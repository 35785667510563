import React from 'react'

// material-ui
import { CustomTheme, useTheme } from '@mui/material/styles'

// third-party
import ReactApexChart from 'react-apexcharts'
import { ApexOptions } from 'apexcharts'

//types
import { IncomeAreaChartType, SrTicketReport } from 'types'

//utils
import { lastTwelveMonths } from 'utils/lastTwelveMonths'
import { Box, Typography } from '@mui/material'

//icons-material/
import BarChartIcon from '@mui/icons-material/BarChart'

//zustand
import useMiscellaneous from 'store/useMiscellaneous'
import { WarningOutlined } from '@ant-design/icons'

// ==============================|| INCOME AREA CHART ||============================== //

const UsageChart: React.FC<IncomeAreaChartType> = ({
    slot,
    data,
    fullTicketData,
    isError,
}): JSX.Element => {
    //theme
    const theme = useTheme<CustomTheme>()

    //zustand
    const setUsageModal = useMiscellaneous((state) => state.setUsageModal)

    const { primary, secondary } = theme.palette.text
    const line = theme.palette.divider

    const chartOptions: ApexOptions = {
        chart: {
            toolbar: {
                tools: {
                    download: true,
                    selection: false,
                    zoom: true,
                    zoomin: false,
                    zoomout: false,
                    pan: false,
                    reset: false,
                },
            },
            selection: {
                enabled: true,
            },
            events: {
                markerClick: function (
                    event: any,
                    chartContext: any,
                    config: any
                ) {
                    //@ts-ignore
                    const ticketInThisSection: SrTicketReport[] =
                        fullTicketData[config.dataPointIndex]
                    setUsageModal(true, ticketInThisSection)
                },
            },
        },

        // @ts-ignore
        colors: [theme.palette.primary.main, theme.palette.primary[700]],
        xaxis: {
            categories:
                slot === 'month'
                    ? lastTwelveMonths
                    : ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
            labels: {
                style: {
                    colors: [
                        secondary,
                        secondary,
                        secondary,
                        secondary,
                        secondary,
                        secondary,
                        secondary,
                        secondary,
                        secondary,
                        secondary,
                        secondary,
                        secondary,
                    ],
                },
            },
        },
        yaxis: {
            labels: {
                style: {
                    colors: [secondary],
                },
            },
        },
        noData: {
            text: 'No data available',
            align: 'center',
            verticalAlign: 'middle',
            offsetX: 0,
            offsetY: 0,
            style: {
                color: primary,
            },
        },
        grid: {
            borderColor: line,
            padding: {
                left: 15,
                right: 15,
                bottom: 15,
                top: 15,
            },
        },
        stroke: {
            curve: 'smooth',
            width: 2,
        },
        legend: {
            onItemHover: {
                highlightDataSeries: true,
            },
        },
        tooltip: {
            theme: theme.palette.mode,
        },
    }

    if (isError) {
        return (
            <Box
                sx={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex',
                    height: 414,
                    width: '100%',
                }}
            >
                <WarningOutlined
                    style={{
                        fontSize: '20px',
                        color: theme.palette.error.main,
                    }}
                />
                <Typography
                    variant="h5"
                    color="text.secondary"
                    sx={{
                        ml: 1,
                    }}
                >
                    Something went wrong, please try again later.
                </Typography>
            </Box>
        )
    }
    if (!data[0]?.data) {
        return (
            <Box
                sx={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex',
                    height: 414,
                    width: '100%',
                }}
            >
                <Typography
                    variant="h5"
                    color="text.secondary"
                    sx={{
                        mr: 1,
                    }}
                >
                    No Usage Data
                </Typography>
                <BarChartIcon />
            </Box>
        )
    }

    return (
        <Box height={400} position="relative">
            <Box height={400}>
                <ReactApexChart
                    options={chartOptions}
                    series={data}
                    type="area"
                    height={400}
                />
            </Box>
        </Box>
    )
}

export default UsageChart
