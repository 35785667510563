// material-ui
import {
    Button,
    FormHelperText,
    Grid,
    InputLabel,
    OutlinedInput,
    Stack,
    Typography,
    IconButton,
    CircularProgress,
} from '@mui/material'

// third party
import * as Yup from 'yup'
import { Formik } from 'formik'

// project import
import AnimateButton from 'components/@extended/AnimateButton'

//zustand

//config
import { API_URL } from 'config'

// project import

//components
import AuthCard from '../authentication/AuthCard'

// icons
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import useBusinessLogic from 'store/useBusinessLogic'

const ConfirmCode: React.FC<{
    goBack: () => void
    enteringNewPassword: () => void
}> = ({ goBack, enteringNewPassword }): JSX.Element => {
    const resetPassEmail = useBusinessLogic((state) => state.resetPassEmail)
    console.log(
        '🚀 ~ file: ConfirmCode.tsx:40 ~ resetPassEmail',
        resetPassEmail
    )
    return (
        <AuthCard>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Stack
                        direction="row"
                        alignItems="space-between"
                        justifyContent="space-between"
                        sx={{ mb: { xs: -0.5, sm: 0.5 } }}
                    >
                        <IconButton
                            aria-label="go-back"
                            onClick={goBack}
                            color="secondary"
                            sx={{
                                color: 'text.primary',
                                mr: 1,
                            }}
                        >
                            <ChevronLeftIcon />
                        </IconButton>
                        <Typography variant="h3">Enter Code</Typography>
                    </Stack>
                </Grid>
                <Grid item xs={12}>
                    <>
                        <Formik
                            initialValues={{
                                code: '',
                                submit: null,
                            }}
                            validationSchema={Yup.object().shape({
                                code: Yup.string()
                                    .max(6)
                                    .required('Reset code is required'),
                            })}
                            onSubmit={async (
                                values,
                                { setErrors, setStatus, setSubmitting }
                            ) => {
                                try {
                                    setStatus({
                                        success: false,
                                    })
                                    setSubmitting(true)

                                    if (!resetPassEmail) {
                                        goBack()
                                    }
                                    const data = await fetch(
                                        `${API_URL}/users/confirmResetCode`,
                                        {
                                            method: 'POST',
                                            headers: {
                                                'Content-Type':
                                                    'application/json',
                                            },
                                            body: JSON.stringify({
                                                code: values.code,
                                                email: resetPassEmail,
                                            }),
                                        }
                                    )

                                    if (data.ok) {
                                        const results = await data.json()

                                        if (
                                            results?.message ===
                                            'The Code You Entered is Invalid.'
                                        ) {
                                            throw new Error(
                                                'The Code You Entered is Invalid.'
                                            )
                                        }

                                        if (
                                            results?.message ===
                                            'This Code Has Already Been Used.'
                                        ) {
                                            throw new Error(
                                                'This Code Has Already Been Used.'
                                            )
                                        }

                                        enteringNewPassword()
                                    }
                                    setSubmitting(false)
                                } catch (err: Error | any | undefined) {
                                    setStatus({
                                        success: false,
                                    })
                                    setErrors({
                                        submit: err?.message,
                                    })
                                    setSubmitting(false)
                                }
                            }}
                        >
                            {({
                                errors,
                                handleBlur,
                                handleChange,
                                handleSubmit,
                                isSubmitting,
                                touched,
                                values,
                            }) => (
                                <form noValidate onSubmit={handleSubmit}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <Stack spacing={1}>
                                                <InputLabel htmlFor="reset-code">
                                                    Reset Code
                                                </InputLabel>
                                                <OutlinedInput
                                                    id="enter-code"
                                                    type="numeric"
                                                    value={values.code}
                                                    name="code"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    placeholder="Code"
                                                    fullWidth
                                                    error={Boolean(
                                                        touched.code &&
                                                            errors.code
                                                    )}
                                                />
                                                {touched.code &&
                                                    errors.code && (
                                                        <FormHelperText
                                                            error
                                                            id="standard-weight-helper-text-code-login"
                                                        >
                                                            {errors.code}
                                                        </FormHelperText>
                                                    )}
                                            </Stack>
                                        </Grid>

                                        {errors.submit && (
                                            <Grid item xs={12}>
                                                <FormHelperText error>
                                                    {errors.submit}
                                                </FormHelperText>
                                            </Grid>
                                        )}
                                        <Grid item xs={12}>
                                            <AnimateButton>
                                                {/*// @ts-ignore */}
                                                <Button
                                                    disableElevation
                                                    disabled={isSubmitting}
                                                    fullWidth
                                                    size="large"
                                                    type="submit"
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={handleSubmit}
                                                    startIcon={
                                                        isSubmitting && (
                                                            <CircularProgress
                                                                size="1rem"
                                                                color="inherit"
                                                            />
                                                        )
                                                    }
                                                >
                                                    Confirm Code
                                                </Button>
                                            </AnimateButton>
                                        </Grid>
                                    </Grid>
                                </form>
                            )}
                        </Formik>
                    </>
                </Grid>
            </Grid>
        </AuthCard>
    )
}

export default ConfirmCode
