//framer
import { motion } from 'framer-motion'

//config
import config from 'config'

//assets
import SRLogo from 'assets/images/icons/SRLogo.svg'

const AnimatingSRLogo: React.FC<{
    size?: number | string
    shouldAnimate?: boolean
}> = ({ size = config.headerHeight, shouldAnimate }) => {
    return (
        <motion.div
            layout
            initial={
                shouldAnimate
                    ? {
                          opacity: 0.5,
                          rotate: -60,
                      }
                    : {}
            }
            animate={
                shouldAnimate
                    ? {
                          opacity: 1,
                          rotate: 0,
                      }
                    : {}
            }
            transition={{
                duration: 0.5,
            }}
            style={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'center',
            }}
        >
            <img
                src={SRLogo}
                alt="SR Logo"
                style={{
                    width: size,
                    height: size,
                }}
            />
        </motion.div>
    )
}

export { AnimatingSRLogo }
