import React from 'react'

//MUI
import {
    Button,
    ButtonGroup,
    CustomTheme,
    Divider,
    Grid,
    ListItem,
    Stack,
    Typography,
    useTheme,
} from '@mui/material'
import Popover from '@mui/material/Popover'

//types
import { ProposalType } from 'types'

//icons
import ReceiptIcon from '@mui/icons-material/Receipt'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import LocalOfferIcon from '@mui/icons-material/LocalOffer'
import PaidIcon from '@mui/icons-material/Paid'

//zustand
import useMiscellaneous from 'store/useMiscellaneous'

const ProposalRow: React.FC<{
    proposal: ProposalType
    onApprovePressed: (rowInfo: any) => void
    onRejectPressed: (rowInfo: any) => void
    completed?: boolean
}> = ({
    proposal,
    onApprovePressed,
    onRejectPressed,
    completed,
}): JSX.Element => {
    //zustand

    //theme
    const theme = useTheme<CustomTheme>()

    const textStyle = {
        display: 'block',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        ml: 0.5,
    }

    //--------------------------PAYMENT--------------------------//
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
        null
    )

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const open = Boolean(anchorEl)
    const id = open ? 'simple-popover' : undefined
    //--------------------------PAYMENT--------------------------//

    return (
        <ListItem>
            <Grid
                container
                spacing={'space-between'}
                direction="row"
                rowSpacing={1}
            >
                <Grid item sm={12} xs={12} md={1.6} lg={1.6}>
                    <Stack direction="row">
                        <ReceiptIcon
                            style={{
                                fontSize: '1.2rem',
                                color: theme.palette.primary.main,
                            }}
                        />

                        <Typography variant="h6" color="inherit" sx={textStyle}>
                            Proposal ID
                        </Typography>
                    </Stack>
                    <Typography
                        variant="h6"
                        color="text.secondary"
                        sx={textStyle}
                    >
                        {proposal.id}
                    </Typography>
                </Grid>

                <Grid item sm={12} xs={12} md={1.6} lg={1.6}>
                    <Stack direction="row">
                        <PaidIcon
                            sx={{
                                fontSize: '1.2rem',
                                color: theme.palette.primary.main,
                            }}
                        />
                        <Typography variant="h6" color="inherit" sx={textStyle}>
                            Due Today
                        </Typography>
                    </Stack>
                    <Typography
                        variant="h6"
                        color="text.secondary"
                        sx={textStyle}
                    >
                        ${proposal.deposit}
                    </Typography>
                </Grid>

                <Grid item sm={12} xs={12} md={1.6} lg={1.6}>
                    <Stack direction="row">
                        <PaidIcon
                            sx={{
                                fontSize: '1.2rem',
                                color: theme.palette.primary.main,
                            }}
                        />
                        <Typography variant="h6" color="inherit" sx={textStyle}>
                            Total Cost
                        </Typography>
                    </Stack>
                    <Typography
                        variant="h6"
                        color="text.secondary"
                        sx={textStyle}
                    >
                        ${proposal.finalPayment}
                    </Typography>
                </Grid>

                <Grid item sm={12} xs={12} md={2} lg={2}>
                    <Stack direction="row">
                        <CalendarMonthIcon
                            sx={{
                                fontSize: '1.2rem',
                                color: theme.palette.primary.main,
                            }}
                        />
                        <Typography variant="h6" color="inherit" sx={textStyle}>
                            Notes
                        </Typography>
                    </Stack>

                    <Typography
                        variant="h6"
                        color={theme.palette.text.secondary}
                        sx={textStyle}
                    >
                        {proposal.orderNotes}
                    </Typography>
                </Grid>

                <Grid item sm={12} xs={12} md={1.6} lg={1.6}>
                    <Stack direction="row">
                        <CalendarMonthIcon
                            sx={{
                                fontSize: '1.2rem',
                                color: theme.palette.primary.main,
                            }}
                        />
                        <Typography variant="h6" color="inherit" sx={textStyle}>
                            Date Created
                        </Typography>
                    </Stack>

                    <Typography
                        variant="h6"
                        color={theme.palette.text.secondary}
                        sx={textStyle}
                    >
                        {new Date(proposal.dateCreated).toLocaleDateString() ??
                            ''}
                    </Typography>
                </Grid>

                <Grid item sm={12} xs={12} md={1.6} lg={1.6}>
                    <Stack direction="row">
                        <CalendarMonthIcon
                            sx={{
                                fontSize: '1.2rem',
                                color: theme.palette.primary.main,
                            }}
                        />
                        <Typography variant="h6" color="inherit" sx={textStyle}>
                            Status
                        </Typography>
                    </Stack>

                    <Typography
                        variant="h6"
                        color={theme.palette.text.secondary}
                        sx={[
                            textStyle,
                            {
                                color:
                                    proposal.orderStatus === 'Hold'
                                        ? theme.palette.error.main
                                        : undefined,
                            },
                        ]}
                    >
                        {proposal.orderStatus === 'New'
                            ? 'Pending'
                            : proposal.orderStatus === 'Hold'
                            ? 'Rejected'
                            : proposal.orderStatus}
                    </Typography>
                </Grid>

                {!completed && (
                    <Grid
                        item
                        sm={12}
                        xs={12}
                        md={2}
                        lg={2}
                        display="flex"
                        alignItems="center"
                        justifyContent="end"
                    >
                        {proposal.orderStatus !== 'Hold' && (
                            <div>
                                <Button
                                    onClick={handleClick}
                                    variant="outlined"
                                    size="small"
                                    sx={{
                                        height: 30,
                                        mr: 2,
                                    }}
                                >
                                    Reject
                                </Button>
                                <Popover
                                    id={id}
                                    open={open}
                                    anchorEl={anchorEl}
                                    onClose={handleClose}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                >
                                    <Typography sx={{ p: 1.5 }}>
                                        Are you sure?
                                    </Typography>
                                    <Divider />
                                    <Button
                                        onClick={onRejectPressed}
                                        variant="text"
                                        size="medium"
                                        sx={{
                                            width: '100%',
                                            color: theme.palette.error.main,
                                        }}
                                    >
                                        Confirm
                                    </Button>
                                </Popover>
                            </div>
                        )}
                        <Button
                            onClick={onApprovePressed}
                            variant="contained"
                            size="small"
                            sx={{
                                height: 30,
                                minWidth: 113,
                            }}
                        >
                            Approve & Pay
                        </Button>
                    </Grid>
                )}
            </Grid>
        </ListItem>
    )
}

export default ProposalRow
