import { CustomTheme, CheckboxPropsColorOverrides } from '@mui/material'

const CheckBox = (theme: CustomTheme): CheckboxPropsColorOverrides => {
    return {
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    color: theme.palette.secondary.main,
                },
            },
        },
    }
}

export default CheckBox
