import React from 'react'

// material-ui
import { useTheme } from '@mui/material/styles'
import { AppBar, IconButton, Toolbar, useMediaQuery } from '@mui/material'

// project import
import AppBarStyled from './AppBarStyled'
import HeaderContent from './HeaderContent'

// assets
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons'

//types
import { HeaderType } from 'types'

// ==============================|| MAIN LAYOUT - HEADER ||============================== //

const Header: React.FC<HeaderType> = ({
    open,
    handleDrawerToggle,
    drawerType,
}): JSX.Element => {
    const theme = useTheme()
    const matchDownMD = useMediaQuery(theme.breakpoints.down('lg'))

    // common header
    const mainHeader = (
        <Toolbar>
            <IconButton
                aria-label="open-drawer"
                onClick={handleDrawerToggle}
                edge="start"
                color="secondary"
                sx={{
                    color: 'text.primary',
                    // bgcolor: open ? iconBackColorOpen : iconBackColor,
                    ml: { xs: 0, lg: -2 },
                    // ...(open && { display: 'none' }),
                }}
            >
                {open ? <MenuFoldOutlined /> : <MenuUnfoldOutlined />}
            </IconButton>
            <HeaderContent />
        </Toolbar>
    )

    // app-bar params
    const appBar = {
        position: 'fixed',
        color: 'inherit',
        elevation: 0,
        sx: {
            borderBottom: `1px solid ${theme.palette.divider}`,
            // boxShadow: theme.customShadows.z1,
        },
    }

    return (
        <>
            {!matchDownMD ? (
                //@ts-ignore - theres no way to type this
                <AppBarStyled open={open} {...appBar}>
                    {mainHeader}
                </AppBarStyled>
            ) : (
                //@ts-ignore - theres no way to type this
                <AppBar {...appBar}>{mainHeader}</AppBar>
            )}
        </>
    )
}

export default Header
