// material-ui
import { Box, CustomTheme, Grid, Stack, useTheme } from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery'
import Skeleton from '@mui/material/Skeleton'
import Divider from '@mui/material/Divider'

const Row = (): JSX.Element => (
    <Box
        sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
        }}
    >
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
            }}
        >
            <Skeleton
                variant="circular"
                animation="wave"
                height={20}
                width={20}
            />
            <Skeleton
                variant="text"
                color="wave"
                sx={{
                    display: 'block',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    ml: 1,
                }}
                width={100}
            ></Skeleton>
        </Box>
        <Skeleton
            variant="text"
            color="wave"
            width={20}
            sx={{
                display: 'block',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
            }}
        ></Skeleton>
    </Box>
)

const DividerSpacing = (): JSX.Element => (
    <Grid item>
        <Divider />
    </Grid>
)

const MonthlyMaintSkeleton = (): JSX.Element => {
    const theme = useTheme<CustomTheme>()

    const sm = useMediaQuery(theme.breakpoints.down('sm'))
    const md = useMediaQuery(theme.breakpoints.down('md'))

    return (
        <Box
            sx={{
                p: 0.1,
            }}
        >
            <Stack
                sx={{
                    p: 3,
                    pb: 0,
                    color: theme.palette.text.secondary,
                }}
                direction="row"
            >
                <Skeleton
                    variant="text"
                    animation="wave"
                    height={20}
                    width={200}
                />
            </Stack>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    height: md ? 341 : 364,
                }}
            >
                <Grid
                    container
                    rowSpacing={3}
                    xs={6}
                    md={12}
                    lg={12}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        p: { xs: 1, sm: 1, md: 2, lg: 2 },
                    }}
                >
                    <Grid item>
                        <Row />
                    </Grid>
                    <DividerSpacing />
                    <Grid item>
                        <Row />
                    </Grid>
                    <DividerSpacing />
                    <Grid item>
                        <Row />
                    </Grid>
                    <DividerSpacing />
                    <Grid item>
                        <Row />
                    </Grid>
                </Grid>
                <Grid
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                    xs={6}
                    md={12}
                    lg={12}
                >
                    <Skeleton
                        variant="circular"
                        animation="wave"
                        height={sm ? 140 : md ? 301 : 200}
                        width={sm ? 140 : md ? 301 : 200}
                        sx={{
                            position: 'absolute',
                        }}
                    />
                </Grid>
            </Box>
        </Box>
    )
}

export default MonthlyMaintSkeleton
