import React from 'react'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

//types
import { ScrollTopType } from 'types'

// ==============================|| NAVIGATION - SCROLL TO TOP ||============================== //

const ScrollTop: React.FC<ScrollTopType> = ({
    children,
}: JSX.Element | any) => {
    const location = useLocation()
    const { pathname } = location

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
        })
    }, [pathname])

    if (children) {
        return children
    }
}

export default ScrollTop
