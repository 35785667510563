import create from 'zustand'
import { devtools, persist } from 'zustand/middleware'

interface PersistedStoreState {
    darkMode: 'dark' | 'light' | 'auto'
    setDarkMode: (mode: 'dark' | 'light' | 'auto') => void
}
const useStoredStore = create<PersistedStoreState>()(
    devtools(
        persist((set) => ({
            //@ts-ignore
            darkMode: localStorage.getItem('darkMode') || 'auto',
            setDarkMode: (mode: 'dark' | 'light' | 'auto') => {
                if (mode === 'auto') {
                    localStorage.removeItem('darkMode')
                    set((state) => ({
                        ...state,
                        darkMode: mode,
                    }))
                } else {
                    localStorage.setItem('darkMode', mode)
                    set((state) => ({
                        ...state,
                        darkMode: mode,
                    }))
                }
            },
        }))
    )
)

export default useStoredStore
