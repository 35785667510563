import { CustomTheme, BadgePropsVariantOverrides } from '@mui/material'

const Badge = (theme: CustomTheme): BadgePropsVariantOverrides => {
    return {
        MuiBadge: {
            styleOverrides: {
                standard: {
                    minWidth: theme.spacing(2),
                    height: theme.spacing(2),
                    padding: theme.spacing(0.5),
                },
            },
        },
    }
}

export default Badge
