import React, { useCallback, useEffect } from 'react'

// material-ui
// import { useTheme } from '@mui/material/styles'
import {
    Box,
    Toolbar,
    useTheme,
    CustomTheme,
    useMediaQuery,
} from '@mui/material'

// project import
import MainDrawer from './Drawer'
import Header from './Header'
import navigation from '../../menu-items/index'
import Breadcrumbs from 'components/@extended/Breadcrumbs'

// zustand
import useDrawerStore from 'store/drawer-store'
import useBusinessLogic from 'store/useBusinessLogic'

//react router
// import { useLocation } from 'react-router-dom'

//types
import { MainLayoutType } from 'types'

//config
import { drawerMiniWidth } from 'config'

const MainLayout: React.FC<MainLayoutType> = ({ children }): JSX.Element => {
    //zustand
    const setOpenDrawer = useDrawerStore((state) => state.setOpenDrawer)
    const drawerOpen = useDrawerStore((state) => state.drawerOpen)
    const setDrawerType = useDrawerStore((state) => state.setDrawerType)
    const drawerType = useDrawerStore((state) => state.drawerType)

    //theme
    const theme = useTheme<CustomTheme>()

    //media query
    const matchDownMD = useMediaQuery(theme.breakpoints.down('lg'))

    //router
    // let location = useLocation()

    //token
    const token = useBusinessLogic((state) => state.token)

    //----------------------|| DRAWER TOGGLES ||---------------------- //
    const handleDrawerToggle = useCallback(() => {
        if (matchDownMD) {
            setTimeout(() => {
                setDrawerType('persistent')
            }, 200)
            setOpenDrawer(!drawerOpen)
            return
        }

        if (drawerType === 'persistent') {
            setDrawerType('permanent')
            setTimeout(() => {
                setOpenDrawer(!drawerOpen)
            }, 50)
        } else {
            setOpenDrawer(!drawerOpen)
            setTimeout(() => {
                setDrawerType('persistent')
            }, 200)
        }
    }, [drawerOpen, setOpenDrawer, setDrawerType, drawerType, matchDownMD])

    const closeDrawerHover = useCallback(() => {
        if (drawerType === 'persistent') {
            setOpenDrawer(false)
        }
    }, [setOpenDrawer, drawerType])

    const openDrawerHover = useCallback(() => {
        if (drawerType === 'persistent') {
            setOpenDrawer(true)
        }
    }, [setOpenDrawer, drawerType])

    const closePersistentDrawer = useCallback(() => {
        if (drawerType === 'persistent') {
            setOpenDrawer(false)
        }
    }, [drawerType, setOpenDrawer])
    //----------------------|| DRAWER TOGGLES ||---------------------- //

    useEffect(() => {
        if (matchDownMD && drawerType !== 'persistent') {
            setDrawerType('persistent')
        }
        //eslint-disable-next-line
    }, [matchDownMD, drawerType])

    return token ? (
        <Box sx={{ display: 'flex', width: '100%' }}>
            <Header
                open={drawerOpen}
                handleDrawerToggle={handleDrawerToggle}
                drawerType={drawerType}
            />
            <MainDrawer
                open={drawerOpen}
                handleDrawerToggle={handleDrawerToggle}
                openDrawerHover={openDrawerHover}
                closeDrawerHover={closeDrawerHover}
                drawerType={drawerType}
            />
            <Box
                component="main"
                sx={{
                    width: '100%',
                    flexGrow: 1,
                    p: { xs: 2, sm: 3 },
                }}
            >
                <Toolbar />
                <Breadcrumbs
                    navigation={navigation}
                    title
                    titleBottom
                    card={false}
                    divider={false}
                />
                <Box
                    component="main"
                    onClick={closePersistentDrawer}
                    sx={{
                        // marginLeft: { xs: 2, sm: 3 },
                        marginLeft:
                            drawerType === 'persistent' && !matchDownMD
                                ? `${drawerMiniWidth}px`
                                : 0,
                    }}
                >
                    {children}
                </Box>
            </Box>
        </Box>
    ) : (
        children
    )
}

MainLayout.displayName = 'MainLayout'

export default MainLayout
