import React from 'react'
// material-ui
import { Box, CustomTheme, useMediaQuery } from '@mui/material'

//components
import Profile from './Profile'
import Notification from './Notification'
import MobileSection from './MobileSection'
import CreateButton from './CreateButton'

import useMiscellaneous from 'store/useMiscellaneous'
import { PlusOutlined } from '@ant-design/icons'

// ==============================|| HEADER - CONTENT ||============================== //

const HeaderContent: React.FC = (): JSX.Element => {
    const matchesMd = useMediaQuery((theme: CustomTheme) =>
        theme.breakpoints.down('md')
    )
    console.log('🚀 ~ file: index.tsx:20 ~ matchesMd', matchesMd)

    const handleOpen = useMiscellaneous(
        (state) => state.setCreateTicketModalOpen
    )
    return (
        <>
            <Box
                sx={{
                    width: '100%',
                    ml: { xs: 0, md: 1 },
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    mr: 2,
                }}
            >
                {!matchesMd ? (
                    <CreateButton
                        onPress={() => {
                            handleOpen(true)
                        }}
                        text="Create Ticket"
                    />
                ) : (
                    <CreateButton
                        onPress={() => {
                            handleOpen(true)
                        }}
                        icon={
                            <PlusOutlined
                                style={{
                                    fontSize: '25px',
                                }}
                            />
                        }
                    />
                )}
            </Box>

            {!matchesMd && <Profile />}
            {!matchesMd && <Notification />}
            {matchesMd && <MobileSection />}
        </>
    )
}

export default HeaderContent
