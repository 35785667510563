import React, { useEffect, useState } from 'react'

// material-ui
import {
    Box,
    Button,
    FormControl,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Stack,
    Typography,
    CircularProgress,
} from '@mui/material'

// third party
import * as Yup from 'yup'
import 'yup-phone'
import { Formik } from 'formik'

// project import
import AnimateButton from 'components/@extended/AnimateButton'
import { strengthColor, strengthIndicator } from 'utils/password-strength'

// assets
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons'

//types
import { PassStrengthType } from 'types'

//config
import { API_URL } from 'config'

//api
import { headersFunction } from 'api'
import { toast } from 'react-toastify'

//react query
import { useQuery } from '@tanstack/react-query'
import { queryClient, queryKeys } from 'react-query'

//API calls
import { getProfileInfo } from 'api'

//utils
import { errorTypeHandler } from 'utils'

//sentry
import * as Sentry from '@sentry/react'

// ============================|| REGISTER ||============================ //

const EditProfileForm: React.FC = (): JSX.Element => {
    //----------------------|| GET USER INFO ||-----------------------//
    const { data } = useQuery([queryKeys.userInfo], getProfileInfo)

    //----------------------|| GET USER INFO ||-----------------------//

    const [level, setLevel] = useState<PassStrengthType>()
    const [showPassword, setShowPassword] = useState(false)
    // const [existingCompany, setExistingCompany] = useState(false)
    const handleClickShowPassword = () => {
        setShowPassword(!showPassword)
    }

    const handleMouseDownPassword = (event: any) => {
        event?.preventDefault()
    }

    const changePassword = (value: string) => {
        const temp: number = strengthIndicator(value)
        setLevel(strengthColor(temp))
    }

    useEffect(() => {
        changePassword('')
    }, [])

    const handleChangeWithFormatting = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        setFieldValue: (field: string, value: string) => void
    ) => {
        const value = e.target.value
        if (!value) return value

        const phoneNumber = value.replace(/[^\d]/g, '')

        const phoneNumberLength = phoneNumber.length

        if (phoneNumberLength < 4) return phoneNumber

        if (phoneNumberLength < 7) {
            return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`
        }

        const string = `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
            3,
            6
        )}-${phoneNumber.slice(6, 10)}`

        setFieldValue('phone', string)
    }

    return (
        <>
            <Formik
                initialValues={{
                    firstname: data?.Name,
                    lastname: data?.lastName,
                    email: data?.emailAddress,
                    // company: '',
                    password: '',
                    phone: data?.companyPhone,
                    submit: null,
                }}
                validationSchema={Yup.object().shape({
                    firstname: Yup.string().max(255),
                    lastname: Yup.string().max(255),
                    email: Yup.string().email('Must be a valid email').max(255),
                    password: Yup.string().max(255),
                    // company: Yup.string().required('Company is required'),
                    phone: Yup.string().phone(),
                })}
                onSubmit={async (
                    values,
                    { setErrors, setStatus, setSubmitting }
                ) => {
                    try {
                        setStatus({ success: false })
                        setSubmitting(true)

                        const data = await fetch(`${API_URL}/users/me`, {
                            method: 'PATCH',
                            headers: headersFunction(),
                            body: JSON.stringify({
                                //@ts-ignore
                                ...(values.firstname?.length > 0 && {
                                    firstName: values.firstname,
                                }),
                                lastName: values.lastname,
                                //@ts-ignore
                                ...(values?.lastname?.length > 0 && {
                                    lastName: values.lastname,
                                }),
                                ...(values.password.length > 0 && {
                                    password: values.password,
                                }),
                                //@ts-ignore
                                ...(values.phone.length > 0 && {
                                    phone: values.phone,
                                }),
                                //@ts-ignore
                                ...(values?.email?.length > 0 && {
                                    email: values.email,
                                }),
                            }),
                        })

                        const results = await data.json()

                        errorTypeHandler(results)

                        if (data.ok) {
                            setStatus({ success: true })
                            toast.success('Profile Updated Successfully')
                            setSubmitting(false)
                            queryClient.invalidateQueries([queryKeys.userInfo])
                        } else {
                            setStatus({ success: false })
                            setSubmitting(false)
                        }
                    } catch (err: Error | any) {
                        Sentry.captureException(err)
                        toast.error(
                            'Something Went Wrong, Please Try Again Later.'
                        )
                        setStatus({ success: false })
                        setErrors({ submit: err?.message })
                        setSubmitting(false)
                    }
                }}
            >
                {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    touched,
                    values,
                    setFieldValue,
                }) => (
                    <form noValidate onSubmit={handleSubmit}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={6}>
                                <Stack spacing={1}>
                                    <InputLabel htmlFor="firstname-signup">
                                        First Name*
                                    </InputLabel>
                                    <OutlinedInput
                                        id="firstname-login"
                                        type="firstname"
                                        value={values.firstname}
                                        name="firstname"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        placeholder="John"
                                        fullWidth
                                        error={Boolean(
                                            touched.firstname &&
                                                errors.firstname
                                        )}
                                    />
                                    {touched.firstname && errors.firstname && (
                                        <FormHelperText
                                            error
                                            id="helper-text-firstname-signup"
                                        >
                                            {errors.firstname}
                                        </FormHelperText>
                                    )}
                                </Stack>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Stack spacing={1}>
                                    <InputLabel htmlFor="lastname-signup">
                                        Last Name*
                                    </InputLabel>
                                    <OutlinedInput
                                        fullWidth
                                        error={Boolean(
                                            touched.lastname && errors.lastname
                                        )}
                                        id="lastname-signup"
                                        type="lastname"
                                        value={values.lastname}
                                        name="lastname"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        placeholder="Doe"
                                        inputProps={{}}
                                    />
                                    {touched.lastname && errors.lastname && (
                                        <FormHelperText
                                            error
                                            id="helper-text-lastname-signup"
                                        >
                                            {errors.lastname}
                                        </FormHelperText>
                                    )}
                                </Stack>
                            </Grid>

                            <Grid item xs={12}>
                                <Stack spacing={1}>
                                    <InputLabel htmlFor="email-signup">
                                        Email Address*
                                    </InputLabel>
                                    <OutlinedInput
                                        fullWidth
                                        error={Boolean(
                                            touched.email && errors.email
                                        )}
                                        id="email-login"
                                        type="email"
                                        value={values.email}
                                        name="email"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        placeholder="demo@company.com"
                                        inputProps={{}}
                                    />
                                    {touched.email && errors.email && (
                                        <FormHelperText
                                            error
                                            id="helper-text-email-signup"
                                        >
                                            {errors.email}
                                        </FormHelperText>
                                    )}
                                </Stack>
                            </Grid>

                            <Grid item xs={12}>
                                <Stack spacing={1}>
                                    <InputLabel htmlFor="firstname-signup">
                                        Company Phone
                                    </InputLabel>

                                    <OutlinedInput
                                        fullWidth
                                        error={Boolean(
                                            touched.phone && errors.phone
                                        )}
                                        id="company-phone"
                                        value={values.phone}
                                        name="phone"
                                        type="phone"
                                        onBlur={handleBlur}
                                        onChange={(e) =>
                                            handleChangeWithFormatting(
                                                e,
                                                setFieldValue
                                            )
                                        }
                                        onChangeCapture={handleChange}
                                        placeholder="Company Phone"
                                        inputProps={{}}
                                    />

                                    {touched.phone && errors.phone && (
                                        <FormHelperText
                                            error
                                            id="helper-text-company-signup"
                                        >
                                            Must be a valid phone number
                                        </FormHelperText>
                                    )}
                                </Stack>
                            </Grid>

                            <Grid item xs={12}>
                                <Stack spacing={1}>
                                    <InputLabel htmlFor="password-signup">
                                        Password
                                    </InputLabel>
                                    <OutlinedInput
                                        fullWidth
                                        error={Boolean(
                                            touched.password && errors.password
                                        )}
                                        id="password-signup"
                                        type={
                                            showPassword ? 'text' : 'password'
                                        }
                                        value={values.password}
                                        name="password"
                                        onBlur={handleBlur}
                                        onChange={(e) => {
                                            handleChange(e)
                                            changePassword(e.target.value)
                                        }}
                                        autoComplete="on"
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={
                                                        handleClickShowPassword
                                                    }
                                                    onMouseDown={
                                                        handleMouseDownPassword
                                                    }
                                                    edge="end"
                                                    size="medium"
                                                >
                                                    {showPassword ? (
                                                        <EyeOutlined />
                                                    ) : (
                                                        <EyeInvisibleOutlined />
                                                    )}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        placeholder="******"
                                        inputProps={{}}
                                    />
                                    {touched.password && errors.password && (
                                        <FormHelperText
                                            error
                                            id="helper-text-password-signup"
                                        >
                                            {errors.password}
                                        </FormHelperText>
                                    )}
                                </Stack>

                                <FormControl fullWidth sx={{ mt: 2 }}>
                                    <Grid
                                        container
                                        spacing={2}
                                        alignItems="center"
                                    >
                                        <Grid item>
                                            <Box
                                                sx={{
                                                    bgcolor: level?.color,
                                                    width: 85,
                                                    height: 8,
                                                    borderRadius: '7px',
                                                }}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Typography
                                                variant="subtitle1"
                                                fontSize="0.75rem"
                                            >
                                                {level?.label}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </FormControl>
                            </Grid>

                            {errors.submit && (
                                <Grid item xs={12}>
                                    <FormHelperText error>
                                        {errors.submit}
                                    </FormHelperText>
                                </Grid>
                            )}

                            <Grid item xs={12}>
                                <AnimateButton>
                                    <Button
                                        disableElevation
                                        disabled={isSubmitting}
                                        fullWidth
                                        size="medium"
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        startIcon={
                                            isSubmitting && (
                                                <CircularProgress
                                                    size="1rem"
                                                    color="primary"
                                                />
                                            )
                                        }
                                    >
                                        Save Changes
                                    </Button>
                                </AnimateButton>
                            </Grid>
                        </Grid>
                    </form>
                )}
            </Formik>
        </>
    )
}

export default EditProfileForm
