import React, { useEffect } from 'react'

//router
import { useLocation, Navigate, useNavigate } from 'react-router-dom'
import useBusinessLogic from 'store/useBusinessLogic'

//types
import { RequireAuthType } from 'types'

//jwt
import jwt_decode from 'jwt-decode'

//zustand
import useMiscellaneous from 'store/useMiscellaneous'
import { queryKeys } from 'react-query'
import { useQuery } from '@tanstack/react-query'
import { getProfileInfo } from 'api'

const RequireAuth: React.FC<RequireAuthType> = ({ children }): JSX.Element => {
    const token = useBusinessLogic((state) => state.token)
    const location = useLocation()
    const navigate = useNavigate()

    const closeAllModals = useMiscellaneous.getState().closeAllModals

    //check if token is expired
    const checkTokenExpiration = () => {
        if (token) {
            const decoded = jwt_decode(token)
            //@ts-ignore
            const expirationTime = decoded.exp * 1000 - 60000
            if (Date.now() >= expirationTime) {
                navigate('/login', { replace: true })
                localStorage.removeItem('token')
            }
            closeAllModals()
        }
    }

    React.useEffect(() => {
        checkTokenExpiration()
    }, [location])

    //----------------------|| GET USER INFO ||-----------------------//

    const setProfileInfo = useBusinessLogic.getState().setProfileInfo
    const {
        data = {
            userID: 0,
            clientID: 0,
            Name: '',
            lastName: '',
            userName: '',
            adminStatus: '',
            techID: '',
            emailAddress: '',
            additionalCompanies: '',
            manager: '',
            adminViewOnly: '',
            ateraID: '',
            AteraUserID: '',
            companyPhone: '',
        },
        isError,
    } = useQuery([queryKeys.userInfo], getProfileInfo)

    useEffect(() => {
        if (isError) return
        //@ts-ignore
        setProfileInfo(data)
    }, [data])

    if (!token) {
        // Redirect them to the /login page, but save the current location they were
        // trying to go to when they were redirected. This allows us to send them
        // along to that page after they login, which is a nicer user experience
        // than dropping them off on the home page.
        return <Navigate to="/login" state={{ from: location }} replace />
    }

    return children
}

export default RequireAuth
