import React from 'react'
// material-ui
import {
    Button,
    Grid,
    Typography,
    Divider,
    List,
    Skeleton,
} from '@mui/material'

import AuthCard from 'pages/authentication/AuthCard'

//api
import { getOpenBalances } from 'api'

//react-query
import { useQuery } from '@tanstack/react-query'
import { queryKeys } from 'react-query'

//components
import MainCard from 'components/MainCard'
import BalanceRow from 'components/BalanceRow'

//types
import { OpenBalanceType } from 'types'

//zustand
import useMiscellaneous from 'store/useMiscellaneous'

const BillingPage = () => {
    //---------------------- QUERY DATA -----------------//
    const {
        data: openBalances,
        isLoading: openBalancesLoading,
        isError: openBalancesError,
    } = useQuery([queryKeys.openBalances], getOpenBalances)
    //---------------------- QUERY DATA -----------------//

    //---------------------- OPEN MODAL -----------------//
    const setPaymentModalOpen = useMiscellaneous.getState().setPaymentModalOpen

    const handleOpenPaymentModal = React.useCallback(
        (rowInfo: any) => {
            setPaymentModalOpen(true, null, rowInfo)
        },
        [setPaymentModalOpen]
    )
    //---------------------- OPEN MODAL -----------------//

    return (
        <Grid container rowSpacing={2} columnSpacing={2}>
            <Grid item xs={12} md={12} lg={12}>
                <AuthCard
                    sx={{
                        width: '100%',
                    }}
                >
                    <Grid container rowSpacing={1} columnSpacing={1.5}>
                        <Grid item xs={6}>
                            <Typography
                                variant="h3"
                                sx={{
                                    mb: 1,
                                }}
                            >
                                Billing
                            </Typography>
                        </Grid>

                        <Grid item xs={12} md={12} lg={12}>
                            <Typography variant="h4">Total Balance:</Typography>
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            {openBalancesLoading ? (
                                <Skeleton
                                    variant="text"
                                    width={150}
                                    height={40}
                                />
                            ) : (
                                <Typography
                                    variant="h4"
                                    flexDirection={'row'}
                                    display="flex"
                                    sx={{
                                        color: 'primary.main',
                                    }}
                                >
                                    $
                                    <Typography variant="h4">
                                        {openBalances?.totalOwed ?? 0}
                                    </Typography>
                                </Typography>
                            )}
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            md={6}
                            lg={6}
                            display="flex"
                            justifyContent="flex-end"
                        >
                            <Button
                                onClick={() => {
                                    handleOpenPaymentModal(openBalances)
                                }}
                                variant="contained"
                                size="medium"
                                sx={{
                                    height: 30,
                                }}
                                disabled={!openBalances}
                            >
                                Pay Total Amount
                            </Button>
                        </Grid>

                        {openBalances && (
                            <>
                                <Grid item xs={12} md={12} lg={12} mt={3}>
                                    <Typography variant="h4">
                                        Individual Charges
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={12} lg={12} mt={1}>
                                    <MainCard
                                        content
                                        contentSX={{
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            p: 0,
                                            mb: -2.5,
                                        }}
                                    >
                                        <List>
                                            {openBalances?.openBalances.map(
                                                (
                                                    balance: OpenBalanceType,
                                                    index: number
                                                ) => (
                                                    <>
                                                        <BalanceRow
                                                            key={index.toString()}
                                                            balance={balance}
                                                            onPayPressed={() =>
                                                                handleOpenPaymentModal(
                                                                    balance
                                                                )
                                                            }
                                                        />
                                                        {index !==
                                                            openBalances
                                                                .openBalances
                                                                .length -
                                                                1 && (
                                                            <Divider
                                                                sx={{
                                                                    mb: 1,
                                                                }}
                                                            />
                                                        )}
                                                    </>
                                                )
                                            )}
                                        </List>
                                    </MainCard>
                                </Grid>
                            </>
                        )}
                    </Grid>
                </AuthCard>
            </Grid>
        </Grid>
    )
}

export default BillingPage
