import { Box, CustomTheme, Typography, useTheme } from '@mui/material'

//icons
import { LockOutlined } from '@mui/icons-material'

const AdminAccessNeeded: React.FC<{
    isAdmin: boolean
}> = ({ isAdmin }): JSX.Element | null => {
    const theme = useTheme<CustomTheme>()

    const colorMode = theme.palette.mode
    if (!isAdmin) {
        return (
            <Box
                sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor:
                        colorMode === 'dark'
                            ? 'rgba(0,0,0,0.5)'
                            : 'rgba(183,183,183,0.2)',
                    backdropFilter: 'blur(5px)',
                    flexDirection: 'row',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <LockOutlined
                    sx={{
                        fontSize: '20px',
                        marginRight: '10px',
                    }}
                />
                <Typography variant="h5">Admin Access Required</Typography>
            </Box>
        )
    }

    return null
}

export default AdminAccessNeeded
