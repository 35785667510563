import React, { useState } from 'react'
import { Link } from 'react-router-dom'

// material-ui
import { useTheme } from '@mui/material/styles'
import { List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'

// assets
import {
    EditOutlined,
    DashboardOutlined,
    LogoutOutlined,
} from '@ant-design/icons'

//types
import { ProfileTabType } from 'types'

// ==============================|| HEADER PROFILE - PROFILE TAB ||============================== //

const listItemProps = (link: string) => {
    return {
        component: React.forwardRef((props, ref) => (
            //@ts-ignore
            <Link ref={ref} {...props} to={link} />
        )),
    }
}

const ProfileTab: React.FC<ProfileTabType> = ({ handleLogout }) => {
    const theme = useTheme()

    const [selectedIndex, setSelectedIndex] = useState(0)
    const handleListItemClick = (event: any, index: number) => {
        setSelectedIndex(index)
    }

    return (
        <List
            component="nav"
            sx={{
                p: 0,
                '& .MuiListItemIcon-root': {
                    minWidth: 32,
                    color: theme.palette.grey[500],
                },
            }}
        >
            <ListItemButton
                {...listItemProps('/editProfile')}
                selected={selectedIndex === 0}
                onClick={(event) => handleListItemClick(event, 1)}
            >
                <ListItemIcon>
                    <EditOutlined />
                </ListItemIcon>
                <ListItemText primary="Edit Profile" />
            </ListItemButton>
            <ListItemButton
                {...listItemProps('/dashboard')}
                selected={selectedIndex === 1}
                onClick={(event) => handleListItemClick(event, 0)}
            >
                <ListItemIcon>
                    <DashboardOutlined />
                </ListItemIcon>
                <ListItemText primary="Dashboard" />
            </ListItemButton>

            <ListItemButton
                selected={selectedIndex === 2}
                onClick={handleLogout}
            >
                <ListItemIcon>
                    <LogoutOutlined />
                </ListItemIcon>
                <ListItemText primary="Logout" />
            </ListItemButton>
        </List>
    )
}

export default ProfileTab
