import React, { useEffect, useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'

// material-ui
import {
    Box,
    Button,
    FormControl,
    FormHelperText,
    Grid,
    Link,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Stack,
    Typography,
    CustomTheme,
    CircularProgress,
} from '@mui/material'
import Checkbox from '@mui/material/Checkbox'

//axios
import axios from 'axios'

// third party
import * as Yup from 'yup'
import 'yup-phone'
import { Formik } from 'formik'

// project import
import AnimateButton from 'components/@extended/AnimateButton'
import { strengthColor, strengthIndicator } from 'utils/password-strength'

// assets
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons'

//types
import { PassStrengthType } from 'types'

//react router
import { useNavigate, useLocation } from 'react-router-dom'

//zustand
import useBusinessLogic from 'store/useBusinessLogic'

//config
import { API_URL } from 'config'

// ============================|| REGISTER ||============================ //

const AuthRegister: React.FC = (): JSX.Element => {
    //zustand
    const setToken = useBusinessLogic((state) => state.setToken)

    //navigation
    const navigate = useNavigate()
    const location = useLocation()

    //@ts-ignore
    const from = location?.state?.from?.pathname || '/dashboard'

    const [level, setLevel] = useState<PassStrengthType>()
    const [showPassword, setShowPassword] = useState(false)
    // const [existingCompany, setExistingCompany] = useState(false)
    const handleClickShowPassword = () => {
        setShowPassword(!showPassword)
    }

    const handleMouseDownPassword = (event: any) => {
        event?.preventDefault()
    }

    const changePassword = (value: string) => {
        const temp: number = strengthIndicator(value)
        setLevel(strengthColor(temp))
    }

    useEffect(() => {
        changePassword('')
    }, [])

    const handleChangeWithFormatting = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        setFieldValue: (field: string, value: string) => void
    ) => {
        const value = e.target.value
        if (!value) return value

        const phoneNumber = value.replace(/[^\d]/g, '')

        const phoneNumberLength = phoneNumber.length

        if (phoneNumberLength < 4) return phoneNumber

        if (phoneNumberLength < 7) {
            return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`
        }

        const string = `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
            3,
            6
        )}-${phoneNumber.slice(6, 10)}`

        setFieldValue('companyPhone', string)
    }

    return (
        <>
            <Formik
                initialValues={{
                    firstname: '',
                    lastname: '',
                    email: '',
                    // company: '',
                    password: '',
                    companyPhone: '',
                    submit: null,
                }}
                validationSchema={Yup.object().shape({
                    firstname: Yup.string()
                        .max(255)
                        .required('First Name is required'),
                    lastname: Yup.string()
                        .max(255)
                        .required('Last Name is required'),
                    email: Yup.string()
                        .email('Must be a valid email')
                        .max(255)
                        .required('Email is required'),
                    password: Yup.string()
                        .max(255)
                        .required('Password is required'),
                    // company: Yup.string().required('Company is required'),
                    companyPhone: Yup.string()
                        .required('Company Phone is required')
                        .phone(),
                })}
                onSubmit={async (
                    values,
                    { setErrors, setStatus, setSubmitting }
                ) => {
                    try {
                        setStatus({ success: false })
                        setSubmitting(true)
                        console.log(values)

                        const data = await fetch(`${API_URL}/users/signup`, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify({
                                name: values.firstname,
                                lastName: values.lastname,
                                userName: values.email,
                                password: values.password,
                                companyPhone: values.companyPhone,
                                email: values.email,
                            }),
                        })

                        const results = await data.json()

                        //@ts-ignore
                        if (results?.errors) {
                            //@ts-ignore
                            results.errors.forEach((error: { msg: string }) => {
                                throw new Error(
                                    error?.msg || 'Something went wrong!'
                                )
                            })
                        }
                        //@ts-ignore
                        if (results?.error) {
                            throw new Error(results.error)
                        }
                        if (results?.token) {
                            //@ts-ignore
                            const token = results?.token
                            setStatus({ success: true })
                            console.log(token)
                            setToken(token)
                            navigate(from, { replace: true })
                        } else {
                            setStatus({ success: false })
                        }
                        setSubmitting(false)
                    } catch (err: Error | any) {
                        setStatus({ success: false })
                        setErrors({ submit: err?.message })
                        setSubmitting(false)
                    }
                }}
            >
                {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    touched,
                    values,
                    setFieldValue,
                }) => (
                    <form noValidate onSubmit={handleSubmit}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={6}>
                                <Stack spacing={1}>
                                    <InputLabel htmlFor="firstname-signup">
                                        First Name*
                                    </InputLabel>
                                    <OutlinedInput
                                        id="firstname-login"
                                        type="firstname"
                                        value={values.firstname}
                                        name="firstname"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        placeholder="John"
                                        fullWidth
                                        error={Boolean(
                                            touched.firstname &&
                                                errors.firstname
                                        )}
                                    />
                                    {touched.firstname && errors.firstname && (
                                        <FormHelperText
                                            error
                                            id="helper-text-firstname-signup"
                                        >
                                            {errors.firstname}
                                        </FormHelperText>
                                    )}
                                </Stack>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Stack spacing={1}>
                                    <InputLabel htmlFor="lastname-signup">
                                        Last Name*
                                    </InputLabel>
                                    <OutlinedInput
                                        fullWidth
                                        error={Boolean(
                                            touched.lastname && errors.lastname
                                        )}
                                        id="lastname-signup"
                                        type="lastname"
                                        value={values.lastname}
                                        name="lastname"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        placeholder="Doe"
                                        inputProps={{}}
                                    />
                                    {touched.lastname && errors.lastname && (
                                        <FormHelperText
                                            error
                                            id="helper-text-lastname-signup"
                                        >
                                            {errors.lastname}
                                        </FormHelperText>
                                    )}
                                </Stack>
                            </Grid>

                            <Grid item xs={12}>
                                <Stack spacing={1}>
                                    <InputLabel htmlFor="email-signup">
                                        Email Address*
                                    </InputLabel>
                                    <OutlinedInput
                                        fullWidth
                                        error={Boolean(
                                            touched.email && errors.email
                                        )}
                                        id="email-login"
                                        type="email"
                                        value={values.email}
                                        name="email"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        placeholder="demo@company.com"
                                        inputProps={{}}
                                    />
                                    {touched.email && errors.email && (
                                        <FormHelperText
                                            error
                                            id="helper-text-email-signup"
                                        >
                                            {errors.email}
                                        </FormHelperText>
                                    )}
                                </Stack>
                            </Grid>

                            {/* <Grid item xs={12}>
                                <Stack
                                    direction={'row'}
                                    width={'100%'}
                                    sx={{
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                    }}
                                >
                                    <InputLabel htmlFor="firstname-signup">
                                        Are you an existing customer with us?
                                    </InputLabel>

                                    <Checkbox
                                        defaultChecked
                                        onChange={(e) => {
                                            setExistingCompany(e.target.checked)
                                        }}
                                    />
                                </Stack>
                            </Grid> */}

                            {/* <Grid item xs={12}>
                                <Stack spacing={1}>
                                    <InputLabel htmlFor="firstname-signup">
                                        Company Name
                                    </InputLabel>
                                    <OutlinedInput
                                        fullWidth
                                        error={Boolean(
                                            touched.company && errors.company
                                        )}
                                        id="company-signup"
                                        value={values.company}
                                        name="company"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        placeholder="Company Name"
                                        inputProps={{}}
                                    />

                                    {touched.company && errors.company && (
                                        <FormHelperText
                                            error
                                            id="helper-text-company-signup"
                                        >
                                            {errors.company}
                                        </FormHelperText>
                                    )}
                                </Stack>
                            </Grid> */}

                            <Grid item xs={12}>
                                <Stack spacing={1}>
                                    <InputLabel htmlFor="firstname-signup">
                                        Company Phone
                                    </InputLabel>

                                    <OutlinedInput
                                        fullWidth
                                        error={Boolean(
                                            touched.companyPhone &&
                                                errors.companyPhone
                                        )}
                                        id="company-phone"
                                        value={values.companyPhone}
                                        name="companyPhone"
                                        type="phone"
                                        onBlur={handleBlur}
                                        onChange={(e) =>
                                            handleChangeWithFormatting(
                                                e,
                                                setFieldValue
                                            )
                                        }
                                        onChangeCapture={handleChange}
                                        placeholder="Company Phone"
                                        inputProps={{}}
                                    />

                                    {touched.companyPhone &&
                                        errors.companyPhone && (
                                            <FormHelperText
                                                error
                                                id="helper-text-company-signup"
                                            >
                                                Must be a valid phone number
                                            </FormHelperText>
                                        )}
                                </Stack>
                            </Grid>

                            <Grid item xs={12}>
                                <Stack spacing={1}>
                                    <InputLabel htmlFor="password-signup">
                                        Password
                                    </InputLabel>
                                    <OutlinedInput
                                        fullWidth
                                        error={Boolean(
                                            touched.password && errors.password
                                        )}
                                        id="password-signup"
                                        type={
                                            showPassword ? 'text' : 'password'
                                        }
                                        value={values.password}
                                        name="password"
                                        onBlur={handleBlur}
                                        onChange={(e) => {
                                            handleChange(e)
                                            changePassword(e.target.value)
                                        }}
                                        autoComplete="on"
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={
                                                        handleClickShowPassword
                                                    }
                                                    onMouseDown={
                                                        handleMouseDownPassword
                                                    }
                                                    edge="end"
                                                    size="medium"
                                                >
                                                    {showPassword ? (
                                                        <EyeOutlined />
                                                    ) : (
                                                        <EyeInvisibleOutlined />
                                                    )}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        placeholder="******"
                                        inputProps={{}}
                                    />
                                    {touched.password && errors.password && (
                                        <FormHelperText
                                            error
                                            id="helper-text-password-signup"
                                        >
                                            {errors.password}
                                        </FormHelperText>
                                    )}
                                </Stack>

                                <FormControl fullWidth sx={{ mt: 2 }}>
                                    <Grid
                                        container
                                        spacing={2}
                                        alignItems="center"
                                    >
                                        <Grid item>
                                            <Box
                                                sx={{
                                                    bgcolor: level?.color,
                                                    width: 85,
                                                    height: 8,
                                                    borderRadius: '7px',
                                                }}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Typography
                                                variant="subtitle1"
                                                fontSize="0.75rem"
                                            >
                                                {level?.label}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </FormControl>
                            </Grid>

                            {errors.submit && (
                                <Grid item xs={12}>
                                    <FormHelperText error>
                                        {errors.submit}
                                    </FormHelperText>
                                </Grid>
                            )}

                            <Grid item xs={12}>
                                {/* <Typography variant="body2">
                                    By Signing up, you agree to our &nbsp;
                                    <Link
                                        variant="subtitle2"
                                        component={RouterLink}
                                        to="#"
                                    >
                                        Terms of Service
                                    </Link>
                                    &nbsp; and &nbsp;
                                    <Link
                                        variant="subtitle2"
                                        component={RouterLink}
                                        to="#"
                                    >
                                        Privacy Policy
                                    </Link>
                                </Typography> */}
                            </Grid>
                            <Grid item xs={12}>
                                <AnimateButton>
                                    <Button
                                        disableElevation
                                        disabled={isSubmitting}
                                        fullWidth
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        startIcon={
                                            isSubmitting && (
                                                <CircularProgress
                                                    size="1rem"
                                                    color="primary"
                                                />
                                            )
                                        }
                                    >
                                        Create Account
                                    </Button>
                                </AnimateButton>
                            </Grid>
                            {/* <Grid item xs={12}>
                                <Divider>
                                    <Typography variant="caption">
                                        Sign up with
                                    </Typography>
                                </Divider>
                            </Grid>
                            <Grid item xs={12}>
                                <FirebaseSocial />
                            </Grid> */}
                        </Grid>
                    </form>
                )}
            </Formik>
        </>
    )
}

export default AuthRegister
