// ==============================|| THEME CONFIG  ||============================== //

const config = {
    defaultPath: '/dashboard',
    fontFamily: `'Public Sans', sans-serif`,
    i18n: 'en',
    miniDrawer: false,
    container: true,
    mode: 'light',
    presetColor: 'default',
    themeDirection: 'ltr',
    logo: './assets/images/icons/SRLogo.svg',
    headerHeight: 38,
}

export default config
export const drawerWidth = 240
export const drawerMiniWidth = 60

export const twitterColor = '#1DA1F2'
export const facebookColor = '#3b5998'
export const linkedInColor = '#0e76a8'

// export const API_URL = 'http://localhost:3000'
export const API_URL = 'https://app.simplyrem.com'
