import React, { useEffect } from 'react'
import { useRef, useState } from 'react'

// material-ui
import { CustomTheme, useTheme } from '@mui/material/styles'
import {
    Avatar,
    Badge,
    Box,
    ClickAwayListener,
    Divider,
    IconButton,
    ListItemButton,
    ListItemAvatar,
    ListItemText,
    ListItemSecondaryAction,
    Paper,
    Popper,
    Typography,
    useMediaQuery,
} from '@mui/material'

// project import
import MainCard from 'components/MainCard'
import Transitions from 'components/@extended/Transitions'

// assets
import { BellOutlined, CloseOutlined } from '@ant-design/icons'
import {
    ReceiptLongOutlined,
    MoneyOutlined,
    WarningOutlined,
    InfoOutlined,
} from '@mui/icons-material'

//react query
import { queryKeys } from 'react-query'
import { getNotifications } from 'api'

//zustand
import useBusinessLogic from 'store/useBusinessLogic'

//react query
import { useQuery } from '@tanstack/react-query'

//list
import { FixedSizeList as List } from 'react-window'

//utils
import { useWindowDimensions } from 'utils'
import { NotificationType } from 'types'

//react router
import { useNavigate } from 'react-router-dom'

//zustan
import useDrawerStore from 'store/drawer-store'

// ==============================|| HEADER CONTENT - NOTIFICATION ||============================== //

const Notification: React.FC = (): JSX.Element => {
    //theme
    const theme = useTheme<CustomTheme>()

    //zustand
    const setActiveItem = useDrawerStore.getState().setActiveItem

    //media query
    const matchesXs = useMediaQuery(theme.breakpoints.down('md'))

    //zustand
    // const setHasNewNotifications =
    //     useBusinessLogic.getState().setHasNewNotifications

    const markAllNotificationsRead =
        useBusinessLogic.getState().markAllNotificationsRead

    const anchorRef = useRef(null)
    const [open, setOpen] = useState(false)
    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen)
    }

    const handleClose = (event: MouseEvent | TouchEvent) => {
        //@ts-ignore
        if (anchorRef.current && anchorRef.current?.contains(event.target)) {
            return
        }
        setOpen(false)
    }

    //dimensions
    const { width, height } = useWindowDimensions()

    //navigation
    const navigate = useNavigate()

    //---------------------------------FETCH NOTIFICATIONS---------------------------------//
    //@ts-ignore
    const {
        data = [],
        isLoading,
        isError,
    }: {
        data: NotificationType[]
        isLoading: boolean
        isError: boolean
        refetch: () => void
    } = useQuery([queryKeys.notifications], getNotifications, {
        keepPreviousData: false,
    })
    //---------------------------------FETCH NOTIFICATIONS---------------------------------//

    useEffect(() => {
        const markUnreadNotificationsRead = async (data: any) => {
            const unreadNotifications = data.filter(
                (notification: any) => notification.read === 'no'
            )
            //if there are unread notifications, mark them as read
            if (unreadNotifications.length > 0) {
                await markAllNotificationsRead(data)
            }
        }
        if (data) {
            markUnreadNotificationsRead(data)
        }
    }, [data])

    //---------------------------------NOTIFICATION NUMBER---------------------------------//
    const getNotificationNumber = () => {
        if (data) {
            const unreadNotifications = data.filter(
                (notification: any) => notification.read === 'no'
            )
            return unreadNotifications.length
        }
        return 0
    }
    //---------------------------------NOTIFICATION NUMBER---------------------------------//

    //---------------------------------RENDER ROW---------------------------------//
    const NotifRow = ({ index, style }: any) => (
        <div style={style}>
            <ListItemButton
                onClick={() => {
                    if (data[index].type === 'proposal') {
                        setActiveItem(['proposals'])
                        navigate('./proposals')
                    } else if (data[index].type === 'balance') {
                        setActiveItem(['billing'])
                        navigate('./billing')
                    } else if (data[index].type === 'warning') {
                        setActiveItem(['dashboard'])
                        navigate('./dashboard')
                    }
                }}
            >
                <ListItemAvatar>
                    <Avatar
                        sx={{
                            color: 'success.main',
                            bgcolor: 'success.lighter',
                        }}
                    >
                        {data && data[index].type === 'warning' ? (
                            <WarningOutlined color="warning" />
                        ) : data && data[index]?.type === 'balance' ? (
                            <MoneyOutlined color="success" />
                        ) : data && data[index]?.type === 'proposal' ? (
                            <ReceiptLongOutlined color="primary" />
                        ) : (
                            <InfoOutlined color="warning" />
                        )}
                    </Avatar>
                </ListItemAvatar>
                <ListItemText
                    primary={
                        <Typography variant="h6">
                            <Typography component="span" variant="subtitle1">
                                {data[index]?.title}
                            </Typography>{' '}
                        </Typography>
                    }
                />
                <ListItemSecondaryAction>
                    {data[index]?.read === 'no' ? (
                        <Box
                            height={10}
                            width={10}
                            borderRadius={15}
                            bgcolor="primary.main"
                        />
                    ) : null}
                </ListItemSecondaryAction>
                <Divider />
            </ListItemButton>
        </div>
    )
    //---------------------------------RENDER ROW---------------------------------//

    return (
        <Box sx={{ flexShrink: 0, ml: 0.75 }}>
            <IconButton
                color="secondary"
                sx={{ color: 'text.primary', marginLeft: '14px' }}
                aria-label="open profile"
                ref={anchorRef}
                aria-controls={open ? 'profile-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
                size="large"
            >
                <Badge badgeContent={getNotificationNumber()} color="primary">
                    <BellOutlined />
                </Badge>
            </IconButton>
            <Popper
                placement={matchesXs ? 'bottom' : 'bottom-end'}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [matchesXs ? -5 : 0, 9],
                            },
                        },
                    ],
                }}
            >
                {({ TransitionProps }) => (
                    <Transitions type="fade" in={open} {...TransitionProps}>
                        <Paper
                            sx={{
                                boxShadow:
                                    theme.palette.mode === 'light'
                                        ? theme.customShadows.light
                                        : theme.customShadows.dark,
                                // width: '100%',
                                minWidth: 285,
                                maxWidth: matchesXs ? width : 420,
                            }}
                        >
                            <ClickAwayListener onClickAway={handleClose}>
                                <MainCard
                                    title="Notification"
                                    elevation={0}
                                    border={false}
                                    content={false}
                                    secondary={
                                        <IconButton
                                            size="small"
                                            onClick={handleToggle}
                                        >
                                            <CloseOutlined />
                                        </IconButton>
                                    }
                                >
                                    {data && !isLoading && !isError ? (
                                        <List
                                            itemCount={data.length}
                                            itemSize={72}
                                            height={matchesXs ? height : 400}
                                            width={matchesXs ? width : 420}
                                        >
                                            {NotifRow}
                                        </List>
                                    ) : null}
                                </MainCard>
                            </ClickAwayListener>
                        </Paper>
                    </Transitions>
                )}
            </Popper>
        </Box>
    )
}

export default Notification
