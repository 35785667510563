import create from 'zustand'
import { devtools, persist } from 'zustand/middleware'

//types
import {
    SrTicketReport,
    OpenBalanceType,
    OpenBalancesObjType,
    ProposalType,
} from 'types'

interface MiscellaneousState {
    createTicketModalOpen: boolean
    setCreateTicketModalOpen: (open: boolean) => void
    setTicketDetailModal: (open: boolean, ticketDetailID: string | null) => void
    ticketDetailID: string | null
    ticketDetailModalOpen: boolean
    usageModalOpen: boolean
    usageModalData: SrTicketReport[] | null
    setUsageModal: (open: boolean, reportData: SrTicketReport[] | null) => void
    ticketCreationDisclaimerModal: boolean
    ticketDisclaimerAgreed: boolean
    setTicketCreationDisclaimerModal: (open: boolean) => void
    setTicketDisclaimerAgreed: (agreed: boolean) => void
    paymentModalOpen: boolean
    isProposal: ProposalType | null
    transactionInfo:
        | (OpenBalancesObjType & OpenBalanceType & { amoutDue: string })
        | null
    setPaymentModalOpen: (
        open: boolean,
        isProposal: ProposalType | null,
        transactionInfo:
            | (OpenBalancesObjType & OpenBalanceType & { amoutDue: string })
            | null
    ) => void
    closeAllModals: () => void
}
const useMiscellaneous = create<MiscellaneousState>()(
    devtools(
        persist((set) => ({
            createTicketModalOpen: false,
            setCreateTicketModalOpen: (open: boolean) => {
                set((state) => ({
                    ...state,
                    createTicketModalOpen: open,
                }))
            },
            setTicketDetailModal: (
                open: boolean,
                ticketDetailID: string | null
            ) => {
                set((state) => ({
                    ...state,
                    ticketDetailModalOpen: open,
                    ticketDetailID: ticketDetailID,
                }))
            },
            ticketDetailID: null,
            ticketDetailModalOpen: false,
            usageModalOpen: false,
            usageModalData: [],
            setUsageModal: (open: boolean, reportData: any[] | null) => {
                set((state) => ({
                    ...state,
                    usageModalOpen: open,
                    usageModalData: reportData,
                }))
            },
            ticketCreationDisclaimerModal: false,
            ticketDisclaimerAgreed: false,
            setTicketCreationDisclaimerModal: (open: boolean) => {
                set((state) => ({
                    ...state,
                    ticketCreationDisclaimerModal: open,
                }))
            },
            setTicketDisclaimerAgreed: (agreed: boolean) => {
                set((state) => ({
                    ...state,
                    ticketDisclaimerAgreed: agreed,
                }))
            },
            paymentModalOpen: false,
            transactionInfo: null,
            isProposal: null,
            setPaymentModalOpen: (
                open: boolean,
                isProposal: ProposalType | null,
                transactionInfo:
                    | (OpenBalancesObjType &
                          OpenBalanceType & { amoutDue: string })
                    | null
            ) => {
                set((state) => ({
                    ...state,
                    paymentModalOpen: open,
                    transactionInfo: transactionInfo,
                    isProposal: isProposal,
                }))
            },
            closeAllModals: () => {
                set((state) => ({
                    ...state,
                    createTicketModalOpen: false,
                    ticketDetailModalOpen: false,
                    usageModalOpen: false,
                    ticketCreationDisclaimerModal: false,
                    paymentModalOpen: false,
                    transactionInfo: null,
                    ticketDetailID: null,
                    usageModalData: null,
                    ticketDisclaimerAgreed: false,
                }))
            },
        }))
    )
)

export default useMiscellaneous
