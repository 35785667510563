import React, { useState } from 'react'

// material-ui
import {
    Button,
    FormHelperText,
    Grid,
    InputLabel,
    OutlinedInput,
    Stack,
    Typography,
    Box,
    IconButton,
    CircularProgress,
} from '@mui/material'

// third party
import * as Yup from 'yup'
import { Formik } from 'formik'

// project import
import AnimateButton from 'components/@extended/AnimateButton'

//react router
import { useNavigate } from 'react-router-dom'

//config
import { API_URL } from 'config'

//components
import AuthCard from '../authentication/AuthCard'
import ConfirmCode from './ConfirmCode'
import ResetPass from 'pages/forgotPassword/ResetPass'

// assets
import AuthBackground from 'assets/images/auth/AuthBackground'
import { CloseOutlined } from '@ant-design/icons'

//zustand
import useBusinessLogic from 'store/useBusinessLogic'

// ================================|| LOGIN ||================================ //

const SendCodeScreen: React.FC = (): JSX.Element => {
    const [enteringCode, setEnteringCode] = useState<boolean>(false)
    const [enteringNewPassword, setEnteringNewPassword] =
        useState<boolean>(false)

    //navigation
    const navigate = useNavigate()

    //zustand
    const setResetPassEmail = useBusinessLogic.getState().setResetPassEmail

    return (
        <Box sx={{ minHeight: '100vh' }}>
            <AuthBackground shouldAnimate={false} />

            <Grid item xs={12}>
                <Grid
                    item
                    xs={12}
                    container
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                        minHeight: {
                            xs: 'calc(100vh - 134px)',
                            md: 'calc(100vh - 112px)',
                        },
                    }}
                >
                    <Grid item justifyContent="center" alignItems="center">
                        {enteringCode ? (
                            <ConfirmCode
                                goBack={() => setEnteringCode(false)}
                                enteringNewPassword={() => {
                                    setEnteringNewPassword(true)
                                    setEnteringCode(false)
                                }}
                            />
                        ) : enteringNewPassword ? (
                            <ResetPass
                                goBack={() => {
                                    setEnteringCode(false)
                                    setEnteringNewPassword(false)
                                }}
                            />
                        ) : (
                            <AuthCard>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <Stack
                                            direction="row"
                                            justifyContent="space-between"
                                            alignItems="baseline"
                                            sx={{
                                                mb: {
                                                    xs: -0.5,
                                                    sm: 0.5,
                                                    lg: 2,
                                                },
                                            }}
                                        >
                                            <Typography variant="h3">
                                                Forgot Your Password?
                                            </Typography>
                                            <IconButton
                                                aria-label="go-back"
                                                onClick={() => {
                                                    navigate(-1)
                                                }}
                                                color="secondary"
                                                sx={{
                                                    color: 'text.primary',
                                                    mr: 1,
                                                }}
                                            >
                                                <CloseOutlined />
                                            </IconButton>
                                        </Stack>
                                        <Typography
                                            variant="h6"
                                            color="textSecondary"
                                        >
                                            Enter your email address below, and
                                            if an account exists, we’ll send you
                                            a code to reset your password.
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <>
                                            <Formik
                                                initialValues={{
                                                    email: '',
                                                    submit: null,
                                                }}
                                                validationSchema={Yup.object().shape(
                                                    {
                                                        email: Yup.string()
                                                            .email(
                                                                'Must Be a Valid Email'
                                                            )
                                                            .max(255)
                                                            .required(
                                                                'Email is Required'
                                                            ),
                                                    }
                                                )}
                                                onSubmit={async (
                                                    values,
                                                    {
                                                        setErrors,
                                                        setStatus,
                                                        setSubmitting,
                                                    }
                                                ) => {
                                                    try {
                                                        setStatus({
                                                            success: false,
                                                        })
                                                        setSubmitting(true)
                                                        const data =
                                                            await fetch(
                                                                `${API_URL}/users/forgotPassword`,
                                                                {
                                                                    method: 'POST',
                                                                    headers: {
                                                                        'Content-Type':
                                                                            'application/json',
                                                                    },
                                                                    body: JSON.stringify(
                                                                        {
                                                                            email: values.email,
                                                                        }
                                                                    ),
                                                                }
                                                            )

                                                        if (data.ok) {
                                                            setSubmitting(false)
                                                            setEnteringCode(
                                                                true
                                                            )
                                                        } else {
                                                            throw Error(
                                                                'Something Went Wrong!'
                                                            )
                                                        }
                                                    } catch (err:
                                                        | Error
                                                        | any
                                                        | undefined) {
                                                        setStatus({
                                                            success: false,
                                                        })
                                                        setErrors({
                                                            submit: 'Something Went Wrong!',
                                                        })
                                                        setSubmitting(false)
                                                    }
                                                }}
                                            >
                                                {({
                                                    errors,
                                                    handleBlur,
                                                    handleChange,
                                                    handleSubmit,
                                                    isSubmitting,
                                                    touched,
                                                    values,
                                                }) => (
                                                    <form
                                                        noValidate
                                                        onSubmit={handleSubmit}
                                                    >
                                                        <Grid
                                                            container
                                                            spacing={3}
                                                        >
                                                            <Grid item xs={12}>
                                                                <Stack
                                                                    spacing={1}
                                                                >
                                                                    <InputLabel htmlFor="email-login">
                                                                        Email
                                                                        Address
                                                                    </InputLabel>
                                                                    <OutlinedInput
                                                                        id="email-login"
                                                                        type="email"
                                                                        value={
                                                                            values.email
                                                                        }
                                                                        name="email"
                                                                        onBlur={
                                                                            handleBlur
                                                                        }
                                                                        onChange={(
                                                                            e
                                                                        ) => {
                                                                            handleChange(
                                                                                e
                                                                            )
                                                                            setResetPassEmail(
                                                                                e
                                                                                    .target
                                                                                    .value
                                                                            )
                                                                        }}
                                                                        placeholder="Enter email address"
                                                                        fullWidth
                                                                        error={Boolean(
                                                                            touched.email &&
                                                                                errors.email
                                                                        )}
                                                                    />
                                                                    {touched.email &&
                                                                        errors.email && (
                                                                            <FormHelperText
                                                                                error
                                                                                id="standard-weight-helper-text-email-login"
                                                                            >
                                                                                {
                                                                                    errors.email
                                                                                }
                                                                            </FormHelperText>
                                                                        )}
                                                                </Stack>
                                                            </Grid>

                                                            {errors.submit && (
                                                                <Grid
                                                                    item
                                                                    xs={12}
                                                                >
                                                                    <FormHelperText
                                                                        error
                                                                    >
                                                                        {
                                                                            errors.submit
                                                                        }
                                                                    </FormHelperText>
                                                                </Grid>
                                                            )}
                                                            <Grid item xs={12}>
                                                                <AnimateButton>
                                                                    {/*// @ts-ignore */}
                                                                    <Button
                                                                        disableElevation
                                                                        disabled={
                                                                            isSubmitting
                                                                        }
                                                                        fullWidth
                                                                        size="large"
                                                                        type="submit"
                                                                        variant="contained"
                                                                        color="primary"
                                                                        onClick={
                                                                            handleSubmit
                                                                        }
                                                                        startIcon={
                                                                            isSubmitting && (
                                                                                <CircularProgress
                                                                                    size="1rem"
                                                                                    color="inherit"
                                                                                />
                                                                            )
                                                                        }
                                                                    >
                                                                        Send
                                                                        Code
                                                                    </Button>
                                                                </AnimateButton>
                                                            </Grid>
                                                        </Grid>
                                                    </form>
                                                )}
                                            </Formik>
                                        </>
                                    </Grid>
                                </Grid>
                            </AuthCard>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    )
}
export default SendCodeScreen
