import React from 'react'

// material-ui
import {
    Box,
    Button,
    CardContent,
    CustomTheme,
    IconButton,
    useTheme,
} from '@mui/material'
import { DataGrid, GridToolbar, GridColDef } from '@mui/x-data-grid'

//zustand
import useMiscellaneous from 'store/useMiscellaneous'

//components
import MainCard from 'components/MainCard'
import { CloseOutlined } from '@ant-design/icons'

//mantis
import { useFocusTrap } from '@mantine/hooks'

const UsageModal: React.FC<{ closeModal: () => void }> = ({
    closeModal,
}): JSX.Element | null => {
    //zustand
    const setTicketDetailModal =
        useMiscellaneous.getState().setTicketDetailModal

    const usageModalData = useMiscellaneous((state) => state.usageModalData)

    //focus trap
    const focusTrapRef = useFocusTrap()

    const headCells: GridColDef[] = [
        {
            field: 'ticketID',
            headerName: 'TicketID',
            renderCell: (params) => (
                <Button
                    onClick={() => {
                        setTicketDetailModal(true, params.row.worklogID)
                    }}
                >
                    {params.value}
                </Button>
            ),
            minWidth: 100,
        },
        {
            field: 'completedDate',
            headerName: 'Completion Date',
            minWidth: 150,
        },
        {
            field: 'personName',
            headerName: 'Requestor',
            flex: 1,
            minWidth: 150,
        },
        {
            field: 'issue',
            headerName: 'Issue',
            flex: 1,
            minWidth: 150,
        },
        {
            field: 'resolution',
            headerName: 'Resolution',
            flex: 1,
            minWidth: 150,
        },
        {
            field: 'status',
            headerName: 'Status',
        },
        {
            field: 'techName',
            headerName: 'Tech Name',
            flex: 1,
            minWidth: 150,
        },
    ]

    return (
        <MainCard
            sx={{
                height: window.innerHeight * 0.8,
                width: '90%',
            }}
        >
            <div
                ref={focusTrapRef}
                style={{
                    height: window.innerHeight * 0.7,
                    width: '100%',
                }}
            >
                <Box
                    width="100%"
                    display="flex"
                    justifyContent="flex-end"
                    mb={2}
                >
                    <IconButton
                        aria-label="button"
                        role="button"
                        onClick={closeModal}
                        color="secondary"
                        sx={{
                            color: 'text.primary',
                        }}
                    >
                        <CloseOutlined />
                    </IconButton>
                </Box>
                <DataGrid
                    rows={usageModalData || []}
                    columns={headCells}
                    components={{
                        Toolbar: GridToolbar,
                    }}
                    scrollbarSize={10}
                    sx={{
                        p: 2,
                        borderRadius: 2,
                        borderWidth: 0.5,
                    }}
                    onRowClick={(e) => {
                        setTicketDetailModal(true, e.row.worklogID)
                    }}
                />
            </div>
        </MainCard>
    )
}

export default UsageModal
