import { CustomTheme, LinearProgressPropsColorOverrides } from '@mui/material'

const LinearProgress = (
    theme: CustomTheme
): LinearProgressPropsColorOverrides => {
    return {
        MuiLinearProgress: {
            styleOverrides: {
                root: {
                    height: 6,
                    borderRadius: 100,
                },
                bar: {
                    borderRadius: 100,
                },
            },
        },
    }
}

export default LinearProgress
