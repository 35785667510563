import React from 'react'
import { useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'

// material-ui
import {
    Box,
    Link,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    SortDirection,
} from '@mui/material'
import Skeleton from '@mui/material/Skeleton'

// project import

import { TableHeadCellType } from 'types'

const headCells: Array<TableHeadCellType> = [
    {
        id: 'TicketID',
        align: 'left',
        disablePadding: false,
        label: 'Ticket ID',
    },
    {
        id: 'name',
        align: 'left',
        disablePadding: true,
        label: 'Requestor',
    },
    {
        id: 'time',
        align: 'right',
        disablePadding: false,
        label: 'Time of Request',
    },
    {
        id: 'carbs',
        align: 'left',
        disablePadding: false,

        label: 'Status',
    },
    {
        id: 'tech',
        align: 'left',
        disablePadding: false,
        label: 'Tech Assigned',
    },
    {
        id: 'description',
        align: 'right',
        disablePadding: false,
        label: 'Ticket Description',
    },
]

// ==============================|| ORDER TABLE - HEADER ||============================== //

const TableHeader = ({
    order,
    orderBy,
}: {
    order: SortDirection
    orderBy: string
}): JSX.Element => {
    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell: TableHeadCellType) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.align}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <Skeleton
                            variant="text"
                            animation="wave"
                            sx={{
                                borderRadius: 1,
                            }}
                        />
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    )
}

// ==============================|| ORDER TABLE - STATUS ||============================== //

const TicketStatus = () => {
    return (
        <Stack direction="row" spacing={1} alignItems="center">
            <Skeleton
                variant="circular"
                height={10}
                width={10}
                animation="wave"
                sx={{
                    borderRadius: 1,
                }}
            />
            <Skeleton
                variant="text"
                animation="wave"
                width={'100%'}
                sx={{
                    borderRadius: 1,
                }}
            />
        </Stack>
    )
}

// ==============================|| ORDER TABLE ||============================== //

const collection = (numberOfCells: number) => {
    // eslint-disable-next-line
    for (var array = [], i = 0; i < numberOfCells; i++) {
        array.push(i)
    }
    return array
}

const RecentlyResolvedSkeleton: React.FC<{
    height?: number
}> = ({ height }): JSX.Element => {
    const [order] = useState<SortDirection>('asc')
    const [orderBy] = useState('trackingNo')

    let fillerData = []

    if (height) {
        const numberOfCells = Math.floor(height / 20)
        fillerData = collection(numberOfCells)
    } else {
        fillerData = collection(10)
    }

    return (
        <Box>
            <TableContainer
                sx={{
                    width: '100%',
                    overflowX: 'auto',
                    position: 'relative',
                    display: 'block',
                    maxWidth: '100%',
                    '& td, & th': { whiteSpace: 'nowrap' },
                    ...(height && {
                        height,
                    }),
                }}
            >
                <Table
                    aria-labelledby="tableTitle"
                    sx={{
                        '& .MuiTableCell-root:first-child': {
                            pl: 2,
                        },
                        '& .MuiTableCell-root:last-child': {
                            pr: 3,
                        },
                    }}
                >
                    <TableHeader order={order} orderBy={orderBy} />
                    <TableBody>
                        {fillerData.map((index: number) => {
                            const labelId = `enhanced-table-checkbox-${index}`

                            return (
                                <TableRow
                                    hover
                                    role="checkbox"
                                    sx={{
                                        '&:last-child td, &:last-child th': {
                                            border: 0,
                                        },
                                    }}
                                    // aria-checked={isItemSelected}
                                    tabIndex={-1}
                                    key={index?.toString()}
                                    // selected={isItemSelected}
                                >
                                    <TableCell
                                        component="th"
                                        id={labelId}
                                        scope="row"
                                        align="left"
                                    >
                                        <Link
                                            color="secondary"
                                            component={RouterLink}
                                            to=""
                                        >
                                            <Skeleton
                                                variant="text"
                                                animation="wave"
                                                sx={{
                                                    borderRadius: 1,
                                                }}
                                            />
                                        </Link>
                                    </TableCell>
                                    <TableCell align="left">
                                        <Skeleton
                                            variant="text"
                                            animation="wave"
                                            sx={{
                                                borderRadius: 1,
                                            }}
                                        />
                                    </TableCell>
                                    <TableCell align="right">
                                        <Skeleton
                                            variant="text"
                                            animation="wave"
                                            sx={{
                                                borderRadius: 1,
                                            }}
                                        />
                                    </TableCell>
                                    <TableCell align="left">
                                        <TicketStatus />
                                    </TableCell>
                                    <TableCell align="left">
                                        <Skeleton
                                            variant="text"
                                            animation="wave"
                                            sx={{
                                                borderRadius: 1,
                                            }}
                                        />
                                    </TableCell>
                                    <TableCell align="right">
                                        <Skeleton
                                            variant="text"
                                            animation="wave"
                                            sx={{
                                                borderRadius: 1,
                                            }}
                                        />
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    )
}

export default RecentlyResolvedSkeleton
