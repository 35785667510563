import React from 'react'

//react router
import { useNavigate, useLocation } from 'react-router-dom'

// material-ui
import {
    Button,
    FormHelperText,
    Grid,
    OutlinedInput,
    Stack,
    Typography,
    IconButton,
    FormControl,
    InputAdornment,
    Box,
} from '@mui/material'

// third party
import * as Yup from 'yup'
import { Formik } from 'formik'

// project import
import AnimateButton from 'components/@extended/AnimateButton'

//zustand

//config
import { API_URL } from 'config'

// project import

//components
import AuthCard from '../authentication/AuthCard'

// icons
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'

import { strengthColor, strengthIndicator } from 'utils/password-strength'

// assets
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons'

//types
import { PassStrengthType } from 'types'

//toast
import { toast } from 'react-toastify'

//zustand
import useBusinessLogic from 'store/useBusinessLogic'

//react-query
import { queryCache } from 'react-query'

//sentry
import * as Sentry from '@sentry/react'

const ResetPass: React.FC<{
    goBack: () => void
}> = ({ goBack }): JSX.Element => {
    //zustand
    const setToken = useBusinessLogic((state) => state.setToken)
    const setProfileInfo = useBusinessLogic((state) => state.setProfileInfo)
    const resetPassEmail = useBusinessLogic((state) => state.resetPassEmail)

    //navigation
    const navigate = useNavigate()
    const location = useLocation()

    //@ts-ignore
    const from = location?.state?.from?.pathname || '/dashboard'

    const [level, setLevel] = React.useState<PassStrengthType>()
    const [showPassword, setShowPassword] = React.useState(false)
    // const [existingCompany, setExistingCompany] = useState(false)
    const handleClickShowPassword = () => {
        setShowPassword(!showPassword)
    }

    const changePassword = (value: string) => {
        const temp: number = strengthIndicator(value)
        setLevel(strengthColor(temp))
    }

    const handleMouseDownPassword = (event: any) => {
        event?.preventDefault()
    }

    React.useEffect(() => {
        changePassword('')
    }, [])

    return (
        <AuthCard>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Stack
                        direction="row"
                        alignItems="space-between"
                        justifyContent="space-between"
                        sx={{ mb: { xs: -0.5, sm: 0.5 } }}
                    >
                        <IconButton
                            aria-label="go-back"
                            onClick={goBack}
                            color="secondary"
                            sx={{
                                color: 'text.primary',
                                mr: 1,
                            }}
                        >
                            <ChevronLeftIcon />
                        </IconButton>
                        <Typography variant="h3">Enter New Password</Typography>
                    </Stack>
                </Grid>
                <Grid item xs={12}>
                    <>
                        <Formik
                            initialValues={{
                                password: '',
                                password2: '',
                                submit: null,
                            }}
                            validationSchema={Yup.object().shape({
                                password: Yup.string()
                                    .max(255)
                                    .required('Password is Required'),
                                password2: Yup.string()
                                    .max(255)
                                    .required('Password is Required')
                                    .when('password', {
                                        is: (password: string) =>
                                            password && password.length > 0
                                                ? true
                                                : false,
                                        then: Yup.string().oneOf(
                                            [Yup.ref('password')],
                                            'Passwords Do Not Match.'
                                        ),
                                    }),
                            })}
                            onSubmit={async (
                                values,
                                { setErrors, setStatus, setSubmitting }
                            ) => {
                                try {
                                    setStatus({
                                        success: false,
                                    })
                                    setSubmitting(true)

                                    if (!resetPassEmail) {
                                        goBack()
                                    }

                                    const body = JSON.stringify({
                                        password: values.password,
                                        confirmPassword: values.password2,
                                        email: resetPassEmail,
                                    })

                                    const data = await fetch(
                                        `${API_URL}/users/resetPassword`,
                                        {
                                            method: 'POST',
                                            headers: {
                                                'Content-Type':
                                                    'application/json',
                                            },
                                            body,
                                        }
                                    )

                                    if (data.ok) {
                                        const results = await data.json()
                                        if (
                                            results.message ===
                                            'Password has been reset'
                                        ) {
                                            toast.success(
                                                'Password has been reset successfully.'
                                            )
                                            queryCache.clear()
                                            setToken(results?.token)
                                            setProfileInfo(results?.user)
                                            navigate(from, { replace: true })
                                        }
                                    }
                                    setSubmitting(false)
                                } catch (err: Error | any | undefined) {
                                    Sentry.captureException(err)
                                    console.log(
                                        err.message || 'Something went wrong'
                                    )
                                    setStatus({
                                        success: false,
                                    })
                                    setErrors({
                                        submit: err?.message,
                                    })
                                    setSubmitting(false)
                                }
                            }}
                        >
                            {({
                                errors,
                                handleBlur,
                                handleChange,
                                handleSubmit,
                                isSubmitting,
                                touched,
                                values,
                            }) => (
                                <form noValidate onSubmit={handleSubmit}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <Stack spacing={1}>
                                                <OutlinedInput
                                                    fullWidth
                                                    error={Boolean(
                                                        touched.password &&
                                                            errors.password
                                                    )}
                                                    id="password"
                                                    type={
                                                        showPassword
                                                            ? 'text'
                                                            : 'password'
                                                    }
                                                    value={values.password}
                                                    name="password"
                                                    onBlur={handleBlur}
                                                    onChange={(e) => {
                                                        handleChange(e)
                                                        changePassword(
                                                            e.target.value
                                                        )
                                                    }}
                                                    autoComplete="on"
                                                    endAdornment={
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                onClick={
                                                                    handleClickShowPassword
                                                                }
                                                                onMouseDown={
                                                                    handleMouseDownPassword
                                                                }
                                                                edge="end"
                                                                size="medium"
                                                            >
                                                                {showPassword ? (
                                                                    <EyeOutlined />
                                                                ) : (
                                                                    <EyeInvisibleOutlined />
                                                                )}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    }
                                                    placeholder="******"
                                                    inputProps={{}}
                                                />
                                                {touched.password &&
                                                    errors.password && (
                                                        <FormHelperText
                                                            error
                                                            id="standard-weight-helper-text-code-login"
                                                        >
                                                            {errors.password}
                                                        </FormHelperText>
                                                    )}
                                            </Stack>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Stack spacing={1}>
                                                <OutlinedInput
                                                    fullWidth
                                                    error={Boolean(
                                                        touched.password2 &&
                                                            errors.password2
                                                    )}
                                                    id="password2"
                                                    type={
                                                        showPassword
                                                            ? 'text'
                                                            : 'password'
                                                    }
                                                    value={values.password2}
                                                    name="password2"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    autoComplete="on"
                                                    endAdornment={
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                onClick={
                                                                    handleClickShowPassword
                                                                }
                                                                onMouseDown={
                                                                    handleMouseDownPassword
                                                                }
                                                                edge="end"
                                                                size="medium"
                                                            >
                                                                {showPassword ? (
                                                                    <EyeOutlined />
                                                                ) : (
                                                                    <EyeInvisibleOutlined />
                                                                )}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    }
                                                    placeholder="******"
                                                    inputProps={{}}
                                                />
                                                {touched.password2 &&
                                                    errors.password2 && (
                                                        <FormHelperText
                                                            error
                                                            id="standard-weight-helper-text-code-login"
                                                        >
                                                            {errors.password2}
                                                        </FormHelperText>
                                                    )}
                                            </Stack>
                                        </Grid>

                                        {errors.submit && (
                                            <Grid item xs={12}>
                                                <FormHelperText error>
                                                    {errors.submit}
                                                </FormHelperText>
                                            </Grid>
                                        )}

                                        <Grid item xs={12}>
                                            <FormControl
                                                fullWidth
                                                sx={{ mt: 2 }}
                                            >
                                                <Grid
                                                    container
                                                    spacing={2}
                                                    alignItems="center"
                                                >
                                                    <Grid item>
                                                        <Box
                                                            sx={{
                                                                bgcolor:
                                                                    level?.color,
                                                                width: 85,
                                                                height: 8,
                                                                borderRadius:
                                                                    '7px',
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography
                                                            variant="subtitle1"
                                                            fontSize="0.75rem"
                                                        >
                                                            {level?.label}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <AnimateButton>
                                                {/*// @ts-ignore */}
                                                <Button
                                                    disableElevation
                                                    disabled={isSubmitting}
                                                    fullWidth
                                                    size="large"
                                                    type="submit"
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={handleSubmit}
                                                >
                                                    Reset Password
                                                </Button>
                                            </AnimateButton>
                                        </Grid>
                                    </Grid>
                                </form>
                            )}
                        </Formik>
                    </>
                </Grid>
            </Grid>
        </AuthCard>
    )
}

export default ResetPass
