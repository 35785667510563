import React from 'react'
// material-ui
import { Grid, Typography, Divider, List } from '@mui/material'

import AuthCard from 'pages/authentication/AuthCard'

//react-query
import { useQuery } from '@tanstack/react-query'
import { queryKeys, queryClient } from 'react-query'

//components
import MainCard from 'components/MainCard'
import ProposalRow from 'components/ProposalRow'
import ProposalsSkeleton from 'components/skeletons/ProposalsSkeleton'

//types
import { ProposalType } from 'types'

//zustand
import useMiscellaneous from 'store/useMiscellaneous'

//api
import { headersFunction } from 'api'
import { API_URL } from 'config'

//api
import { getPendingProposals, getCompletedProposals } from 'api'

//roast
import { toast } from 'react-toastify'

const ProposalsPage = () => {
    //---------------------- QUERY DATA -----------------//
    const {
        data: pendingProposals = [],
        isLoading: pendingProposalsLoading,
        isError: pendingProposalsError,
    } = useQuery([queryKeys.pendingProposals], getPendingProposals)

    const {
        data: completedProposals = [],
        isLoading: completedProposalsLoading,
        isError: completedProposalsError,
    } = useQuery([queryKeys.completedProposals], getCompletedProposals)

    //---------------------- QUERY DATA -----------------//

    //----------------------APPROVE PROPOSAL----------------------//
    const setPaymentModalOpen = useMiscellaneous.getState().setPaymentModalOpen

    const handleApproveProposal = React.useCallback(
        (rowInfo: string, proposal: ProposalType) => {
            //@ts-ignore
            setPaymentModalOpen(true, proposal, {
                amoutDue: rowInfo,
            })
        },
        [setPaymentModalOpen]
    )
    //----------------------APPROVE PROPOSAL----------------------//
    //----------------------REJECT PROPOSAL----------------------//
    const handleRejectProposal = async (proposal: ProposalType) => {
        try {
            const response = await fetch(
                `${API_URL}/finances/proposalResponse`,
                {
                    method: 'POST',
                    headers: headersFunction(),
                    body: JSON.stringify({
                        proposalID: proposal?.id?.toString(),
                        response: 'reject',
                    }),
                }
            )

            const data = await response.json()

            if (data.error) {
                throw new Error(data.error)
            } else {
                queryClient.invalidateQueries([queryKeys.pendingProposals])
                toast.warn('Proposal Rejected!')
                setPaymentModalOpen(false, null, null)
                return
            }
        } catch (error) {
            console.log(error)
            toast.error('Something went wrong!')
        }
    }
    //----------------------REJECT PROPOSAL----------------------//

    return (
        <Grid container rowSpacing={2} columnSpacing={2}>
            <Grid item xs={12} md={12} lg={12}>
                <AuthCard
                    sx={{
                        width: '100%',
                    }}
                >
                    <Grid container rowSpacing={1} columnSpacing={1.5}>
                        <Grid item xs={6} md={12} lg={12}>
                            <Typography
                                variant="h3"
                                sx={{
                                    mb: 1,
                                }}
                            >
                                Proposals
                            </Typography>
                        </Grid>

                        <Grid item xs={6} md={12} lg={12} sx={{ mt: 3 }}>
                            <Typography
                                variant="h5"
                                sx={{
                                    mb: 1,
                                }}
                            >
                                Pending Proposals
                            </Typography>
                        </Grid>

                        <Grid item xs={12} md={12} lg={12}>
                            {!pendingProposalsError && (
                                <MainCard
                                    content
                                    contentSX={{
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        p: 0,
                                        mb: -2.5,
                                    }}
                                >
                                    {pendingProposalsLoading ? (
                                        <ProposalsSkeleton />
                                    ) : pendingProposals &&
                                      !pendingProposalsError ? (
                                        <List>
                                            {pendingProposals.map(
                                                (
                                                    proposal: ProposalType,
                                                    index: number
                                                ) => (
                                                    <>
                                                        <ProposalRow
                                                            key={
                                                                proposal.id.toString() ||
                                                                index.toString()
                                                            }
                                                            proposal={proposal}
                                                            onApprovePressed={() => {
                                                                handleApproveProposal(
                                                                    proposal.deposit?.toString(),
                                                                    proposal
                                                                )
                                                            }}
                                                            onRejectPressed={() => {
                                                                handleRejectProposal(
                                                                    proposal
                                                                )
                                                            }}
                                                        />
                                                        {index !==
                                                            pendingProposals.length -
                                                                1 && (
                                                            <Divider
                                                                sx={{
                                                                    mb: 1,
                                                                }}
                                                            />
                                                        )}
                                                    </>
                                                )
                                            )}
                                        </List>
                                    ) : (
                                        <Typography
                                            variant="h6"
                                            sx={{ textAlign: 'center', py: 3 }}
                                        >
                                            You Currently Have No Pending
                                            Proposals.
                                        </Typography>
                                    )}
                                </MainCard>
                            )}
                        </Grid>
                        {completedProposals &&
                            completedProposals.length > 0 && (
                                <>
                                    <Grid item xs={6} sx={{ mt: 3 }}>
                                        <Typography
                                            variant="h5"
                                            sx={{
                                                mb: 1,
                                            }}
                                        >
                                            Completed Proposals
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={12} lg={12}>
                                        {!completedProposalsError && (
                                            <MainCard
                                                content
                                                contentSX={{
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    p: 0,
                                                    mb: -2.5,
                                                }}
                                            >
                                                {completedProposalsLoading ? (
                                                    <ProposalsSkeleton />
                                                ) : completedProposals &&
                                                  !completedProposalsError ? (
                                                    <List>
                                                        {completedProposals.map(
                                                            (
                                                                proposal: ProposalType,
                                                                index: number
                                                            ) => (
                                                                <>
                                                                    <ProposalRow
                                                                        key={
                                                                            proposal.id.toString() ||
                                                                            index.toString()
                                                                        }
                                                                        proposal={
                                                                            proposal
                                                                        }
                                                                        onApprovePressed={() => {
                                                                            handleApproveProposal(
                                                                                proposal.deposit?.toString(),
                                                                                proposal
                                                                            )
                                                                        }}
                                                                        onRejectPressed={() => {
                                                                            handleRejectProposal(
                                                                                proposal
                                                                            )
                                                                        }}
                                                                        completed
                                                                    />
                                                                    {index !==
                                                                        completedProposals.length -
                                                                            1 && (
                                                                        <Divider
                                                                            sx={{
                                                                                mb: 1,
                                                                            }}
                                                                        />
                                                                    )}
                                                                </>
                                                            )
                                                        )}
                                                    </List>
                                                ) : (
                                                    <Typography
                                                        variant="h6"
                                                        sx={{
                                                            textAlign: 'center',
                                                            py: 3,
                                                        }}
                                                    >
                                                        You Currently Have No
                                                        Pending Proposals
                                                    </Typography>
                                                )}
                                            </MainCard>
                                        )}
                                    </Grid>
                                </>
                            )}
                    </Grid>
                </AuthCard>
            </Grid>
        </Grid>
    )
}

export default ProposalsPage
