import { CustomTheme, TablePropsSizeOverrides } from '@mui/material'

const Tabs = (theme: CustomTheme): TablePropsSizeOverrides => {
    return {
        MuiTabs: {
            styleOverrides: {
                vertical: {
                    overflow: 'visible',
                },
            },
        },
    }
}

export default Tabs
