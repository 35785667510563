// material-ui
import { alpha } from '@mui/material/styles'

import { CustomTheme } from '@mui/material'

// ==============================|| DEFAULT THEME - CUSTOM SHADOWS  ||============================== //

const CustomShadows = (theme: CustomTheme) => ({
    button: `0 2px #0000000b`,
    text: `0 -1px 0 rgb(0 0 0 / 12%)`,
    light: `0px 2px 8px ${alpha(theme.palette.grey[800], 0.15)}`,
    dark: `0px 2px 8px ${alpha(theme.palette.grey[400], 0.15)}`,
    z1: `5px 1px 40px ${alpha(theme.palette.grey[900], 0.15)}`,
})

export default CustomShadows
