// material-ui
import {
    Box,
    Chip,
    CustomTheme,
    Grid,
    Stack,
    Typography,
    useTheme,
    Divider,
    Skeleton,
    IconButton,
} from '@mui/material'

import Linkify from 'react-linkify'

//components
import MainCard from 'components/MainCard'

//react-query
import { useQuery } from '@tanstack/react-query'
import { queryKeys } from 'react-query'

//API calls
import { getTicket } from 'api'

//zustand
import useMiscellaneous from 'store/useMiscellaneous'

//icons
import {
    CalendarOutlined,
    CheckCircleOutlined,
    CloseOutlined,
    DeploymentUnitOutlined,
    IssuesCloseOutlined,
    ProfileOutlined,
    TagOutlined,
    ToolOutlined,
    UserOutlined,
    WarningOutlined,
} from '@ant-design/icons'

//mantis
import { useFocusTrap } from '@mantine/hooks'

const TicketDetail: React.FC<{
    closeModal: () => void
}> = ({ closeModal }): JSX.Element => {
    //theme
    const theme = useTheme<CustomTheme>()

    const ticketDetailID = useMiscellaneous((state) => state.ticketDetailID)

    //focus trap
    const focusTrapRef = useFocusTrap()

    //---------------------- QUERY DATA -----------------//

    const {
        data: ticket,
        isLoading,
        isError,
    } = useQuery(
        [queryKeys.ticket, ticketDetailID],
        () => getTicket(`${ticketDetailID}`),
        {
            refetchOnWindowFocus: false,
            retry: 0,
        }
    )

    //---------------------- QUERY DATA -----------------//

    return (
        <Grid
            ref={focusTrapRef}
            container
            alignItems="center"
            justifyContent="center"
            display="flex"
            sx={{
                maxHeight: '90%',
                overflow: 'scroll',
            }}
            xs={11}
            md={8}
            lg={8}
        >
            <Grid
                item
                xs={12}
                md={12}
                lg={12}
                alignItems="center"
                justifyContent="center"
                display="flex"
                sx={{
                    maxHeight: '90%',
                }}
            >
                <MainCard
                    contentSX={{
                        p: 2.25,
                    }}
                    sx={{
                        width: '100%',
                        maxHeight: '100%',
                        borderWidth: isError ? 3 : 1,
                    }}
                    isError={isError}
                >
                    {isError ? (
                        <Stack
                            direction="row"
                            spacing={2}
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                        >
                            <WarningOutlined
                                style={{
                                    fontSize: '25px',
                                    color: theme.palette.error.main,
                                }}
                            />
                            <Typography
                                variant="h5"
                                sx={{
                                    color: theme.palette.error.main,
                                    fontWeight: 700,
                                }}
                            >
                                Something went wrong! Please try again.
                            </Typography>
                        </Stack>
                    ) : (
                        <>
                            <Stack spacing={0.5}>
                                <Stack
                                    spacing={0.5}
                                    direction="row"
                                    justifyContent="space-between"
                                >
                                    <Grid
                                        item
                                        sx={{
                                            whiteSpace: 'nowrap',
                                            overflow: ' hidden',
                                            textOverflow: 'ellipsis',
                                        }}
                                    >
                                        <Typography
                                            variant="h4"
                                            color="inherit"
                                            sx={{
                                                display: 'block',
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                            }}
                                        >
                                            {isLoading ? (
                                                <Skeleton
                                                    width={200}
                                                    height={40}
                                                />
                                            ) : ticket?.isSrTicket ? (
                                                ''
                                            ) : (
                                                ticket?.CustomerName
                                            )}
                                        </Typography>
                                    </Grid>
                                    <Stack
                                        spacing={2}
                                        direction="row"
                                        alignItems="center"
                                    >
                                        <Chip
                                            variant="filled"
                                            icon={
                                                <TagOutlined
                                                    style={{
                                                        fontSize: '0.8rem',
                                                        color: 'inherit',
                                                    }}
                                                />
                                            }
                                            label={
                                                isLoading
                                                    ? '...'
                                                    : `${
                                                          ticket?.TicketID ??
                                                          ticket?.worklogID
                                                      }`
                                            }
                                            sx={{
                                                p: 0.5,
                                                width: 'fit-content',
                                            }}
                                            size="small"
                                            color="primary"
                                        />
                                        <IconButton
                                            aria-label="go-back"
                                            onClick={closeModal}
                                            color="secondary"
                                            sx={{
                                                color: 'text.primary',
                                            }}
                                        >
                                            <CloseOutlined />
                                        </IconButton>
                                    </Stack>
                                </Stack>
                                <Divider />
                                <Grid container alignItems="center"></Grid>
                            </Stack>
                            <Grid item>
                                <Stack mt={2} direction="row" spacing={1}>
                                    <UserOutlined
                                        style={{
                                            fontSize: '1.2rem',
                                            color: theme.palette.primary.main,
                                        }}
                                    />
                                    <Typography variant="h5">
                                        Requestor
                                    </Typography>
                                </Stack>
                                <Typography
                                    variant="h6"
                                    color={theme.palette.text.primary}
                                    sx={{
                                        display: 'block',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                    }}
                                >
                                    {isLoading ? (
                                        <Skeleton width={100} />
                                    ) : ticket?.isSrTicket ? (
                                        ticket.personName
                                    ) : (
                                        ticket?.EndUserFirstName +
                                        ' ' +
                                        ticket?.EndUserLastName
                                    )}
                                </Typography>
                            </Grid>
                            <Stack mt={2} direction="row" spacing={1}>
                                <IssuesCloseOutlined
                                    style={{
                                        fontSize: '1.2rem',
                                        color: theme.palette.primary.main,
                                    }}
                                />
                                <Typography variant="h5">Issue</Typography>
                            </Stack>
                            <Box
                                sx={{
                                    whiteSpace: 'nowrap',
                                    overflow: ' hidden',
                                    textOverflow: 'ellipsis',
                                    height: '45%',
                                    borderWidth: '1px',
                                    borderColor: 'black',
                                }}
                            >
                                <Linkify
                                    //@ts-ignore
                                    properties={{
                                        target: '_blank',
                                        style: { color: 'blue' },
                                    }}
                                >
                                    <Typography
                                        color={theme.palette.text.primary}
                                        sx={{
                                            display: 'block',
                                            whiteSpace: 'break-spaces',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                        }}
                                    >
                                        {isLoading ? (
                                            <>
                                                <Skeleton width={'100%'} />
                                                <Skeleton width={'100%'} />
                                                <Skeleton width={'65%'} />
                                            </>
                                        ) : ticket?.isSrTicket ? (
                                            ticket?.issue?.trim()
                                        ) : (
                                            ticket?.TicketTitle?.replace(
                                                '⚠️ This client has a open balance, please speak with accounting before completing this job.⚠️',
                                                ''
                                            )
                                        )}
                                    </Typography>
                                </Linkify>
                            </Box>

                            <Stack mt={2} direction="row" spacing={1}>
                                <CheckCircleOutlined
                                    style={{
                                        fontSize: '1.2rem',
                                        color: theme.palette.primary.main,
                                    }}
                                />
                                <Typography variant="h5">Resolution</Typography>
                            </Stack>
                            <Box
                                sx={{
                                    whiteSpace: 'nowrap',
                                    overflow: ' hidden',
                                    textOverflow: 'ellipsis',
                                    height: '45%',
                                    borderWidth: '1px',
                                    borderColor: 'black',
                                }}
                            >
                                <Typography
                                    color={theme.palette.text.primary}
                                    sx={{
                                        display: 'block',
                                        whiteSpace: 'break-spaces',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                    }}
                                >
                                    {isLoading ? (
                                        <>
                                            <Skeleton width={'100%'} />
                                            <Skeleton width={'100%'} />
                                            <Skeleton width={'65%'} />
                                        </>
                                    ) : ticket?.resolution?.trim() ? (
                                        ticket?.resolution?.trim()
                                    ) : (
                                        'No resolution yet.'
                                    )}
                                </Typography>
                            </Box>
                            {!ticket?.isSrTicket && (
                                <>
                                    <Stack my={2} direction="row" spacing={1}>
                                        <ProfileOutlined
                                            style={{
                                                fontSize: '1.2rem',
                                                color: theme.palette.primary
                                                    .main,
                                            }}
                                        />
                                        <Typography variant="h5">
                                            Details
                                        </Typography>
                                    </Stack>
                                    <MainCard
                                        sx={{
                                            backgroundColor:
                                                theme.palette.mode === 'dark'
                                                    ? theme.palette.grey[800]
                                                    : theme.palette.grey[200],
                                        }}
                                    >
                                        <Grid
                                            container
                                            alignItems="center"
                                            justifyContent="space-between"
                                            spacing={1}
                                        >
                                            <Grid
                                                sx={{
                                                    whiteSpace: 'nowrap',
                                                    overflow: ' hidden',
                                                    textOverflow: 'ellipsis',
                                                    height: '45%',
                                                    borderWidth: '1px',
                                                    borderColor: 'black',
                                                }}
                                                item
                                                sm={12}
                                                xs={12}
                                                md={'auto'}
                                                lg={'auto'}
                                            >
                                                <Stack
                                                    direction="row"
                                                    spacing={1}
                                                    alignItems="center"
                                                >
                                                    {' '}
                                                    <ToolOutlined
                                                        style={{
                                                            fontSize: '1.2rem',
                                                            color: theme.palette
                                                                .primary.main,
                                                        }}
                                                    />
                                                    <Typography variant="h5">
                                                        Technician
                                                    </Typography>
                                                </Stack>
                                                <Typography
                                                    color={
                                                        theme.palette.text
                                                            .primary
                                                    }
                                                    sx={{
                                                        display: 'block',
                                                        whiteSpace:
                                                            'break-spaces',
                                                        overflow: 'hidden',
                                                        textOverflow:
                                                            'ellipsis',
                                                    }}
                                                >
                                                    {isLoading ? (
                                                        <Skeleton width={100} />
                                                    ) : (
                                                        ticket?.TechnicianFullName
                                                    )}
                                                </Typography>
                                            </Grid>
                                            <Grid
                                                sx={{
                                                    whiteSpace: 'nowrap',
                                                    overflow: ' hidden',
                                                    textOverflow: 'ellipsis',
                                                    height: '45%',
                                                    borderWidth: '1px',
                                                    borderColor: 'black',
                                                }}
                                                item
                                                sm={12}
                                                xs={12}
                                                md={'auto'}
                                                lg={'auto'}
                                            >
                                                <Stack
                                                    direction="row"
                                                    spacing={1}
                                                    alignItems="center"
                                                >
                                                    <TagOutlined
                                                        style={{
                                                            fontSize: '1.2rem',
                                                            color: theme.palette
                                                                .primary.main,
                                                        }}
                                                    />
                                                    <Typography variant="h5">
                                                        Ticket Type
                                                    </Typography>
                                                </Stack>
                                                <Typography
                                                    color={
                                                        theme.palette.text
                                                            .primary
                                                    }
                                                    sx={{
                                                        display: 'block',
                                                        whiteSpace:
                                                            'break-spaces',
                                                        overflow: 'hidden',
                                                        textOverflow:
                                                            'ellipsis',
                                                    }}
                                                >
                                                    {isLoading ? (
                                                        <Skeleton width={100} />
                                                    ) : (
                                                        ticket?.TicketType
                                                    )}
                                                </Typography>
                                            </Grid>
                                            <Grid
                                                sx={{
                                                    whiteSpace: 'nowrap',
                                                    overflow: ' hidden',
                                                    textOverflow: 'ellipsis',
                                                    height: '45%',
                                                    borderWidth: '1px',
                                                    borderColor: 'black',
                                                }}
                                                item
                                                sm={12}
                                                xs={12}
                                                md={'auto'}
                                                lg={'auto'}
                                            >
                                                <Stack
                                                    direction="row"
                                                    spacing={1}
                                                    alignItems="center"
                                                >
                                                    {' '}
                                                    <DeploymentUnitOutlined
                                                        style={{
                                                            fontSize: '1.2rem',
                                                            color: theme.palette
                                                                .primary.main,
                                                        }}
                                                    />
                                                    <Typography variant="h5">
                                                        Ticket Source
                                                    </Typography>
                                                </Stack>
                                                <Typography
                                                    color={
                                                        theme.palette.text
                                                            .primary
                                                    }
                                                    sx={{
                                                        display: 'block',
                                                        whiteSpace:
                                                            'break-spaces',
                                                        overflow: 'hidden',
                                                        textOverflow:
                                                            'ellipsis',
                                                    }}
                                                >
                                                    {isLoading ? (
                                                        <Skeleton width={100} />
                                                    ) : (
                                                        ticket?.TicketSource
                                                    )}
                                                </Typography>
                                            </Grid>
                                            <Grid
                                                sx={{
                                                    whiteSpace: 'nowrap',
                                                    overflow: ' hidden',
                                                    textOverflow: 'ellipsis',
                                                    height: '45%',
                                                    borderWidth: '1px',
                                                    borderColor: 'black',
                                                }}
                                                item
                                                sm={12}
                                                xs={12}
                                                md={'auto'}
                                                lg={'auto'}
                                            >
                                                <Stack
                                                    direction="row"
                                                    spacing={1}
                                                    alignItems="center"
                                                >
                                                    {' '}
                                                    <CalendarOutlined
                                                        style={{
                                                            fontSize: '1.2rem',
                                                            color: theme.palette
                                                                .primary.main,
                                                        }}
                                                    />
                                                    <Typography variant="h5">
                                                        Ticket Create Date
                                                    </Typography>
                                                </Stack>
                                                <Typography
                                                    color={
                                                        theme.palette.text
                                                            .primary
                                                    }
                                                    sx={{
                                                        display: 'block',
                                                        whiteSpace:
                                                            'break-spaces',
                                                        overflow: 'hidden',
                                                        textOverflow:
                                                            'ellipsis',
                                                    }}
                                                >
                                                    {isLoading ? (
                                                        <Skeleton width={100} />
                                                    ) : (
                                                        new Date(
                                                            //@ts-ignore
                                                            ticket?.TicketCreatedDate
                                                        ).toLocaleString()
                                                    )}
                                                </Typography>
                                            </Grid>

                                            <Grid
                                                sx={{
                                                    whiteSpace: 'nowrap',
                                                    overflow: ' hidden',
                                                    textOverflow: 'ellipsis',
                                                    height: '45%',
                                                    borderWidth: '1px',
                                                    borderColor: 'black',
                                                }}
                                                item
                                                sm={12}
                                                xs={12}
                                                md={'auto'}
                                                lg={'auto'}
                                            >
                                                <Stack
                                                    direction="row"
                                                    spacing={1}
                                                    alignItems="center"
                                                >
                                                    {' '}
                                                    <CalendarOutlined
                                                        style={{
                                                            fontSize: '1.2rem',
                                                            color: theme.palette
                                                                .primary.main,
                                                        }}
                                                    />
                                                    <Typography variant="h5">
                                                        Ticket Resolve Date
                                                    </Typography>
                                                </Stack>
                                                <Typography
                                                    color={
                                                        theme.palette.text
                                                            .primary
                                                    }
                                                    sx={{
                                                        display: 'block',
                                                        whiteSpace:
                                                            'break-spaces',
                                                        overflow: 'hidden',
                                                        textOverflow:
                                                            'ellipsis',
                                                    }}
                                                >
                                                    {isLoading ? (
                                                        <Skeleton width={100} />
                                                    ) : (
                                                        new Date(
                                                            //@ts-ignore
                                                            ticket?.TicketResolvedDate
                                                        ).toLocaleString()
                                                    )}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </MainCard>
                                </>
                            )}
                        </>
                    )}
                </MainCard>
            </Grid>
        </Grid>
    )
}

export default TicketDetail
