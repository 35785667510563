//config
import { API_URL } from 'config'

//types
import {
    ProfileInfoType,
    AteraTicketType,
    OpenBalancesObjType,
    SrTicketReport,
    ProposalType,
    NotificationType,
} from 'types'

//utils
import { errorTypeHandler } from 'utils'

export const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${localStorage.getItem('token')}`,
}

export const headersFunction = (): any => ({
    'Content-Type': 'application/json',
    Authorization: `Bearer ${localStorage.getItem('token')}`,
})

export const getOpenTickets = async () => {
    const response = await fetch(`${API_URL}/tickets`, {
        body: JSON.stringify({
            page: 1,
            itemsInPage: 50,
            ticketStatus: 'open',
        }),
        method: 'POST',
        headers: headersFunction(),
    })
    const data = await response.json()
    errorTypeHandler(data)

    if (data?.noTickets) {
        return null
    }

    return data.openTickets
}

export const getRecentlyResolvedTickets = async () => {
    const response = await fetch(`${API_URL}/tickets`, {
        body: JSON.stringify({
            page: 1,
            itemsInPage: 10,
            ticketStatus: 'closed',
        }),
        method: 'POST',
        headers: headersFunction(),
    })

    const data = await response.json()
    errorTypeHandler(data)

    if (data?.noTickets) {
        return null
    }
    return data.closedTickets
}

export const getMonthlyUsageReport = async () => {
    const response = await fetch(`${API_URL}/tickets/reports`, {
        body: JSON.stringify({
            reportType: 'monthly',
        }),
        method: 'POST',
        headers: headersFunction(),
    })

    const data = await response.json()
    errorTypeHandler(data)

    if (data?.message === 'No Report Data') {
        return []
    }
    return data
}

export const getWeeklyUsageReport = async () => {
    const response = await fetch(`${API_URL}/tickets/reports`, {
        body: JSON.stringify({
            reportType: 'weekly',
        }),
        method: 'POST',
        headers: headersFunction(),
    })
    const data = await response.json()
    errorTypeHandler(data)

    if (data?.noReportData) {
        return []
    }
    return data
}

export const getMaintenanceData = async () => {
    const response = await fetch(`${API_URL}/clients/maintenance`, {
        method: 'GET',
        headers: headersFunction(),
    })
    const data = await response.json()
    errorTypeHandler(data)

    if (data?.noMaintenanceResults) {
        return null
    }
    return data
}

export const getOpenAlerts = async () => {
    const response = await fetch(`${API_URL}/alerts`, {
        method: 'GET',
        headers: headersFunction(),
    })
    const data = await response.json()
    errorTypeHandler(data)

    if (data?.noAlerts) {
        return null
    }
    return data.alerts
}

export const getYearlyPayments = async () => {
    const response = await fetch(`${API_URL}/finances/yearly`, {
        method: 'post',
        headers: headersFunction(),
    })
    const data = await response.json()
    errorTypeHandler(data)

    if (data?.noFinancialRecords) {
        return null
    }
    return data
}

export const getProfileInfo = async (): Promise<ProfileInfoType> => {
    const response = await fetch(`${API_URL}/users/me`, {
        method: 'GET',
        headers: headersFunction(),
    })
    const data = await response.json()
    errorTypeHandler(data)

    return data?.user
}

export const getReports = async (
    reportType: string | null,
    startDate: string | null,
    endDate: string | null,
    ticketStatus?: string | null
) => {
    console.log('🚀 ~ file: index.ts:181 ~ ticketStatus', ticketStatus)
    console.log('🚀 ~ file: index.ts:181 ~ endDate', endDate)
    console.log('🚀 ~ file: index.ts:181 ~ startDate', startDate)
    console.log('🚀 ~ file: index.ts:181 ~ reportType', reportType)
    const url = new URL(`${API_URL}/reports`)
    const params = {
        ...(reportType && { reportType }),
        ...(ticketStatus && { ticketStatus }),
        ...(startDate && { startDate }),
        ...(endDate && { endDate }),
    }
    //@ts-ignore
    url.search = new URLSearchParams(params).toString()
    const response = await fetch(url, {
        method: 'post',
        headers: headersFunction(),
    })

    const data = await response.json()
    errorTypeHandler(data)

    if (data?.noResults) {
        return null
    }

    return data
}

export const getTicket = async (
    ticketID: string | null
): Promise<(SrTicketReport & AteraTicketType) | null> => {
    const response = await fetch(`${API_URL}/tickets/${ticketID}`, {
        method: 'get',
        headers: headersFunction(),
    })
    const data = await response.json()
    errorTypeHandler(data)

    return data
}

export const getOpenBalances =
    async (): Promise<OpenBalancesObjType | null> => {
        const response = await fetch(`${API_URL}/finances/openBalances`, {
            method: 'get',
            headers: headersFunction(),
        })
        const data = await response.json()
        errorTypeHandler(data)

        if (data?.noOpenBalances) {
            return null
        }

        return data
    }

export const getPendingProposals = async (): Promise<ProposalType[] | null> => {
    const response = await fetch(`${API_URL}/finances/pendingProposals`, {
        method: 'get',
        headers: headersFunction(),
    })
    const data = await response.json()
    errorTypeHandler(data)

    if (data.notFound || data.noProposals) {
        return null
    }

    return data.proposals
}

export const getCompletedProposals = async (): Promise<
    ProposalType[] | null
> => {
    const response = await fetch(`${API_URL}/finances/completedProposals`, {
        method: 'get',
        headers: headersFunction(),
    })
    const data = await response.json()
    errorTypeHandler(data)

    if (data.notFound || data.noProposals) {
        return null
    }

    return data.proposals
}

export const getNotifications = async (): Promise<
    NotificationType[] | null
> => {
    const response = await fetch(`${API_URL}/users/notifications`, {
        method: 'get',
        headers: headersFunction(),
    })
    const data = await response.json()
    errorTypeHandler(data)

    if (data.noNotifications) {
        return null
    }

    return data.notifications
}
