import React from 'react'
import { useState } from 'react'

// material-ui
import {
    Box,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    SortDirection,
    useTheme,
    CustomTheme,
} from '@mui/material'

// project import
import Dot from 'components/@extended/Dot'

import { RecentlyResolvedTableType, TableHeadCellType } from 'types'

//icons
import LocalOfferIcon from '@mui/icons-material/LocalOffer'
import { WarningOutlined } from '@ant-design/icons'

const headCells: Array<TableHeadCellType> = [
    {
        id: 'TicketID',
        align: 'left',
        disablePadding: false,
        label: 'Ticket ID',
    },
    {
        id: 'name',
        align: 'left',
        disablePadding: true,
        label: 'Requestor',
    },
    {
        id: 'time',
        align: 'right',
        disablePadding: false,
        label: 'Time of Request',
    },
    {
        id: 'status',
        align: 'left',
        disablePadding: false,

        label: 'Status',
    },
    {
        id: 'tech',
        align: 'left',
        disablePadding: false,
        label: 'Tech Assigned',
    },
    {
        id: 'description',
        align: 'left',
        disablePadding: false,
        label: 'Ticket Description',
    },
]

// ==============================|| ORDER TABLE - HEADER ||============================== //

const TableHeader = ({
    order,
    orderBy,
}: {
    order: SortDirection
    orderBy: string
}): JSX.Element => {
    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell: TableHeadCellType) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.align}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        {headCell.label}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    )
}

// ==============================|| ORDER TABLE - STATUS ||============================== //

const TicketStatus = ({ status }: { status: number }) => {
    let color
    let title

    switch (status) {
        case 0:
            color = 'warning'
            title = 'Pending'
            break
        case 1:
            color = 'success'
            title = 'Resolved'
            break
        case 2:
            color = 'error'
            title = 'Rejected'
            break
        default:
            color = 'primary'
            title = 'None'
    }

    return (
        <Stack direction="row" spacing={1} alignItems="center">
            <Dot color={color} />
            <Typography>{title}</Typography>
        </Stack>
    )
}

// ==============================|| ORDER TABLE ||============================== //

const RecentlyResolvedTable: React.FC<RecentlyResolvedTableType> = ({
    recentlyResolvedTickets,
    isError,
}): JSX.Element => {
    const [order] = useState<SortDirection>('asc')
    const [orderBy] = useState('trackingNo')

    //theme
    const theme = useTheme<CustomTheme>()

    return (
        <Box>
            {isError ? (
                <TableContainer
                    sx={{
                        width: '100%',
                        overflowX: 'auto',
                        position: 'relative',
                        display: 'block',
                        maxWidth: '100%',
                        '& td, & th': { whiteSpace: 'nowrap' },
                    }}
                >
                    <Table
                        aria-labelledby="tableTitle"
                        sx={{
                            '& .MuiTableCell-root:first-child': {
                                pl: 2,
                            },
                            '& .MuiTableCell-root:last-child': {
                                pr: 3,
                            },
                        }}
                    >
                        <TableHeader order={order} orderBy={orderBy} />
                    </Table>
                    <Box
                        sx={{
                            justifyContent: 'center',
                            alignItems: 'center',
                            display: 'flex',
                            height: '80%',
                        }}
                    >
                        <WarningOutlined
                            style={{
                                fontSize: '20px',
                                color: theme.palette.error.main,
                            }}
                        />
                        <Typography
                            variant="h5"
                            color="text.secondary"
                            sx={{
                                ml: 1,
                                my: 15,
                            }}
                        >
                            Something went wrong, please try again later.
                        </Typography>
                    </Box>
                </TableContainer>
            ) : recentlyResolvedTickets ? (
                <TableContainer
                    sx={{
                        width: '100%',
                        overflowX: 'auto',
                        position: 'relative',
                        display: 'block',
                        maxWidth: '100%',
                        '& td, & th': { whiteSpace: 'nowrap' },
                    }}
                >
                    <Table
                        aria-labelledby="tableTitle"
                        sx={{
                            '& .MuiTableCell-root:first-child': {
                                pl: 2,
                            },
                            '& .MuiTableCell-root:last-child': {
                                pr: 3,
                            },
                        }}
                    >
                        <TableHeader order={order} orderBy={orderBy} />
                        <TableBody>
                            {recentlyResolvedTickets.map(
                                (row, index: number) => {
                                    const labelId = `enhanced-table-checkbox-${index}`

                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            sx={{
                                                '&:last-child td, &:last-child th':
                                                    {
                                                        border: 0,
                                                    },
                                            }}
                                            // aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={row.TicketID}
                                            // selected={isItemSelected}
                                        >
                                            <TableCell
                                                component="th"
                                                id={labelId}
                                                scope="row"
                                                align="left"
                                            >
                                                {row.TicketID}
                                            </TableCell>
                                            <TableCell align="left">
                                                {row.EndUserFirstName}
                                            </TableCell>
                                            <TableCell align="right">
                                                {new Date(
                                                    row.TicketCreatedDate
                                                )?.toLocaleString()}
                                            </TableCell>
                                            <TableCell align="left">
                                                <TicketStatus status={1} />
                                            </TableCell>
                                            <TableCell align="left">
                                                {row.TechnicianFullName}
                                            </TableCell>
                                            <TableCell align="left">
                                                {row.TicketTitle}
                                            </TableCell>
                                        </TableRow>
                                    )
                                }
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            ) : (
                <TableContainer
                    sx={{
                        width: '100%',
                        overflowX: 'auto',
                        position: 'relative',
                        display: 'block',
                        maxWidth: '100%',
                        '& td, & th': { whiteSpace: 'nowrap' },
                    }}
                >
                    <Table
                        aria-labelledby="tableTitle"
                        sx={{
                            '& .MuiTableCell-root:first-child': {
                                pl: 2,
                            },
                            '& .MuiTableCell-root:last-child': {
                                pr: 3,
                            },
                        }}
                    >
                        <TableHeader order={order} orderBy={orderBy} />
                    </Table>
                    <Box
                        sx={{
                            justifyContent: 'center',
                            alignItems: 'center',
                            display: 'flex',
                            height: 358,
                            width: '100%',
                        }}
                    >
                        <Typography
                            variant="h5"
                            color="text.secondary"
                            sx={{
                                mr: 1,
                            }}
                        >
                            No Recently Resolved Tickets
                        </Typography>
                        <LocalOfferIcon />
                    </Box>
                </TableContainer>
            )}
        </Box>
    )
}

export default RecentlyResolvedTable
