import { Box, Button } from '@mui/material'
import React, { useEffect } from 'react'
import { useDropzone } from 'react-dropzone'

const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16,
}

const thumb = {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: 'border-box',
}

const thumbInner = {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden',
}

const img = {
    display: 'block',
    width: 'auto',
    height: '100%',
}

const DragAndDrop: React.FC<{
    files:
        | {
              name: string
              preview: string
          }[]
        | Blob
    setFiles: React.Dispatch<
        React.SetStateAction<
            | {
                  name: string
                  preview: string
              }[]
            | Blob
        >
    >
}> = ({ files, setFiles }): JSX.Element => {
    const { getRootProps, getInputProps, open } = useDropzone({
        accept: {
            'image/*': ['.png', '.gif', '.jpeg', '.jpg'],
            'application/pdf': ['.pdf'],
            'application/msword': ['.doc', '.docx'],
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                ['.docx'],
            'application/vnd.ms-excel': ['.xls', '.xlsx'],
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                ['.xlsx'],
            'application/vnd.ms-powerpoint': ['.ppt', '.pptx'],
            'application/vnd.openxmlformats-officedocument.presentationml.presentation':
                ['.pptx'],
        },
        onDrop: (acceptedFiles) => {
            setFiles(
                acceptedFiles.map((file) => {
                    return Object.assign(file, {
                        preview: URL.createObjectURL(file),
                    })
                })
            )
        },
    })

    //@ts-ignore
    const thumbs = files.map((file) => (
        //@ts-ignore
        <div style={thumb} key={file.name}>
            <div style={thumbInner}>
                <img
                    src={file.preview}
                    style={img}
                    // Revoke data uri after image is loaded
                    onLoad={() => {
                        URL.revokeObjectURL(file.preview)
                    }}
                />
            </div>
        </div>
    ))

    useEffect(() => {
        // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
        //@ts-ignore
        return () => files.forEach((file) => URL.revokeObjectURL(file.preview))
    }, [])

    return (
        <Box
            sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                borderWidth: 2,
                borderRadius: 2,
                borderColor: '#eeeeee',
                borderStyle: 'dashed',
            }}
        >
            <div {...getRootProps({ className: 'dropzone' })}>
                <input {...getInputProps()} />
                <p>Drag and drop files here, or click to select files</p>
            </div>

            <Button
                type="button"
                onClick={open}
                variant="outlined"
                size="small"
            >
                Open
            </Button>
            <aside
                //@ts-ignore
                style={thumbsContainer}
            >
                {thumbs}
            </aside>
        </Box>
    )
}

export default DragAndDrop
