import { CustomTheme, TypographyPropsVariantOverrides } from '@mui/material'

const Typography = (theme: CustomTheme): TypographyPropsVariantOverrides => {
    return {
        MuiTypography: {
            styleOverrides: {
                gutterBottom: {
                    marginBottom: 12,
                },
            },
        },
    }
}

export default Typography
