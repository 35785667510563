import React from 'react'

// material-ui
import { Box, CustomTheme, Grid, useMediaQuery } from '@mui/material'

//components
import AuthCard from 'pages/authentication/AuthCard'
// import AuthFooter from 'components/cards/AuthFooter'

//types
import { AuthWrapperType } from 'types'
// ==============================|| AUTHENTICATION - WRAPPER ||============================== //

const AuthWrapper: React.FC<AuthWrapperType> = ({ children }): JSX.Element => {
    const matchesMd = useMediaQuery((theme: CustomTheme) =>
        theme.breakpoints.down('md')
    )

    return (
        <Box sx={{ minHeight: '100vh' }}>
            <Grid
                container
                direction="column"
                justifyContent="flex-end"
                sx={{
                    minHeight: '100vh',
                }}
            >
                <Grid item xs={12}>
                    <Grid
                        item
                        xs={12}
                        container
                        justifyContent="center"
                        alignItems={matchesMd ? 'flex-start' : 'center'}
                        sx={{
                            minHeight: {
                                xs: 'calc(100vh - 134px)',
                                md: 'calc(100vh - 112px)',
                            },
                        }}
                    >
                        <Grid item>
                            <AuthCard>{children}</AuthCard>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sx={{ m: 3, mt: 1 }}>
                    {/* <AuthFooter /> */}
                </Grid>
            </Grid>
        </Box>
    )
}
export default AuthWrapper
