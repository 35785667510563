import React from 'react'

// material-ui
import {
    Box,
    ButtonBase,
    Chip,
    Divider,
    Grid,
    IconButton,
    Stack,
    Typography,
} from '@mui/material'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'

// project import
import MainCard from 'components/MainCard'

//icons
import { TagOutlined } from '@ant-design/icons'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import SyncAltIcon from '@mui/icons-material/SyncAlt'
import CancelIcon from '@mui/icons-material/Cancel'

//types
import { OpenTicketType } from 'types'
import { toast } from 'react-toastify'

//config
import { API_URL } from 'config'
//api
import { headersFunction } from 'api'

//zustand
import useMiscellaneous from 'store/useMiscellaneous'

// ==============================|| STATISTICS - ECOMMERCE CARD  ||============================== //

const OpenTicket: React.FC<OpenTicketType> = ({
    color,
    businessName,
    requestor,
    ticketID,
    description,
    techName,
}): JSX.Element => {
    //zustand
    const setTicketDetailModal =
        useMiscellaneous.getState().setTicketDetailModal

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }

    const ticketRequest = async (requestType: 'update' | 'cancel') => {
        try {
            const data = await fetch(`${API_URL}/tickets/request`, {
                method: 'POST',
                headers: headersFunction(),
                body: JSON.stringify({
                    businessName,
                    requestor,
                    ticketID: ticketID?.toString(),
                    description,
                    ...(techName && { techName }),
                    requestType,
                }),
            })
            const results = await data.json()
            //@ts-ignore
            if (results?.error) {
                throw new Error(results?.error)
            }
            if (data.ok) {
                if (requestType === 'update') {
                    toast.success(
                        'Update Requested. A member of the team will get in touch with you shortly.'
                    )
                } else {
                    toast.warning(
                        'Cancelation Requested. A member of the team will get in touch with you shortly.'
                    )
                }
            }
            setAnchorEl(null)
        } catch (error) {
            //@ts-ignore
            console.log(error?.message)
            toast.error('Error requesting update, please try again later.')
        }
    }

    return (
        <MainCard
            contentSX={{
                p: 2.25,
                height: 180,
            }}
            hasHover
        >
            <Box
                onClick={() => {
                    console.log('clicked')
                    setTicketDetailModal(true, ticketID.toString())
                }}
                sx={{
                    height: '80%',
                }}
            >
                <Stack spacing={0.5}>
                    <Stack
                        spacing={0.5}
                        direction="row"
                        justifyContent="space-between"
                    >
                        <Grid item>
                            <Typography
                                variant="h6"
                                color="textSecondary"
                                sx={{
                                    display: 'block',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                }}
                            >
                                {requestor}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Chip
                                variant="filled"
                                color={color}
                                icon={
                                    <TagOutlined
                                        style={{
                                            fontSize: '0.8rem',
                                            color: 'inherit',
                                        }}
                                    />
                                }
                                label={`${ticketID}`}
                                sx={{ p: 0.5 }}
                                size="small"
                            />
                        </Grid>
                    </Stack>
                    <Grid container alignItems="center">
                        <Grid
                            item
                            sx={{
                                whiteSpace: 'nowrap',
                                overflow: ' hidden',
                                textOverflow: 'ellipsis',
                            }}
                        >
                            <Typography
                                variant="h4"
                                color="inherit"
                                sx={{
                                    display: 'block',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                }}
                            >
                                {businessName}
                            </Typography>
                        </Grid>
                    </Grid>
                </Stack>
                <Box
                    sx={{
                        whiteSpace: 'nowrap',
                        overflow: ' hidden',
                        textOverflow: 'ellipsis',
                        height: '45%',
                        borderWidth: '1px',
                        borderColor: 'black',
                    }}
                >
                    <Typography
                        color="textSecondary"
                        sx={{
                            display: 'block',
                            whiteSpace: 'break-spaces',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        }}
                    >
                        {description?.replace(
                            '⚠️ This client has a open balance, please speak with accounting before completing this job.⚠️',
                            ''
                        )}
                    </Typography>
                </Box>
            </Box>

            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
            >
                <Box>
                    <Typography component="span" variant="caption">
                        {'Technician: '}
                    </Typography>
                    <Typography
                        component="span"
                        variant="caption"
                        sx={{ color: `${color || 'primary'}.main` }}
                    >
                        {techName ?? 'Unassigned'}
                    </Typography>
                </Box>
                <IconButton
                    size="small"
                    onClick={handleClick}
                    sx={{
                        mr: -0.1,
                    }}
                >
                    <MoreVertIcon />
                </IconButton>
            </Stack>
            <Menu
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <MenuItem onClick={() => ticketRequest('update')}>
                    <SyncAltIcon
                        sx={{
                            mr: 2,
                        }}
                        fontSize="small"
                    />
                    Request Update
                </MenuItem>
                <Divider />
                <MenuItem onClick={() => ticketRequest('cancel')}>
                    <CancelIcon
                        sx={{
                            mr: 2,
                        }}
                        fontSize="small"
                    />
                    Cancel Ticket
                </MenuItem>
            </Menu>
        </MainCard>
    )
}

OpenTicket.defaultProps = {
    color: 'primary',
}

export default OpenTicket
