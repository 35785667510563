import React, { useEffect, forwardRef } from 'react'
import { Link } from 'react-router-dom'

// material-ui
import { useTheme } from '@mui/material/styles'
import {
    Box,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Typography,
} from '@mui/material'

//icons
import { LockOutlined } from '@mui/icons-material'

// zustand
import useDrawerStore from 'store/drawer-store'

//types
import { NavItemsType } from 'types'
import useBusinessLogic from 'store/useBusinessLogic'

// ==============================|| NAVIGATION - LIST ITEM ||============================== //

const NavItem: React.FC<NavItemsType> = ({ item, level }): JSX.Element => {
    const theme = useTheme()

    //zustand
    const setActiveItem = useDrawerStore.getState().setActiveItem

    //drawer open
    const drawerOpen = useDrawerStore((state) => state.drawerOpen)
    const openItem = useDrawerStore((state) => state.openItem)

    let itemTarget = '_self'
    if (item.target) {
        itemTarget = '_blank'
    }

    //@ts-ignore
    let listItemProps = {
        component: forwardRef((props, ref) => (
            //@ts-ignore
            <Link
                //@ts-ignore
                ref={ref}
                {...props}
                to={
                    !isAdmin && item.title !== 'Dashboard'
                        ? '/dashboard'
                        : item.url
                }
                target={itemTarget}
            />
        )),
    }
    if (item?.external) {
        //@ts-ignore

        listItemProps = { component: 'a', href: item.url, target: itemTarget }
    }

    const itemHandler = (id: string) => {
        if (!isAdmin && item.title !== 'Dashboard') {
            return
        }
        setActiveItem([id])
    }

    const Icon = item.icon
    const itemIcon = item.icon ? (
        <Icon style={{ fontSize: drawerOpen ? '1.25rem' : '1.25rem' }} />
    ) : (
        false
    )

    const isSelected = openItem.findIndex((id) => id === item.id) > -1

    // active menu item on page load

    const currentIndex = document.location.pathname
        .toString()
        .split('/')
        .findIndex((id) => id === item.id)
    useEffect(() => {
        if (currentIndex > -1) {
            setActiveItem([item.id])
        }
    }, [currentIndex])

    const textColor = 'text.primary'
    const iconSelectedColor = 'primary.main'

    //is admin
    const isAdmin = useBusinessLogic(
        (state) => state.profileInfo?.isAdmin === 'yes'
    )

    return (
        <ListItemButton
            {...listItemProps}
            disabled={item.disabled}
            onClick={() => itemHandler(item.id)}
            selected={isSelected}
            sx={{
                zIndex: 1201,
                pl: drawerOpen ? 1.5 : 1.5,
                py: !drawerOpen && level === 1 ? 1 : 1,
                ...(drawerOpen && {
                    '&:hover': {
                        bgcolor: 'primary.lighter',
                    },
                    '&.Mui-selected': {
                        bgcolor: 'primary.lighter',
                        borderRight: `2px solid ${theme.palette.primary.main}`,
                        color: iconSelectedColor,
                        '&:hover': {
                            color: iconSelectedColor,
                            bgcolor: 'primary.lighter',
                        },
                    },
                }),
                ...(!drawerOpen && {
                    '&:hover': {
                        bgcolor: 'transparent',
                    },
                    '&.Mui-selected': {
                        '&:hover': {
                            bgcolor: 'transparent',
                        },
                        bgcolor: 'transparent',
                    },
                }),
                opacity: item.id === 'dashboard' ? 1 : !isAdmin ? 0.3 : 1,
            }}
        >
            {itemIcon && (
                <ListItemIcon
                    sx={{
                        minWidth: 28,
                        color: isSelected ? iconSelectedColor : textColor,
                        width: 36,
                        height: 36,
                        alignItems: 'center',
                        justifyContent: 'center',
                        ...(!drawerOpen && {
                            borderRadius: 1.5,
                            '&:hover': {
                                bgcolor: 'secondary.lighter',
                            },
                        }),
                        ...(!drawerOpen &&
                            isSelected && {
                                bgcolor: 'primary.lighter',
                                '&:hover': {
                                    bgcolor: 'primary.lighter',
                                },
                            }),
                    }}
                >
                    {itemIcon}
                </ListItemIcon>
            )}
            <Link
                // @ts-ignore
                to={
                    !isAdmin && item.title !== 'Dashboard'
                        ? '/dashboard'
                        : item.url
                }
                target={itemTarget}
                style={{
                    textDecoration: 'none',
                    marginLeft: 5,
                    width: '100%',
                }}
            >
                {drawerOpen || (!drawerOpen && level !== 1) ? (
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            width: '100%',
                        }}
                    >
                        <ListItemText
                            primary={
                                <Typography
                                    variant="h6"
                                    sx={{
                                        color: isSelected
                                            ? iconSelectedColor
                                            : textColor,
                                    }}
                                >
                                    {item.title}
                                </Typography>
                            }
                        />
                        {!isAdmin && item.title !== 'Dashboard' ? (
                            <LockOutlined
                                sx={{
                                    fontSize: '20px',
                                    color:
                                        theme.palette.mode === 'dark'
                                            ? 'white'
                                            : 'black',
                                }}
                            />
                        ) : null}
                    </Box>
                ) : null}
            </Link>
        </ListItemButton>
    )
}
export default NavItem
