import React, { useEffect } from 'react'
import { useRef, useState } from 'react'

// material-ui
import { CustomTheme, useTheme } from '@mui/material/styles'
import {
    Box,
    ButtonBase,
    CardContent,
    ClickAwayListener,
    Grid,
    IconButton,
    Paper,
    Popper,
    Stack,
    Tab,
    Tabs,
    Typography,
    useMediaQuery,
} from '@mui/material'

// project import
import MainCard from 'components/MainCard'
import Transitions from 'components/@extended/Transitions'
import ProfileTab from './ProfileTab'
import SettingTab from './SettingTab'

//icons
import {
    LogoutOutlined,
    SettingOutlined,
    UserOutlined,
} from '@ant-design/icons'

//types
import { TabPanelType } from 'types'

//react router
import { useNavigate } from 'react-router-dom'

//zustand
import useBusinessLogic from 'store/useBusinessLogic'

//API calls
import { getProfileInfo } from 'api'

//react query
import { useQuery } from '@tanstack/react-query'
import { queryKeys, queryCache } from 'react-query'

// tab panel wrapper
const TabPanel: React.FC<TabPanelType> = ({
    children,
    value,
    index,
    ...other
}): JSX.Element => {
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`profile-tabpanel-${index}`}
            aria-labelledby={`profile-tab-${index}`}
            {...other}
        >
            {value === index && children}
        </div>
    )
}

const a11yProps = (index: number) => {
    return {
        id: `profile-tab-${index}`,
        'aria-controls': `profile-tabpanel-${index}`,
    }
}

// ==============================|| HEADER CONTENT - PROFILE ||============================== //

const Profile = () => {
    //zustand
    const removeToken = useBusinessLogic((state) => state.removeToken)

    //theme
    const theme = useTheme<CustomTheme>()

    //navigation
    const navigate = useNavigate()

    //media query
    const matchesXs = useMediaQuery((theme: CustomTheme) =>
        theme.breakpoints.down('md')
    )
    //----------------------|| GET USER INFO ||-----------------------//

    const setProfileInfo = useBusinessLogic.getState().setProfileInfo
    const {
        data = {
            userID: 0,
            clientID: 0,
            Name: '',
            lastName: '',
            userName: '',
            adminStatus: '',
            techID: '',
            emailAddress: '',
            additionalCompanies: '',
            manager: '',
            adminViewOnly: '',
            ateraID: '',
            AteraUserID: '',
            companyPhone: '',
        },
        isError,
    } = useQuery([queryKeys.userInfo], getProfileInfo)

    useEffect(() => {
        //@ts-ignore
        setProfileInfo(data)
        console.log('ran', data)
    }, [data])
    //----------------------|| GET USER INFO ||-----------------------//

    const handleLogout = async () => {
        removeToken()
        queryCache.clear()
        navigate('./login')
    }

    const anchorRef = useRef(null)
    const [open, setOpen] = useState<boolean>(false)
    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen)
    }

    const handleClose = (
        event: globalThis.MouseEvent | globalThis.TouchEvent
    ) => {
        //@ts-ignore
        if (anchorRef?.current && anchorRef?.current.contains(event.target)) {
            return
        }
        setOpen(false)
    }

    const [value, setValue] = useState<number>(0)

    const handleChange = (
        event: React.SyntheticEvent<Element, Event>,
        newValue: number
    ) => {
        setValue(newValue)
    }

    const iconBackColorOpen =
        theme.palette.mode === 'light' ? 'grey.300' : 'grey.800'

    return (
        <Box sx={{ flexShrink: 0, ml: 0.75 }}>
            <ButtonBase
                sx={{
                    p: 0.6,
                    bgcolor: open ? iconBackColorOpen : 'transparent',
                    borderRadius: 1,
                    '&:hover': { bgcolor: 'secondary.lighter' },
                    borderWidth: 1,
                    borderStyle: 'solid',
                    borderColor: 'grey.500',
                }}
                aria-label="open profile"
                ref={anchorRef}
                aria-controls={open ? 'profile-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
            >
                <Stack
                    direction="row"
                    spacing={2}
                    alignItems="center"
                    justifyContent="center"
                    sx={{ py: 0.65, px: 1 }}
                >
                    <UserOutlined
                        style={{
                            fontSize: '16px',
                        }}
                    />
                    {!isError && data?.Name && data.lastName && (
                        <Typography variant="subtitle1">
                            {`${data?.Name}` + ` ${data?.lastName}`}
                        </Typography>
                    )}
                </Stack>
            </ButtonBase>
            <Popper
                placement="bottom-end"
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, 9],
                            },
                        },
                    ],
                }}
            >
                {({ TransitionProps }) => (
                    <Transitions type="fade" in={open} {...TransitionProps}>
                        {open && (
                            <Paper
                                sx={{
                                    boxShadow:
                                        theme.palette.mode === 'light'
                                            ? theme.customShadows.light
                                            : theme.customShadows.dark,
                                    width: matchesXs ? window.innerWidth : 290,
                                    minWidth: 240,
                                    // maxWidth: 290,
                                    //@ts-ignore
                                    // [theme.breakpoints.down('md')]: {
                                    //     maxWidth: 250,
                                    // },
                                }}
                            >
                                <ClickAwayListener onClickAway={handleClose}>
                                    <MainCard
                                        elevation={0}
                                        border={false}
                                        content={false}
                                    >
                                        <CardContent sx={{ px: 2.5, pt: 3 }}>
                                            <Grid
                                                container
                                                justifyContent="space-between"
                                                alignItems="center"
                                            >
                                                <Grid item>
                                                    <Stack
                                                        direction="row"
                                                        spacing={1.25}
                                                        alignItems="center"
                                                    >
                                                        <Stack>
                                                            <Typography variant="h6">
                                                                {`${data?.Name}` +
                                                                    ` ${data?.lastName}`}
                                                            </Typography>
                                                        </Stack>
                                                    </Stack>
                                                </Grid>
                                                <Grid item>
                                                    <IconButton
                                                        size="large"
                                                        color="secondary"
                                                        onClick={handleLogout}
                                                    >
                                                        <LogoutOutlined />
                                                    </IconButton>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                        {open && (
                                            <>
                                                <Box
                                                    sx={{
                                                        borderBottom: 1,
                                                        borderColor: 'divider',
                                                    }}
                                                >
                                                    <Tabs
                                                        variant="fullWidth"
                                                        value={value}
                                                        onChange={handleChange}
                                                        aria-label="profile tabs"
                                                    >
                                                        <Tab
                                                            sx={{
                                                                display: 'flex',
                                                                flexDirection:
                                                                    'row',
                                                                justifyContent:
                                                                    'center',
                                                                alignItems:
                                                                    'center',
                                                                textTransform:
                                                                    'capitalize',
                                                            }}
                                                            icon={
                                                                <UserOutlined
                                                                    style={{
                                                                        marginBottom: 0,
                                                                        marginRight:
                                                                            '10px',
                                                                    }}
                                                                />
                                                            }
                                                            label="Profile"
                                                            {...a11yProps(0)}
                                                        />
                                                        <Tab
                                                            sx={{
                                                                display: 'flex',
                                                                flexDirection:
                                                                    'row',
                                                                justifyContent:
                                                                    'center',
                                                                alignItems:
                                                                    'center',
                                                                textTransform:
                                                                    'capitalize',
                                                            }}
                                                            icon={
                                                                <SettingOutlined
                                                                    style={{
                                                                        marginBottom: 0,
                                                                        marginRight:
                                                                            '10px',
                                                                    }}
                                                                />
                                                            }
                                                            label="Setting"
                                                            {...a11yProps(1)}
                                                        />
                                                    </Tabs>
                                                </Box>
                                                <TabPanel
                                                    value={value}
                                                    index={0}
                                                    dir={theme.direction}
                                                >
                                                    <ProfileTab
                                                        handleLogout={
                                                            handleLogout
                                                        }
                                                    />
                                                </TabPanel>
                                                <TabPanel
                                                    value={value}
                                                    index={1}
                                                    dir={theme.direction}
                                                >
                                                    <SettingTab />
                                                </TabPanel>
                                            </>
                                        )}
                                    </MainCard>
                                </ClickAwayListener>
                            </Paper>
                        )}
                    </Transitions>
                )}
            </Popper>
        </Box>
    )
}

export default Profile
