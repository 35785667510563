// material-ui
import { createTheme } from '@mui/material/styles'

// third-party
import { presetPalettes } from '@ant-design/colors'

// project import
import CreateCustomTheme from 'themes/theme/index'

// ==============================|| DEFAULT THEME - PALETTE  ||============================== //

const Palette = (mode: 'light' | 'dark') => {
    const colors = presetPalettes

    const greyPrimary = [
        '#ffffff',
        '#fafafa',
        '#f5f5f5',
        '#f0f0f0',
        '#d9d9d9',
        '#bfbfbf',
        '#8c8c8c',
        '#595959',
        '#262626',
        '#141414',
        '#000000',
    ]
    const greyAscent = ['#fafafa', '#bfbfbf', '#434343', '#1f1f1f']
    const greyConstant = ['#fafafb', '#e6ebf1']

    colors.grey = [...greyPrimary, ...greyAscent, ...greyConstant]

    const paletteColor = CreateCustomTheme(colors, mode)

    return createTheme({
        palette: {
            primary: {
                main: '#FF4500',
            },
            mode,
            common: {
                black: '#000',
                white: '#fff',
            },
            action: {
                disabled: paletteColor.grey[300],
            },
        },
    })
}

export default Palette
