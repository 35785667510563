import React from 'react'

// material-ui
import { Box, Button, CustomTheme, Typography, useTheme } from '@mui/material'

import { DataGrid, GridToolbar, GridColDef } from '@mui/x-data-grid'

// project import

import { TicketsReportTableType } from 'types'

//skeleton
import RecentlyResolvedSkeleton from 'components/skeletons/RecentlyResolvedSkeleton'

//zustand
import useMiscellaneous from 'store/useMiscellaneous'
import { WarningOutlined } from '@ant-design/icons'

const TicketReportTable: React.FC<TicketsReportTableType> = ({
    ticketsReportData = [],
    isFetching,
    isError,
}): JSX.Element | null => {
    const theme = useTheme<CustomTheme>()
    //zustand
    const setTicketDetailModal =
        useMiscellaneous.getState().setTicketDetailModal

    const headCells: GridColDef[] = [
        {
            field: 'ticketID',
            headerName: 'TicketID',
            renderCell: (params) => (
                <Button
                    onClick={() => {
                        setTicketDetailModal(true, params.row.ticketID)
                    }}
                >
                    {params.value}
                </Button>
            ),
            minWidth: 100,
        },
        {
            field: 'completedDate',
            headerName: 'Completion Date',
            minWidth: 150,
        },
        {
            field: 'personName',
            headerName: 'Requestor',
            flex: 1,
            minWidth: 150,
        },
        {
            field: 'issue',
            headerName: 'Issue',
            flex: 1,
            minWidth: 150,
        },
        {
            field: 'resolution',
            headerName: 'Resolution',
            flex: 1,
            minWidth: 150,
        },
        {
            field: 'status',
            headerName: 'Status',
        },
        {
            field: 'techName',
            headerName: 'Tech Name',
            flex: 1,
            minWidth: 150,
        },
    ]

    if (isError) {
        return (
            <Box
                sx={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex',
                    height: 414,
                    width: '100%',
                }}
            >
                <WarningOutlined
                    style={{
                        fontSize: '20px',
                        color: theme.palette.error.main,
                    }}
                />
                <Typography
                    variant="h5"
                    color="text.secondary"
                    sx={{
                        ml: 1,
                    }}
                >
                    Something went wrong, please try again later.
                </Typography>
            </Box>
        )
    }

    if (isFetching) {
        return <RecentlyResolvedSkeleton height={window.innerWidth * 0.4} />
    }

    return (
        <Box>
            <DataGrid
                rows={ticketsReportData}
                columns={headCells}
                components={{
                    Toolbar: GridToolbar,
                }}
                autoHeight
                scrollbarSize={10}
                onRowClick={(e) => {
                    setTicketDetailModal(true, e.row.ticketID)
                }}
                sx={{
                    p: 2,
                    borderRadius: 2,
                    borderWidth: 0.5,
                }}
            />
        </Box>
    )
}

export default TicketReportTable

/*

  {ticketsReportData ? (
                <TableContainer
                    sx={{
                        width: '100%',
                        overflowX: 'auto',
                        position: 'relative',
                        display: 'block',
                        maxWidth: '100%',
                        '& td, & th': { whiteSpace: 'nowrap' },
                    }}
                >
                    <Table
                        aria-labelledby="tableTitle"
                        sx={{
                            '& .MuiTableCell-root:first-child': {
                                pl: 2,
                            },
                            '& .MuiTableCell-root:last-child': {
                                pr: 3,
                            },
                        }}
                    >
                        <TableHeader order={order} orderBy={orderBy} />
                        <TableBody>
                            {ticketsReportData.map(
                                (row: SrTicketReport, index: number) => {
                                    const isItemSelected = isSelected(
                                        row.worklogID
                                    )
                                    const labelId = `enhanced-table-checkbox-${index}`

                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            sx={{
                                                '&:last-child td, &:last-child th':
                                                    {
                                                        border: 0,
                                                    },
                                            }}
                                            // aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={row.worklogID}
                                            // selected={isItemSelected}
                                        >
                                            <TableCell
                                                component="th"
                                                id={labelId}
                                                scope="row"
                                                align="left"
                                            >
                                                {row.worklogID}
                                            </TableCell>
                                            <TableCell align="left">
                                                {row.completedDate}
                                            </TableCell>
                                            <TableCell align="left">
                                                {row.personName}
                                            </TableCell>
                                            <TableCell align="left">
                                                <TicketStatus
                                                    status={row.status}
                                                />
                                            </TableCell>
                                            <TableCell align="left">
                                                {row.resolution}
                                            </TableCell>
                                        </TableRow>
                                    )
                                }
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            ) : (
                <TableContainer
                    sx={{
                        width: '100%',
                        overflowX: 'auto',
                        position: 'relative',
                        display: 'block',
                        maxWidth: '100%',
                        '& td, & th': { whiteSpace: 'nowrap' },
                        minHeight: window.innerWidth * 0.4,
                    }}
                >
                    <Table
                        aria-labelledby="tableTitle"
                        sx={{
                            '& .MuiTableCell-root:first-child': {
                                pl: 2,
                            },
                            '& .MuiTableCell-root:last-child': {
                                pr: 3,
                            },
                        }}
                    >
                        <TableHeader order={order} orderBy={orderBy} />
                    </Table>
                    <Box
                        sx={{
                            justifyContent: 'center',
                            alignItems: 'center',
                            display: 'flex',
                            width: '100%',
                        }}
                    >
                        <Typography
                            variant="h5"
                            color="text.secondary"
                            sx={{
                                mr: 1,
                                mt: 5,
                            }}
                        >
                            No tickets found for this date range and search
                            criteria
                        </Typography>
                        </Box>
                        </TableContainer>
                    )}
*/
