import { useState } from 'react'

// material-ui
import {
    Box,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    SortDirection,
} from '@mui/material'
import Skeleton from '@mui/material/Skeleton'

import { TableHeadCellType } from 'types'

const headCells: Array<TableHeadCellType> = [
    {
        id: 'severity',
        align: 'left',
        disablePadding: true,
        label: 'Severity',
    },
    {
        id: 'deviceName',
        align: 'left',
        disablePadding: false,
        label: 'Device Name',
    },
    {
        id: 'alertCategoryID',
        align: 'left',
        disablePadding: false,
        label: 'Alert Category',
    },
    {
        id: 'alertMessage',
        align: 'left',
        disablePadding: false,
        label: 'Alert Message',
    },
]

const fillerData = [1, 2, 3, 4, 5, 6]

const TableHeader = ({
    order,
    orderBy,
}: {
    order: SortDirection
    orderBy: string
}): JSX.Element => {
    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell: TableHeadCellType) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.align}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <Skeleton
                            variant="text"
                            animation="wave"
                            sx={{
                                borderRadius: 1,
                            }}
                        />
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    )
}

// ==============================|| SEVERITY STATUS ||============================== //

const Severity = () => {
    let color
    let title

    switch (status) {
        case 'Critical':
            color = 'error'
            title = 'Critical'
            break
        case 'Warning':
            color = 'warning'
            title = 'Warning'
            break
    }

    return (
        <Stack direction="row" spacing={1} alignItems="center">
            <Skeleton
                variant="circular"
                height={10}
                width={10}
                animation="wave"
                sx={{
                    borderRadius: 1,
                }}
            />
            <Skeleton
                variant="text"
                animation="wave"
                width={'100%'}
                sx={{
                    borderRadius: 1,
                }}
            />
        </Stack>
    )
}

// ==============================|| SEVERITY TABLE ||============================== //

const DeviceName = () => {
    return (
        <Stack direction="row" spacing={1} alignItems="center">
            <Skeleton
                variant="text"
                width={20}
                animation="wave"
                sx={{
                    borderRadius: 1,
                }}
            />
            <Skeleton
                variant="text"
                animation="wave"
                width={'100%'}
                sx={{
                    borderRadius: 1,
                }}
            />
        </Stack>
    )
}

const AlertsSkeleton = (): JSX.Element => {
    // console.log('🚀 ~ file: AlertsTable.tsx ~ line 114 ~ alertData', alertData)
    const [order] = useState<SortDirection>('asc')
    const [orderBy] = useState('trackingNo')
    const [selected] = useState<Array<number>>()

    const isSelected = (trackingNo: number) =>
        selected?.indexOf(trackingNo) !== -1

    return (
        <Box>
            <TableContainer
                sx={{
                    width: '100%',
                    overflowX: 'auto',
                    position: 'relative',
                    display: 'block',
                    maxWidth: '100%',
                    '& td, & th': { whiteSpace: 'nowrap' },
                    height: 358,
                }}
            >
                <Table
                    aria-labelledby="tableTitle"
                    sx={{
                        '& .MuiTableCell-root:first-child': {
                            pl: 2,
                        },
                        '& .MuiTableCell-root:last-child': {
                            pr: 3,
                        },
                    }}
                >
                    <TableHeader order={order} orderBy={orderBy} />
                    <TableBody>
                        {/*@ts-ignore*/}
                        {fillerData.map((row: number, index: number) => {
                            const labelId = `enhanced-table-checkbox-${index}`

                            return (
                                <TableRow
                                    key={index.toString()}
                                    hover
                                    role="checkbox"
                                    sx={{
                                        '&:last-child td, &:last-child th': {
                                            border: 0,
                                        },
                                    }}
                                    tabIndex={-1}
                                    // key={row.TicketID}
                                >
                                    <TableCell
                                        component="th"
                                        id={labelId}
                                        scope="row"
                                        align="left"
                                    >
                                        <Severity />
                                    </TableCell>
                                    <TableCell align="left">
                                        <DeviceName />
                                    </TableCell>
                                    <TableCell align="left">
                                        <Skeleton
                                            variant="text"
                                            animation="wave"
                                            sx={{
                                                borderRadius: 1,
                                            }}
                                        />
                                    </TableCell>
                                    <TableCell align="left">
                                        <Skeleton
                                            variant="text"
                                            animation="wave"
                                            sx={{
                                                borderRadius: 1,
                                            }}
                                        />
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    )
}

export default AlertsSkeleton
