// third-party
import { merge } from 'lodash'

// project import
import Badge from './Badge'
import Button from './Button'
import CardContent from './CardContent'
import Checkbox from './Checkbox'
import Chip from './Chip'
import IconButton from './IconButton'
import InputLabel from './InputLabel'
import LinearProgress from './LinearProgress'
import Link from './Link'
import ListItemIcon from './ListItemIcon'
import OutlinedInput from './OutlinedInput'
import Tab from './Tab'
import TableCell from './TableCell'
import Tabs from './Tabs'
import Typography from './Typography'

//types
import { CustomTheme } from '@mui/material'

export default function ComponentsOverrides(theme: CustomTheme) {
    return merge(
        Button(theme),
        Badge(theme),
        CardContent(theme),
        Checkbox(theme),
        Chip(theme),
        IconButton(theme),
        InputLabel(theme),
        LinearProgress(theme),
        Link(theme),
        ListItemIcon(theme),
        OutlinedInput(theme),
        Tab(theme),
        TableCell(theme),
        Tabs(theme),
        Typography(theme)
    )
}
