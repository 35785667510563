//react query
import { QueryClient, QueryCache } from '@tanstack/react-query'

//toastify
import { toast } from 'react-toastify'

//sentry
import * as Sentry from '@sentry/react'

//query keys
export const queryKeys = {
    userInfo: 'userInfo',
    openTickets: 'openTickets',
    recentlyResolvedTickets: 'recentlyResolvedTickets',
    monthlyUsageReport: 'monthlyUsageReport',
    weeklyUsageReport: 'weeklyUsageReport',
    maintenanceData: 'maintenanceData',
    alertData: 'alertData',
    yearlyPayments: 'yearlyPayments',
    ticketReport: 'ticketReport',
    chargedTicketsReport: 'chargedTicketsReport',
    invoiceReport: 'invoiceReport',
    ticket: 'ticket',
    openBalances: 'openBalances',
    pendingProposals: 'pendingProposals',
    completedProposals: 'completedProposals',
    notifications: 'notifications',
}

function queryErrorHandler(error: unknown): void {
    // error is type unknown because in js, anything can be an error (e.g. throw(5))
    const title =
        error instanceof Error ? error.message : 'error connecting to server'

    Sentry.captureException(error)
    toast.dismiss()
    toast.error(title, {
        toastId: 'queryErrorHandler',
        autoClose: 5000,
    })
}

export const queryCache = new QueryCache()

// to satisfy typescript until this file has uncommented contents
export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            onError: queryErrorHandler,
            //other  default options
        },
    },
    queryCache,
})
