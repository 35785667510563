import { CustomTheme, CardPropsColorOverrides } from '@mui/material'

const CardContent = (theme: CustomTheme): CardPropsColorOverrides => {
    return {
        MuiCardContent: {
            styleOverrides: {
                root: {
                    padding: 20,
                    '&:last-child': {
                        paddingBottom: 20,
                    },
                },
            },
        },
    }
}

export default CardContent
