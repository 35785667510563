import React, { useState } from 'react'

// material-ui
import {
    Box,
    Button,
    CustomTheme,
    Grid,
    Stack,
    Typography,
    useTheme,
} from '@mui/material'
import Skeleton from '@mui/material/Skeleton'

//components
import RecentlyResolvedTable from './RecentlyResolvedTable'
import UsageChart from './UsageChart'
import MonthlyMaint from './MonthlyMaint'
import YearlyPaymentsChart from './YearlyPaymentsChart'
import AlertsTable from './AlertsTable'
import MainCard from 'components/MainCard'
import OpenTicketsComp from 'pages/dashboard/OpenTicketsComp'

//skeletons
import UsageChartSkeleton from 'components/skeletons/UsageChartSkeleton'
import MonthlyMaintSkeleton from 'components/skeletons/MonthlyMaintSkeleton'
import AlertsSkeleton from 'components/skeletons/AlertsSkeleton'
import RecentlyResolvedSkeleton from 'components/skeletons/RecentlyResolvedSkeleton'
import PaymentsSkeleton from 'components/skeletons/PaymentsSkeleton'

//icons
import InfoOutlined from '@mui/icons-material/InfoOutlined'

//react query
import { useQuery } from '@tanstack/react-query'
import { queryClient, queryKeys } from 'react-query'

//API calls
import {
    getRecentlyResolvedTickets,
    getMonthlyUsageReport,
    getWeeklyUsageReport,
    getMaintenanceData,
    getOpenAlerts,
    getYearlyPayments,
} from 'api'
import useBusinessLogic from 'store/useBusinessLogic'

//placeHolderData
const maintenancePlaceHolder = {
    mmid: 0,
    clientID: 0,
    allowedMonthlyRequests: 0,
    effectiveDate: '',
    serviceType: 'IT',
    openTickets: 0,
    closedTickets: 0,
    percentageUsed: '0%',
}

// ==============================|| DASHBOARD - DEFAULT ||============================== //
console.log('rerendered dashboard')
const DashboardDefault: React.FC = (): JSX.Element => {
    const [slot, setSlot] = useState<'week' | 'month'>('month')

    const theme = useTheme<CustomTheme>()

    //profileInfo
    const profileInfo = useBusinessLogic((state) => state.profileInfo)
    const isAdmin = profileInfo?.isAdmin === 'yes'

    const {
        data: recentlyResolvedTickets = [],
        isLoading: resolvedTicketsLoading,
        isError: recentlyResolvedError,
    } = useQuery(
        [queryKeys.recentlyResolvedTickets],
        getRecentlyResolvedTickets
    )

    const {
        data: monthlyUsageData = [],
        isLoading: monthlyUsageLoading,
        isError: monthlyUsageError,
    } = useQuery([queryKeys.monthlyUsageReport], getMonthlyUsageReport)

    const {
        data: weeklyUsageData = [],
        isLoading: weeklyUsageLoading,
        isError: weeklyUsageError,
    } = useQuery([queryKeys.weeklyUsageReport], getWeeklyUsageReport)

    const {
        data: maintenanceData = maintenancePlaceHolder,
        isLoading: maintenanceDataLoading,
        isError: maintenanceDataError,
    } = useQuery([queryKeys.maintenanceData], getMaintenanceData)

    const {
        data: alertData = [],
        isLoading: alertsLoading,
        isError: alertError,
    } = useQuery([queryKeys.alertData], getOpenAlerts)

    const {
        data: yearlyPayments = [],
        isLoading: yearlyPaymentsLoading,
        isError: yearlyPaymentsError,
    } = useQuery([queryKeys.yearlyPayments], getYearlyPayments)

    React.useEffect(() => {
        queryClient.invalidateQueries([
            queryKeys.monthlyUsageReport,
            queryKeys.weeklyUsageReport,
        ])
    }, [slot])

    return (
        <Grid container rowSpacing={4.5} columnSpacing={2.75}>
            {/* row 1 */}
            <Grid
                item
                xs={12}
                sx={{
                    mb: -2.5,
                }}
            >
                <Typography variant="h5">Open Tickets</Typography>
            </Grid>
            <OpenTicketsComp />
            <Grid
                item
                md={8}
                sx={{ display: { sm: 'none', md: 'block', lg: 'none' } }}
            />
            {/* row 2 */}
            <Grid
                item
                xs={12}
                md={!maintenanceData ? 12 : 7}
                lg={!maintenanceData ? 12 : 7}
            >
                <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Grid item>
                        <Typography variant="h5">Usage Report</Typography>
                    </Grid>
                    <Grid item>
                        <Stack direction="row" alignItems="center" spacing={0}>
                            {monthlyUsageLoading ? (
                                <>
                                    <Skeleton
                                        variant="rectangular"
                                        width={50}
                                        height={36}
                                        animation="wave"
                                        sx={{
                                            borderRadius: 1,
                                            mr: 1,
                                        }}
                                    />
                                    <Skeleton
                                        variant="rectangular"
                                        width={50}
                                        height={36}
                                        animation="wave"
                                        sx={{
                                            borderRadius: 1,
                                        }}
                                    />
                                </>
                            ) : (
                                <>
                                    <Button
                                        size="small"
                                        onClick={() => {
                                            setSlot('month')
                                        }}
                                        color={
                                            slot === 'month'
                                                ? 'primary'
                                                : 'secondary'
                                        }
                                        variant={
                                            slot === 'month'
                                                ? 'outlined'
                                                : 'text'
                                        }
                                    >
                                        Month
                                    </Button>
                                    <Button
                                        size="small"
                                        onClick={() => setSlot('week')}
                                        color={
                                            slot === 'week'
                                                ? 'primary'
                                                : 'secondary'
                                        }
                                        variant={
                                            slot === 'week'
                                                ? 'outlined'
                                                : 'text'
                                        }
                                    >
                                        Week
                                    </Button>
                                </>
                            )}
                        </Stack>
                    </Grid>
                </Grid>
                <MainCard content={false} sx={{ mt: 1.5 }} isAdmin={isAdmin}>
                    {!monthlyUsageLoading ? (
                        <Box sx={{ pt: 1, pr: 2 }}>
                            <UsageChart
                                slot={slot}
                                data={[
                                    {
                                        name: 'Tickets Resolved',
                                        data:
                                            slot === 'month'
                                                ? monthlyUsageData.rawData
                                                : weeklyUsageData.rawData,
                                    },
                                ]}
                                fullTicketData={monthlyUsageData.fullTicketData}
                                isError={
                                    slot === 'month'
                                        ? monthlyUsageError
                                        : weeklyUsageError
                                }
                            />
                        </Box>
                    ) : (
                        <UsageChartSkeleton />
                    )}
                </MainCard>
            </Grid>
            {maintenanceData && (
                <Grid item xs={12} md={5} lg={5}>
                    <Grid
                        container
                        alignItems="center"
                        justifyContent="space-between"
                    >
                        <Grid item>
                            <Typography variant="h5">Monthly Quota</Typography>
                        </Grid>
                        <Grid item />
                    </Grid>
                    <MainCard sx={{ mt: 2 }} content={false}>
                        {maintenanceDataLoading ? (
                            <MonthlyMaintSkeleton />
                        ) : (
                            <>
                                <Stack
                                    sx={{
                                        p: 3,
                                        pb: 0,
                                        color: theme.palette.text.secondary,
                                    }}
                                    direction="row"
                                >
                                    <Typography
                                        variant="h6"
                                        color="textSecondary"
                                    >
                                        Inquire About Unlimited Plan
                                    </Typography>
                                    <InfoOutlined
                                        fontSize="small"
                                        sx={{ ml: 1 }}
                                    />
                                    {/* <Typography variant="h3">$7,650</Typography> */}
                                </Stack>
                                <MonthlyMaint
                                    value={
                                        maintenanceData.numericPercentageUsed
                                    }
                                    data={maintenanceData}
                                    isError={maintenanceDataError}
                                />
                            </>
                        )}
                    </MainCard>
                </Grid>
            )}
            {/* row 3 */}
            <Grid item xs={12} md={7} lg={7}>
                <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Grid item>
                        <Typography variant="h5">Alerts</Typography>
                    </Grid>
                </Grid>
                <MainCard sx={{ mt: 2 }} content={false} isAdmin={isAdmin}>
                    {alertsLoading ? (
                        <AlertsSkeleton />
                    ) : (
                        <AlertsTable
                            alertData={alertData}
                            isError={alertError}
                        />
                    )}
                </MainCard>
            </Grid>
            <Grid item xs={12} md={5} lg={5}>
                <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Grid item>
                        <Typography variant="h5">Payments</Typography>
                    </Grid>
                    <Grid item />
                </Grid>
                <MainCard sx={{ mt: 2 }} content={false} isAdmin={isAdmin}>
                    {yearlyPaymentsLoading || weeklyUsageLoading ? (
                        <PaymentsSkeleton />
                    ) : (
                        <YearlyPaymentsChart
                            data={[
                                {
                                    name: 'None Maintenance Payments',
                                    data: yearlyPayments?.noneMaintPayments,
                                },
                                {
                                    name: 'Maintenance Payments',
                                    data: yearlyPayments?.maintenancePayments,
                                },
                            ]}
                            isError={yearlyPaymentsError}
                        />
                    )}
                </MainCard>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
                <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Grid item>
                        <Typography variant="h5">
                            Recently Resolved Tickets
                        </Typography>
                    </Grid>
                    <Grid item />
                </Grid>
                <MainCard sx={{ mt: 2 }} content={false} isAdmin={isAdmin}>
                    {resolvedTicketsLoading ? (
                        <RecentlyResolvedSkeleton />
                    ) : (
                        <RecentlyResolvedTable
                            recentlyResolvedTickets={recentlyResolvedTickets}
                            isError={recentlyResolvedError}
                        />
                    )}
                </MainCard>
            </Grid>
        </Grid>
    )
}

export default DashboardDefault
