import create from 'zustand'

//types
import { NotificationType, ProfileInfoType } from 'types'

import { API_URL } from 'config'
import { headersFunction } from 'api'

//react query
import { queryCache } from 'react-query'

//sentry
import * as Sentry from '@sentry/react'

interface BusinessLogicState {
    isAuthenticated: boolean
    token: string | null
    profileInfo: ProfileInfoType | null
    resetPassEmail: null | string
    setToken: (token: string | null) => void
    removeToken: () => void
    setProfileInfo: (profileInfo: ProfileInfoType) => void
    checkAndRefreshToken: () => void
    logout: () => void
    markAllNotificationsRead: (data: NotificationType[]) => Promise<void>
    setResetPassEmail: (email: string) => void
}

const useBusinessLogic = create<BusinessLogicState>((set, get) => ({
    isAuthenticated: false,
    token: localStorage.getItem('token') || null,
    profileInfo: null,
    resetPassEmail: null,
    setToken: (token: string | null) => {
        localStorage.removeItem('token')
        localStorage.setItem('token', token || '')
        set((state) => ({
            ...state,
            token,
            isAuthenticated: !!token,
        }))
    },
    removeToken: () => {
        localStorage.removeItem('token')
        set((state) => ({
            ...state,
            token: null,
        }))
    },

    setProfileInfo: (profileInfo: ProfileInfoType) => {
        set((state) => ({
            ...state,
            profileInfo,
        }))
    },
    checkAndRefreshToken: async () => {
        try {
            if (get().isAuthenticated) {
                const response = await fetch(
                    `${API_URL}/users/checkAndRefreshToken`,
                    {
                        method: 'POST',
                        headers: headersFunction(),
                    }
                )
                const data = await response.json()
                if (data?.errors) {
                    data.errors.forEach((error: { msg: string }) => {
                        throw new Error(error?.msg || 'Something went wrong!')
                    })
                }

                if (data?.error) {
                    throw new Error('Something went wrong!')
                }

                if (data?.token) {
                    localStorage.setItem('token', data?.token)
                    set((state) => ({
                        ...state,
                        isAuthenticated: true,
                        token: data?.token,
                        // profileInfo: { ...data?.user, isAdmin: 'no' },
                        profileInfo: data?.user,
                    }))
                    return true
                } else {
                    set((state) => ({
                        ...state,
                        isAuthenticated: false,
                        token: null,
                    }))
                }
            }
        } catch (e) {
            console.log(e)
            Sentry.captureException(e)
            set((state) => ({
                ...state,
                isAuthenticated: false,
                token: null,
            }))
        }
    },
    logout: () => {
        localStorage.removeItem('token')
        queryCache.clear()
        set((state) => ({
            ...state,
            isAuthenticated: false,
            token: null,
            profileInfo: null,
        }))
    },
    markAllNotificationsRead: async (data: NotificationType[]) => {
        try {
            //extract the notification ids from the data
            const notificationIds = data.map((item) => item.id)

            await fetch(`${API_URL}/users/notifications/markRead`, {
                method: 'POST',
                headers: headersFunction(),
                body: JSON.stringify({
                    notificationID: notificationIds,
                }),
            })
        } catch (error) {
            Sentry.captureException(error)
            console.log(error)
        }
    },
    setResetPassEmail: (email: string) => {
        set((state) => ({
            ...state,
            resetPassEmail: email,
        }))
    },
}))

export default useBusinessLogic
