import React from 'react'
// material-ui
import { CustomTheme, useTheme } from '@mui/material/styles'
import { Box, Drawer, Typography, useMediaQuery } from '@mui/material'

// project import
// import DrawerHeader from 'layout/MainLayout/Drawer/DrawerHeader/index'
import DrawerContent from './DrawerContent'
import MiniDrawerStyled from './MiniDrawerStyled'
import config, { drawerWidth } from 'config'
import SRLogo from 'assets/images/icons/SRLogo.svg'

// ==============================|| MAIN LAYOUT - DRAWER ||============================== //
//types
import { MainDrawerType } from 'types'

//router
import { Link } from 'react-router-dom'

//framer
import { motion } from 'framer-motion'
import { useIsFetching } from '@tanstack/react-query'
import { queryKeys } from 'react-query'

const MainDrawer: React.FC<MainDrawerType> = ({
    open,
    handleDrawerToggle,
    openDrawerHover,
    closeDrawerHover,
    drawerType,
    window,
}): JSX.Element => {
    const theme = useTheme<CustomTheme>()
    const matchDownMD = useMediaQuery(theme.breakpoints.down('lg'))

    // responsive drawer container
    const container =
        window !== undefined ? () => window().document.body : undefined

    const isFetching = useIsFetching({ queryKey: [queryKeys.openTickets] })
    console.log('🚀 ~ file: index.tsx ~ line 41 ~ isFetching', isFetching)

    return (
        <Box
            component="nav"
            sx={{ flexShrink: { md: 0 }, zIndex: 1300 }}
            aria-label="mailbox folders"
        >
            {!matchDownMD ? (
                <MiniDrawerStyled
                    variant="permanent"
                    open={open}
                    onMouseEnter={openDrawerHover}
                    onMouseLeave={closeDrawerHover}
                    //@ts-ignore
                    drawerType={drawerType}
                >
                    {/* {open ? (
                        <DrawerHeader
                            handleDrawerToggle={handleDrawerToggle}
                            open={open}
                        />
                    ) : ( */}
                    <Box
                        sx={{
                            alignItems: 'center',
                            display: 'flex',
                            justifyContent: 'center',
                            width: '100%',
                            paddingY: '11px',
                            borderBottom: '1px solid',
                            borderColor: 'divider',
                        }}
                    >
                        <Link
                            to="/dashboard"
                            style={{
                                height: config.headerHeight,
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                                textDecoration: 'none',
                            }}
                        >
                            {!open && (
                                <motion.div
                                    layout
                                    initial={{
                                        opacity: 0,
                                    }}
                                    animate={{
                                        opacity: 1,
                                        ...(isFetching > 0 && { rotate: 360 }),
                                    }}
                                    transition={{
                                        duration: 0.5,
                                    }}
                                    style={{
                                        alignItems: 'center',
                                        display: 'flex',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <img
                                        src={SRLogo}
                                        alt="SR Logo"
                                        style={{
                                            width: config.headerHeight,
                                            height: config.headerHeight,
                                        }}
                                    />
                                </motion.div>
                            )}

                            {open && (
                                <motion.div
                                    layout
                                    initial={{
                                        opacity: 0,
                                        // y: '-20px',
                                    }}
                                    animate={{
                                        opacity: 1,
                                        //  y: 0
                                    }}
                                    transition={{
                                        duration: 0.14,
                                        delay: 0.15,
                                    }}
                                    style={{
                                        width: '100%',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    <img
                                        src={SRLogo}
                                        alt="SR Logo"
                                        style={{
                                            width: config.headerHeight,
                                            height: config.headerHeight,
                                        }}
                                    />
                                    <Typography
                                        variant="h2"
                                        color={theme.palette.text.primary}
                                        sx={{
                                            marginLeft: '10px',
                                        }}
                                    >
                                        SimplyRem
                                    </Typography>
                                </motion.div>
                            )}
                        </Link>
                    </Box>
                    <DrawerContent />
                </MiniDrawerStyled>
            ) : null}

            {matchDownMD ? (
                <Drawer
                    container={container}
                    variant="persistent"
                    open={open}
                    onClose={handleDrawerToggle}
                    sx={{
                        display: 'block',
                        '& .MuiDrawer-paper': {
                            boxSizing: 'border-box',
                            width: drawerWidth,
                            borderRight: `1px solid ${theme.palette.divider}`,
                            marginTop: '61px',
                            // boxShadow: 'inherit',
                            //@ts-ignore
                            backgroundColor: theme.palette.background.lighter,
                            boxShadow: 'inset 0px 0px 5px rgba(0, 0, 0, 0.1)',
                        },
                    }}
                >
                    {/* {open ? (
                        <DrawerHeader
                            handleDrawerToggle={handleDrawerToggle}
                            open={open}
                        />
                    ) : null} */}
                    {open ? <DrawerContent /> : null}
                </Drawer>
            ) : null}
        </Box>
    )
}

MainDrawer.displayName = 'MainDrawer'

export default MainDrawer
