import React from 'react'

// material-ui
import { Box, CustomTheme, Typography, useTheme } from '@mui/material'

import { DataGrid, GridToolbar, GridColDef } from '@mui/x-data-grid'

//types
import { ChargedTicketsTableType } from 'types'

//skeleton
import RecentlyResolvedSkeleton from 'components/skeletons/RecentlyResolvedSkeleton'

//zustand
import useMiscellaneous from 'store/useMiscellaneous'
import { WarningOutlined } from '@ant-design/icons'

const InvoiceReportTable: React.FC<ChargedTicketsTableType> = ({
    financeReportData = [],
    isFetching,
    isError,
}): JSX.Element | null => {
    //zustand
    const setTicketDetailModal =
        useMiscellaneous.getState().setTicketDetailModal

    //theme
    const theme = useTheme<CustomTheme>()

    const headCells: GridColDef[] = [
        {
            field: 'invoiceNumber',
            headerName: 'Invoice Number',
            minWidth: 150,
        },
        {
            field: 'payAmount',
            headerName: 'Amount Paid',
            valueFormatter: (params) => {
                return `$${params.value}`
            },
        },
        {
            field: 'payDate',
            headerName: 'Payment Date',
            minWidth: 150,
        },
        {
            field: 'notes',
            headerName: 'Notes',
            flex: 1,
            minWidth: 150,
        },
        {
            field: 'payType',
            headerName: 'Payment Method',
            flex: 1,
            minWidth: 150,
        },
    ]

    if (isError) {
        return (
            <Box
                sx={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex',
                    height: 414,
                    width: '100%',
                }}
            >
                <WarningOutlined
                    style={{
                        fontSize: '20px',
                        color: theme.palette.error.main,
                    }}
                />
                <Typography
                    variant="h5"
                    color="text.secondary"
                    sx={{
                        ml: 1,
                    }}
                >
                    Something went wrong, please try again later.
                </Typography>
            </Box>
        )
    }

    if (isFetching) {
        return <RecentlyResolvedSkeleton height={window.innerWidth * 0.4} />
    }

    return (
        <Box>
            <DataGrid
                rows={financeReportData}
                columns={headCells}
                components={{
                    Toolbar: GridToolbar,
                }}
                autoHeight
                scrollbarSize={10}
                onRowClick={(e) => {
                    setTicketDetailModal(true, e.row.ticketID)
                }}
                sx={{
                    p: 2,
                    borderRadius: 2,
                    borderWidth: 0.5,
                }}
                getRowId={(row) => row.inv_id}
            />
        </Box>
    )
}

export default InvoiceReportTable
