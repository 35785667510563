import React from 'react'

// material-ui
import { Box, Button, CustomTheme, Typography, useTheme } from '@mui/material'

import { DataGrid, GridToolbar, GridColDef } from '@mui/x-data-grid'

// project import
import Dot from 'components/@extended/Dot'

//types
import { ChargedTicketsTableType } from 'types'

//skeleton
import RecentlyResolvedSkeleton from 'components/skeletons/RecentlyResolvedSkeleton'

//zustand
import useMiscellaneous from 'store/useMiscellaneous'
import { WarningOutlined } from '@ant-design/icons'

const ChargedTicketsTable: React.FC<ChargedTicketsTableType> = ({
    financeReportData = [],
    isFetching,
    isError,
}): JSX.Element | null => {
    //zustand
    const setTicketDetailModal =
        useMiscellaneous.getState().setTicketDetailModal

    //theme
    const theme = useTheme<CustomTheme>()

    const headCells: GridColDef[] = [
        {
            field: 'ticketID',
            headerName: 'TicketID',
            renderCell: (params) => (
                <Button
                    onClick={() => {
                        setTicketDetailModal(true, params.row.ticketID)
                    }}
                >
                    {params.value}
                </Button>
            ),
            minWidth: 100,
        },
        {
            field: 'chargeAmount',
            headerName: 'Charge Amount',
            minWidth: 150,
            valueFormatter: (params) => {
                return `$${params.value}`
            },
        },
        // {
        //     field: 'ticketID',
        //     headerName: 'Ticket ID',
        // },
        {
            field: 'issue',
            headerName: 'Issue',
            flex: 1,
            minWidth: 150,
        },
        {
            field: 'resolution',
            headerName: 'Resolution',
            flex: 1,
            minWidth: 150,
        },
        {
            field: 'completedDate',
            headerName: 'Completion Date',
            minWidth: 150,
        },
    ]

    if (isError) {
        return (
            <Box
                sx={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex',
                    height: 414,
                    width: '100%',
                }}
            >
                <WarningOutlined
                    style={{
                        fontSize: '20px',
                        color: theme.palette.error.main,
                    }}
                />
                <Typography
                    variant="h5"
                    color="text.secondary"
                    sx={{
                        ml: 1,
                    }}
                >
                    Something went wrong, please try again later.
                </Typography>
            </Box>
        )
    }

    if (isFetching) {
        return <RecentlyResolvedSkeleton height={window.innerWidth * 0.4} />
    }

    return (
        <Box>
            <DataGrid
                rows={financeReportData}
                columns={headCells}
                components={{
                    Toolbar: GridToolbar,
                }}
                autoHeight
                scrollbarSize={10}
                onRowClick={(e) => {
                    console.log(
                        '🚀 ~ file: FinanceReportTable.tsx ~ line 99 ~ e.row.ticketID',
                        e.row.ticketID
                    )
                    setTicketDetailModal(true, e.row.ticketID)
                }}
                sx={{
                    p: 2,
                    borderRadius: 2,
                    borderWidth: 0.5,
                }}
            />
        </Box>
    )
}

export default ChargedTicketsTable
