import React from 'react'

// third-party
import { motion } from 'framer-motion'

//types
import { AnimatedButtonType } from 'types'

// ==============================|| ANIMATION BUTTON ||============================== //

const AnimateButton: React.FC<AnimatedButtonType> = ({
    children,
    type,
}): JSX.Element => {
    switch (type) {
        case 'rotate': // only available in paid version
        case 'slide': // only available in paid version
        case 'scale': // only available in paid version
        default:
            return (
                <motion.div whileHover={{ scale: 1 }} whileTap={{ scale: 0.9 }}>
                    {children}
                </motion.div>
            )
    }
}

AnimateButton.defaultProps = {
    type: 'scale',
}

export default AnimateButton
