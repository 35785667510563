import { AnimatingSRLogo } from './AnimatingSRLogo'
import React, { useEffect, useState } from 'react'

// material-ui
import {
    Box,
    Button,
    CircularProgress,
    CustomTheme,
    FormHelperText,
    Grid,
    InputLabel,
    OutlinedInput,
    Stack,
    Typography,
    useTheme,
} from '@mui/material'

import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'

//yup
import * as Yup from 'yup'
import 'yup-phone'
import { Formik } from 'formik'

//components
import AnimateButton from 'components/@extended/AnimateButton'
import MainCard from 'components/MainCard'

//config
import { API_URL } from 'config'

import AuthCard from 'pages/authentication/AuthCard'

//components
import SlideUpAnimation from 'layout/SlideUpAnimation'

//api
import { getMaintenanceData, getOpenBalances } from 'api'

//react query
import { useQuery } from '@tanstack/react-query'

//react-query
import { queryClient, queryKeys } from 'react-query'

//toast
import { toast } from 'react-toastify'

//zustand
import useMiscellaneous from 'store/useMiscellaneous'
import CheckCircleOutline from '@mui/icons-material/CheckCircleOutline'

//mantis
import { useFocusTrap } from '@mantine/hooks'

//utils
import { errorTypeHandler } from 'utils'

//sentry
import * as Sentry from '@sentry/react'
import DragAndDrop from 'components/DragAndDrop'

const KEY_NAME_ESC = 'Escape'
const KEY_EVENT_TYPE = 'keyup'

function useEscapeKey(handleClose: any) {
    const handleEscKey = React.useCallback(
        (event: any) => {
            if (event.key === KEY_NAME_ESC) {
                handleClose()
            }
        },
        [handleClose]
    )

    useEffect(() => {
        document.addEventListener(KEY_EVENT_TYPE, handleEscKey, false)

        return () => {
            document.removeEventListener(KEY_EVENT_TYPE, handleEscKey, false)
        }
    }, [handleEscKey])
}

const CreateTicketForm: React.FC<{
    closeModal: (values: any) => void
}> = ({ closeModal }): JSX.Element => {
    //zustand

    const setTicketCreationDisclaimerModal =
        useMiscellaneous.getState().setTicketCreationDisclaimerModal

    const ticketDisclaimerAgreed = useMiscellaneous(
        (state) => state.ticketDisclaimerAgreed
    )
    const setTicketDisclaimerAgreed =
        useMiscellaneous.getState().setTicketDisclaimerAgreed

    //theme
    const theme = useTheme<CustomTheme>()

    //focus trap
    const focusTrapRef = useFocusTrap()

    useEscapeKey(closeModal)

    const [files, setFiles] = useState<
        | {
              name: string
              preview: string
          }[]
        | Blob
    >([])

    const {
        data: maintenanceData = {},
        isLoading: maintenanceDataLoading,
        isError: maintenanceDataError,
    } = useQuery([queryKeys.maintenanceData], getMaintenanceData)

    const {
        data: openBalances = [],
        isLoading: openBalancesLoading,
        isError: openBalancesError,
    } = useQuery([queryKeys.openBalances], getOpenBalances)

    return (
        <Box ref={focusTrapRef}>
            <Grid
                container
                direction="column"
                justifyContent="center"
                sx={{
                    minHeight: '100vh',
                    overflow: 'scroll',
                }}
            >
                <Grid item xs={12}>
                    <Grid
                        item
                        xs={12}
                        container
                        justifyContent="center"
                        alignItems="center"
                        sx={{
                            maxHeight: {
                                xs: 'calc(100vh - 134px)',
                                md: 'calc(100vh - 112px)',
                            },
                        }}
                    >
                        <Grid
                            item
                            sx={{
                                paddingBottom: {
                                    xs: 30,
                                },
                            }}
                        >
                            <SlideUpAnimation>
                                <AuthCard>
                                    <Grid item>
                                        <Stack
                                            direction="row"
                                            justifyContent="space-between"
                                            alignItems="baseline"
                                            sx={{
                                                mb: {
                                                    xs: 1,
                                                    sm: 1,
                                                    md: 2,
                                                    lg: 2,
                                                },
                                            }}
                                        >
                                            <Typography variant="h3">
                                                Create Ticket
                                            </Typography>

                                            <AnimatingSRLogo />
                                        </Stack>
                                    </Grid>
                                    <Grid item>
                                        <MainCard
                                            sx={{
                                                backgroundColor:
                                                    theme.palette.mode ===
                                                    'dark'
                                                        ? theme.palette
                                                              .grey[800]
                                                        : theme.palette
                                                              .grey[200],
                                                mb: {
                                                    xs: 1,
                                                    sm: 1,
                                                    md: 2,
                                                    lg: 2,
                                                },
                                                borderRadius: 1,
                                            }}
                                            contentSX={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                height: 20,
                                                px: 1.5,
                                            }}
                                        >
                                            <Stack
                                                direction="row"
                                                justifyContent="space-between"
                                                alignItems="center"
                                                width="100%"
                                            >
                                                <Typography
                                                    variant="h6"
                                                    sx={{
                                                        fontWeight: 'bold',
                                                    }}
                                                >
                                                    Tickets Remaining for this
                                                    Month
                                                </Typography>

                                                <Typography
                                                    variant="h4"
                                                    color="primary"
                                                    sx={{
                                                        fontWeight: 'bold',
                                                    }}
                                                >
                                                    {
                                                        maintenanceData?.ticketsRemaining
                                                    }
                                                </Typography>
                                            </Stack>
                                        </MainCard>
                                    </Grid>
                                    <Formik
                                        initialValues={{
                                            ticketTitle: '',
                                            description: '',
                                            ticketType: 'Request',
                                            ticketPriority: 'Medium',
                                            submit: null,
                                        }}
                                        validationSchema={Yup.object().shape({
                                            ticketTitle: Yup.string()
                                                .max(255)
                                                .required(
                                                    'Ticket Title is Required'
                                                ),
                                            description: Yup.string()
                                                .max(255)
                                                .required(
                                                    'Description is Required'
                                                ),
                                            ticketType: Yup.string()
                                                .max(255)
                                                .required(
                                                    'Ticket Type is Required'
                                                ),
                                            ticketPriority: Yup.string()
                                                .max(255)
                                                .required(
                                                    'Priority is Required'
                                                ),
                                        })}
                                        onSubmit={async (
                                            values,
                                            {
                                                setErrors,
                                                setStatus,
                                                setSubmitting,
                                            }
                                        ) => {
                                            try {
                                                setStatus({ success: false })
                                                setSubmitting(true)

                                                let openBalanceWarningMessage =
                                                    ''

                                                if (openBalances !== null) {
                                                    openBalanceWarningMessage =
                                                        '⚠️ This client has a open balance, please speak with accounting before completing this job.⚠️'
                                                }

                                                const isOverMaintenanceLimit =
                                                    maintenanceData?.ticketsRemaining ===
                                                        0 ||
                                                    maintenanceData?.isOverLimit

                                                if (
                                                    isOverMaintenanceLimit &&
                                                    !ticketDisclaimerAgreed
                                                ) {
                                                    setTicketCreationDisclaimerModal(
                                                        true
                                                    )
                                                    return
                                                }

                                                const body = new FormData()

                                                body.append(
                                                    'TicketTitle',
                                                    values.ticketTitle +
                                                        ' ' +
                                                        openBalanceWarningMessage
                                                )
                                                body.append(
                                                    'Description',
                                                    values.description
                                                )
                                                body.append(
                                                    'TicketPriority',
                                                    values.ticketPriority
                                                )
                                                body.append(
                                                    'TicketType',
                                                    values.ticketType
                                                )
                                                body.append(
                                                    'isOverLimit',
                                                    isOverMaintenanceLimit ??
                                                        false
                                                )
                                                body.append(
                                                    'ticketsRemaining',
                                                    maintenanceData?.ticketsRemaining ??
                                                        'none-maintenance'
                                                )

                                                // see if files is a Blob or array and then append to the form accordingly
                                                if (files instanceof Blob) {
                                                    body.append('files', files)
                                                } else if (
                                                    files instanceof Array
                                                ) {
                                                    files.forEach((file) => {
                                                        body.append(
                                                            'files',
                                                            //@ts-ignore
                                                            file
                                                        )
                                                    })
                                                }

                                                const data = await fetch(
                                                    `${API_URL}/tickets/create`,
                                                    {
                                                        method: 'POST',
                                                        headers: {
                                                            Authorization: `Bearer ${localStorage.getItem(
                                                                'token'
                                                            )}`,
                                                        },
                                                        body,
                                                    }
                                                )

                                                const results =
                                                    await data.json()

                                                errorTypeHandler(results)

                                                if (data.ok) {
                                                    queryClient.refetchQueries({
                                                        queryKey: [
                                                            queryKeys.openTickets,
                                                        ],
                                                    })
                                                    queryClient.refetchQueries({
                                                        queryKey: [
                                                            queryKeys.maintenanceData,
                                                        ],
                                                    })
                                                    toast.success(
                                                        'Ticket Created'
                                                    )
                                                    closeModal(true)
                                                } else {
                                                    setStatus({
                                                        success: false,
                                                    })
                                                }

                                                setSubmitting(false)
                                                setTicketDisclaimerAgreed(false)
                                            } catch (err: Error | any) {
                                                Sentry.captureException(err)
                                                setStatus({ success: false })
                                                setErrors({
                                                    //@ts-ignore
                                                    submit: err?.message,
                                                })
                                                setSubmitting(false)
                                            }
                                        }}
                                    >
                                        {({
                                            errors,
                                            handleBlur,
                                            handleChange,
                                            handleSubmit,
                                            isSubmitting,
                                            touched,
                                            values,
                                        }) => (
                                            <form
                                                noValidate
                                                onSubmit={handleSubmit}
                                            >
                                                <Grid container spacing={3}>
                                                    <Grid item xs={12} md={12}>
                                                        <Stack spacing={1}>
                                                            <InputLabel htmlFor="ticket-title">
                                                                Ticket Title
                                                            </InputLabel>
                                                            <OutlinedInput
                                                                id="ticket-title"
                                                                type="ticket-title"
                                                                value={
                                                                    values.ticketTitle
                                                                }
                                                                name="ticketTitle"
                                                                onBlur={
                                                                    handleBlur
                                                                }
                                                                onChange={
                                                                    handleChange
                                                                }
                                                                placeholder=""
                                                                fullWidth
                                                                error={Boolean(
                                                                    touched.ticketTitle &&
                                                                        errors.ticketTitle
                                                                )}
                                                            />
                                                            {touched.ticketTitle &&
                                                                errors.ticketTitle && (
                                                                    <FormHelperText
                                                                        error
                                                                        id="helper-text-firstname-signup"
                                                                    >
                                                                        {
                                                                            errors.ticketTitle
                                                                        }
                                                                    </FormHelperText>
                                                                )}
                                                        </Stack>
                                                    </Grid>

                                                    <Grid item xs={12}>
                                                        <Stack spacing={1}>
                                                            <InputLabel htmlFor="description">
                                                                Description
                                                            </InputLabel>
                                                            <OutlinedInput
                                                                fullWidth
                                                                error={Boolean(
                                                                    touched.description &&
                                                                        errors.description
                                                                )}
                                                                id="description"
                                                                type="description"
                                                                value={
                                                                    values.description
                                                                }
                                                                name="description"
                                                                onBlur={
                                                                    handleBlur
                                                                }
                                                                onChange={
                                                                    handleChange
                                                                }
                                                                placeholder=""
                                                                multiline
                                                                rows={4}
                                                            />
                                                            {touched.description &&
                                                                errors.description && (
                                                                    <FormHelperText
                                                                        error
                                                                        id="description"
                                                                    >
                                                                        {
                                                                            errors.description
                                                                        }
                                                                    </FormHelperText>
                                                                )}
                                                        </Stack>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Stack spacing={1}>
                                                            <InputLabel htmlFor="ticket-type">
                                                                Ticket Type
                                                            </InputLabel>
                                                            <Select
                                                                labelId="ticket-type"
                                                                id="ticket-type"
                                                                value={
                                                                    values.ticketType
                                                                }
                                                                label="ticket-type"
                                                                name="ticketType"
                                                                onChange={
                                                                    handleChange
                                                                }
                                                            >
                                                                <MenuItem
                                                                    value={
                                                                        'Incident'
                                                                    }
                                                                >
                                                                    Incident
                                                                </MenuItem>
                                                                <MenuItem
                                                                    value={
                                                                        'Request'
                                                                    }
                                                                >
                                                                    Request
                                                                </MenuItem>
                                                                <MenuItem
                                                                    value={
                                                                        'Problem'
                                                                    }
                                                                >
                                                                    Problem
                                                                </MenuItem>
                                                                <MenuItem
                                                                    value={
                                                                        'Change'
                                                                    }
                                                                >
                                                                    Change
                                                                </MenuItem>
                                                            </Select>
                                                            {touched.ticketType &&
                                                                errors.ticketType && (
                                                                    <FormHelperText
                                                                        error
                                                                        id="helper-text-company-signup"
                                                                    >
                                                                        Must be
                                                                        a valid
                                                                        phone
                                                                        number
                                                                    </FormHelperText>
                                                                )}
                                                        </Stack>
                                                    </Grid>

                                                    <Grid item xs={12}>
                                                        <Stack spacing={1}>
                                                            <InputLabel htmlFor="password-signup">
                                                                Ticket Priority
                                                            </InputLabel>
                                                            <Select
                                                                labelId="ticket-priority"
                                                                id="ticket-priority"
                                                                value={
                                                                    values.ticketPriority
                                                                }
                                                                label="ticket-priority"
                                                                name="ticketPriority"
                                                                onChange={
                                                                    handleChange
                                                                }
                                                            >
                                                                <MenuItem
                                                                    value={
                                                                        'Low'
                                                                    }
                                                                >
                                                                    Low
                                                                </MenuItem>
                                                                <MenuItem
                                                                    value={
                                                                        'Medium'
                                                                    }
                                                                >
                                                                    Medium
                                                                </MenuItem>
                                                                <MenuItem
                                                                    value={
                                                                        'High'
                                                                    }
                                                                >
                                                                    High
                                                                </MenuItem>
                                                                <MenuItem
                                                                    value={
                                                                        'Critical'
                                                                    }
                                                                >
                                                                    Critical
                                                                </MenuItem>
                                                            </Select>

                                                            {touched.ticketPriority &&
                                                                errors.ticketPriority && (
                                                                    <FormHelperText
                                                                        error
                                                                        id="ticket-priority"
                                                                    >
                                                                        {
                                                                            errors.ticketPriority
                                                                        }
                                                                    </FormHelperText>
                                                                )}
                                                        </Stack>
                                                    </Grid>

                                                    {ticketDisclaimerAgreed && (
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            lg={12}
                                                        >
                                                            <Stack
                                                                direction="row"
                                                                spacing={1}
                                                                alignItems="center"
                                                            >
                                                                <Typography variant="h5">
                                                                    Over Limit
                                                                    Disclaimer
                                                                    Agreed
                                                                </Typography>
                                                                <CheckCircleOutline
                                                                    style={{
                                                                        fontSize:
                                                                            '1.3rem',
                                                                        color: theme
                                                                            .palette
                                                                            .success
                                                                            .main,
                                                                    }}
                                                                />
                                                            </Stack>
                                                        </Grid>
                                                    )}

                                                    {errors.submit && (
                                                        <Grid item xs={12}>
                                                            <FormHelperText
                                                                error
                                                            >
                                                                {errors.submit}
                                                            </FormHelperText>
                                                        </Grid>
                                                    )}

                                                    <Grid item xs={12} lg={12}>
                                                        <DragAndDrop
                                                            files={files}
                                                            setFiles={setFiles}
                                                        />
                                                    </Grid>

                                                    <Grid item xs={12} lg={6}>
                                                        <AnimateButton>
                                                            <Button
                                                                disableElevation
                                                                fullWidth
                                                                size="medium"
                                                                type="button"
                                                                variant="outlined"
                                                                color="primary"
                                                                onClick={() => {
                                                                    setTicketDisclaimerAgreed(
                                                                        false
                                                                    )
                                                                    closeModal(
                                                                        true
                                                                    )
                                                                }}
                                                            >
                                                                Cancel
                                                            </Button>
                                                        </AnimateButton>
                                                    </Grid>

                                                    <Grid item xs={12} lg={6}>
                                                        <AnimateButton>
                                                            <Button
                                                                disableElevation
                                                                disabled={
                                                                    isSubmitting
                                                                }
                                                                fullWidth
                                                                size="medium"
                                                                type="submit"
                                                                variant="contained"
                                                                color="primary"
                                                                startIcon={
                                                                    isSubmitting && (
                                                                        <CircularProgress
                                                                            size="1rem"
                                                                            color="inherit"
                                                                        />
                                                                    )
                                                                }
                                                            >
                                                                Create
                                                            </Button>
                                                        </AnimateButton>
                                                    </Grid>
                                                </Grid>
                                            </form>
                                        )}
                                    </Formik>
                                </AuthCard>
                            </SlideUpAnimation>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    )
}

export default CreateTicketForm
