import React from 'react'

// material-ui
import { Box, CustomTheme } from '@mui/material'

// project import
import MainCard from 'components/MainCard'

//types
import { AuthCardType } from 'types'
// ==============================|| AUTHENTICATION - CARD WRAPPER ||============================== //

const AuthCard: React.FC<AuthCardType> = ({
    children,
    ...other
}): JSX.Element => (
    <MainCard
        sx={{
            maxWidth: { xs: 400, lg: 475 },
            margin: { xs: 2.5, md: 3 },
            '& > *': {
                flexGrow: 1,
                flexBasis: '50%',
            },
            borderRadius: 2,
        }}
        content={false}
        {...other}
        border={false}
        boxShadow
        shadow={(theme: CustomTheme) =>
            theme.palette.mode === 'dark'
                ? theme.customShadows.dark
                : theme.customShadows.z1
        }
    >
        <Box sx={{ p: { xs: 2, sm: 3, md: 3, xl: 3 } }}>{children}</Box>
    </MainCard>
)

export default AuthCard
