import React from 'react'

// material-ui
import { Box, Button, CircularProgress, IconButton } from '@mui/material'

//zustand
import useMiscellaneous from 'store/useMiscellaneous'

//components
import MainCard from 'components/MainCard'
import { CloseOutlined } from '@ant-design/icons'

//stripe
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js'
import { WalletsOption } from '@stripe/stripe-js'

//api
import { headersFunction } from 'api'
import { API_URL } from 'config'

//mantis
import { useFocusTrap } from '@mantine/hooks'

//react query
import { queryClient, queryKeys } from 'react-query'

//toast
import { toast } from 'react-toastify'

//sentry
import * as Sentry from '@sentry/react'

const PaymentModal: React.FC = (): JSX.Element | null => {
    //zustand
    const setPaymentModalOpen = useMiscellaneous(
        (state) => state.setPaymentModalOpen
    )

    const transactionInfo = useMiscellaneous((state) => state.transactionInfo)
    const isProposal = useMiscellaneous((state) => state.isProposal)

    //focus trap
    const focusTrapRef = useFocusTrap()

    //--------------------------PAYMENT--------------------------//

    const stripe = useStripe()
    const elements = useElements()

    const [isSubmitting, setIsSubmitting] = React.useState(false)

    //@ts-ignore
    const handleSubmit = async (event) => {
        try {
            // We don't want to let default form submission happen here,
            // which would refresh the page.
            event.preventDefault()

            setIsSubmitting(true)

            if (!stripe || !elements) {
                // Stripe.js has not yet loaded.
                // Make sure to disable form submission until Stripe.js has loaded.
                return
            }

            const result = await stripe.confirmPayment({
                //`Elements` instance that was used to create the Payment Element
                elements,
                redirect: 'if_required',
            })

            if (result.error) {
                // Show error to your customer (for example, payment details incomplete)
                console.log(result.error.message)
            } else {
                if (isProposal) {
                    //respond to proposal
                    const response = await fetch(
                        `${API_URL}/finances/proposalResponse`,
                        {
                            method: 'POST',
                            headers: headersFunction(),
                            body: JSON.stringify({
                                proposalID: isProposal?.id?.toString(),
                                response: 'approve',
                            }),
                        }
                    )

                    const data = await response.json()

                    if (data.error) {
                        console.log(data.error)
                        throw new Error(data.error)
                    } else {
                        queryClient.invalidateQueries([
                            queryKeys.pendingProposals,
                        ])
                        toast.success('Proposal Accepted!')
                        setPaymentModalOpen(false, null, null)
                        setIsSubmitting(false)
                        return
                    }
                }

                //check if transactionInfo is an array
                if (!Array.isArray(transactionInfo?.openBalances)) {
                    const response = await fetch(
                        `${API_URL}/finances/closeInvoice`,
                        {
                            method: 'POST',
                            headers: headersFunction(),
                            body: JSON.stringify({
                                invoiceID: transactionInfo?.invoiceNumber,
                            }),
                        }
                    )

                    const data = await response.json()

                    if (data.success) {
                        setPaymentModalOpen(false, null, null)
                        toast.success(
                            'Payment successful! Your invoice has been closed.'
                        )
                        queryClient.invalidateQueries([queryKeys.openBalances])
                    } else {
                        toast.error(
                            'Something went wrong. Please contact us at 1 (800) 704-9736'
                        )
                    }
                } else {
                    //if transactionInfo is an array, then we are paying multiple invoices
                    const response = await fetch(
                        `${API_URL}/finances/closeMultipleInvoices`,
                        {
                            method: 'POST',
                            headers: headersFunction(),
                            body: JSON.stringify({
                                invoiceIDs: transactionInfo?.openBalances?.map(
                                    (invoice) => invoice.invoiceNumber
                                ),
                            }),
                        }
                    )

                    const data = await response.json()

                    if (data.success) {
                        setPaymentModalOpen(false, null, null)
                        toast.success(
                            'Payment successful! Your invoices have been closed.'
                        )
                        queryClient.invalidateQueries([queryKeys.openBalances])
                    } else {
                        throw new Error('Something went wrong')
                    }
                }
            }
            setIsSubmitting(false)
        } catch (error) {
            Sentry.captureException(error)
            setIsSubmitting(false)
            toast.error(
                'Something went wrong. Please contact us at 1 (800) 704-9736'
            )
        }
    }
    //--------------------------PAYMENT--------------------------//

    const wallets: WalletsOption = {
        applePay: 'auto',
        googlePay: 'auto',
    }

    return (
        <form onSubmit={handleSubmit}>
            <Box
                ref={focusTrapRef}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <MainCard>
                    <Box
                        width="100%"
                        display="flex"
                        justifyContent="flex-end"
                        mb={2}
                    >
                        <IconButton
                            aria-label="close-modal"
                            onClick={() => {
                                setPaymentModalOpen(false, null, null)
                            }}
                            color="secondary"
                            sx={{
                                color: 'text.primary',
                            }}
                        >
                            <CloseOutlined />
                        </IconButton>
                    </Box>
                    <PaymentElement
                        options={{
                            wallets: wallets,
                        }}
                        onReady={(e) => e.focus()}
                    />
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={!stripe || isSubmitting}
                        onClick={handleSubmit}
                        sx={{
                            width: '100%',
                            mt: 2,
                        }}
                        startIcon={
                            isSubmitting && (
                                <CircularProgress size="1rem" color="primary" />
                            )
                        }
                    >
                        Submit Payment
                    </Button>
                </MainCard>
            </Box>
        </form>
    )
}

export default PaymentModal
