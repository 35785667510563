// material-ui
import { Box, useMediaQuery, CustomTheme } from '@mui/material'
import { AnimatingSRLogo } from 'components/AnimatingSRLogo'

// ==============================|| AUTH BLUR BACK SVG ||============================== //

const AuthBackground: React.FC<{
    shouldAnimate?: boolean
}> = ({ shouldAnimate = true }): JSX.Element => {
    const matchesXs = useMediaQuery((theme: CustomTheme) =>
        theme.breakpoints.down('sm')
    )
    return (
        <Box
            sx={{
                position: 'absolute',
                filter: 'blur(7px)',
                zIndex: -1,
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                height: '100%',
                width: '100%',
                mt: 5,
            }}
        >
            <AnimatingSRLogo
                shouldAnimate={shouldAnimate}
                size={matchesXs ? '70%' : '80%'}
            />
        </Box>
    )
}

export default AuthBackground
