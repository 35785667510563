import PropTypes from 'prop-types'
import { forwardRef, useState } from 'react'

// material-ui
import { CustomTheme, useTheme } from '@mui/material/styles'
import {
    Card,
    CardContent,
    CardHeader,
    Divider,
    Typography,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

// project import
import Highlighter from './third-party/Highlighter'

//types
import { MainCardType } from 'types'
import AdminAccessNeeded from 'components/AdminAccessNeeded'
// header style
const headerSX = {
    p: 2.5,
    '& .MuiCardHeader-action': { m: '0px auto', alignSelf: 'center' },
}

const useStyles = makeStyles({
    root: {
        transition: 'transform 0.15s ease-in-out',
        '&:hover': { transform: 'scale3d(1.02, 1.02, 1)' },
    },
})

// ==============================|| CUSTOM - MAIN CARD ||============================== //

const MainCard = forwardRef<any, MainCardType>(
    (
        {
            border = true,
            boxShadow,
            children,
            content = true,
            contentSX = {},
            darkTitle,
            divider = true,
            elevation,
            secondary,
            shadow,
            sx = {},
            title,
            codeHighlight,
            hasHover,
            isError,
            isAdmin,
            ...others
        },
        ref
    ) => {
        const theme = useTheme<CustomTheme>()
        boxShadow =
            theme.palette.mode === 'dark' ? boxShadow || true : boxShadow

        const classes = useStyles()

        const [hoverState, setHoverState] = useState({
            raised: false,
            shadow: 1,
        })

        const hoverProps = {
            classes: { root: hoverState.raised ? classes.root : '' },
            onMouseOver: () => setHoverState({ raised: true, shadow: 3 }),
            onMouseOut: () => setHoverState({ raised: false, shadow: 1 }),
        }

        return (
            <Card
                elevation={elevation || 0}
                ref={ref}
                {...others}
                sx={{
                    border: border ? '1px solid' : 'none',
                    borderRadius: 2,
                    borderColor: isError
                        ? theme.palette.error.main
                        : theme.palette.divider,
                    boxShadow:
                        boxShadow && (!border || theme.palette.mode === 'dark')
                            ? shadow || theme.customShadows.light
                            : 'inherit',
                    ':hover': {
                        boxShadow: boxShadow
                            ? shadow || theme.customShadows.z1
                            : 'inherit',
                    },
                    '& pre': {
                        m: 0,
                        p: '16px !important',
                        fontFamily: theme.typography.fontFamily,
                        fontSize: '0.75rem',
                    },
                    position: 'relative',
                    backgroundColor:
                        theme.palette.mode === 'dark'
                            ? theme.palette.grey[900]
                            : theme.palette.background.paper,
                    ...sx,
                }}
                {...(hasHover ? { ...hoverProps } : null)}
            >
                {/* card header and action */}
                {!darkTitle && title && (
                    <CardHeader
                        sx={headerSX}
                        titleTypographyProps={{ variant: 'subtitle1' }}
                        title={title}
                        action={secondary}
                    />
                )}
                {darkTitle && title && (
                    <CardHeader
                        sx={headerSX}
                        title={<Typography variant="h3">{title}</Typography>}
                        action={secondary}
                    />
                )}
                {/* content & header divider */}
                {title && divider && <Divider />}
                {/* card content */}
                {content && (
                    <CardContent sx={contentSX}>{children}</CardContent>
                )}
                {!content && children}
                {/* card footer - clipboard & highlighter  */}
                {codeHighlight && (
                    <>
                        <Divider sx={{ borderStyle: 'dashed' }} />
                        <Highlighter>{children}</Highlighter>
                    </>
                )}
                {isAdmin === undefined ? null : (
                    <AdminAccessNeeded isAdmin={isAdmin ? isAdmin : false} />
                )}
            </Card>
        )
    }
)

MainCard.propTypes = {
    border: PropTypes.bool,
    boxShadow: PropTypes.bool,
    contentSX: PropTypes.object,
    darkTitle: PropTypes.bool,
    divider: PropTypes.bool,
    elevation: PropTypes.number,
    secondary: PropTypes.node,
    shadow: PropTypes.string,
    sx: PropTypes.object,
    title: PropTypes.string,
    codeHighlight: PropTypes.bool,
    content: PropTypes.bool,
    children: PropTypes.node,
}

export default MainCard
