import React, { useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'

// material-ui
import {
    Button,
    FormHelperText,
    Grid,
    Link,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Stack,
    CircularProgress,
} from '@mui/material'

// third party
import * as Yup from 'yup'
import { Formik } from 'formik'

// project import
import AnimateButton from 'components/@extended/AnimateButton'

// assets
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons'

//react router
import { useNavigate, useLocation } from 'react-router-dom'

//zustand
import useBusinessLogic from 'store/useBusinessLogic'

//config
import { API_URL } from 'config'

//react query
import { queryCache } from 'react-query'

//sentry
import * as Sentry from '@sentry/react'

// ============================|| FIREBASE - LOGIN ||============================ //

const AuthLogin: React.FC = (): JSX.Element => {
    //zustand
    const setToken = useBusinessLogic.getState().setToken
    const setProfileInfo = useBusinessLogic.getState().setProfileInfo

    //navigation
    const navigate = useNavigate()
    const location = useLocation()

    //@ts-ignore
    const from = location?.state?.from?.pathname || '/dashboard'

    const [showPassword, setShowPassword] = useState<boolean>(false)
    const handleClickShowPassword = () => {
        setShowPassword(!showPassword)
    }

    const handleMouseDownPassword = (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        event?.preventDefault()
    }

    return (
        <>
            <Formik
                initialValues={{
                    email: '',
                    password: '',
                    submit: null,
                }}
                validationSchema={Yup.object().shape({
                    email: Yup.string()
                        .email('Must be a valid email')
                        .max(255)
                        .required('Email is required'),
                    password: Yup.string()
                        .max(255)
                        .required('Password is required'),
                })}
                onSubmit={async (
                    values,
                    { setErrors, setStatus, setSubmitting }
                ) => {
                    try {
                        setStatus({ success: false })
                        setSubmitting(true)

                        const data = await fetch(`${API_URL}/users/login`, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify({
                                email: values.email,
                                password: values.password,
                            }),
                        })

                        if (data.ok) {
                            const results = await data.json()
                            if (results?.token) {
                                queryCache.clear()
                                setToken(results?.token)
                                setProfileInfo(results?.user)
                                navigate(from, { replace: true })
                            }
                        } else {
                            throw new Error('Unable To Login')
                        }
                        setSubmitting(false)
                    } catch (err: Error | any | undefined) {
                        setStatus({ success: false })
                        setErrors({ submit: err?.message })
                        setSubmitting(false)
                        Sentry.captureException(err)
                    }
                }}
            >
                {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    touched,
                    values,
                }) => (
                    <form noValidate onSubmit={handleSubmit}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Stack spacing={1}>
                                    <InputLabel htmlFor="email-login">
                                        Email Address
                                    </InputLabel>
                                    <OutlinedInput
                                        id="email-login"
                                        type="email"
                                        value={values.email}
                                        name="email"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        placeholder="Enter email address"
                                        fullWidth
                                        error={Boolean(
                                            touched.email && errors.email
                                        )}
                                    />
                                    {touched.email && errors.email && (
                                        <FormHelperText
                                            error
                                            id="standard-weight-helper-text-email-login"
                                        >
                                            {errors.email}
                                        </FormHelperText>
                                    )}
                                </Stack>
                            </Grid>
                            <Grid item xs={12}>
                                <Stack spacing={1}>
                                    <InputLabel htmlFor="password-login">
                                        Password
                                    </InputLabel>
                                    <OutlinedInput
                                        fullWidth
                                        error={Boolean(
                                            touched.password && errors.password
                                        )}
                                        id="-password-login"
                                        type={
                                            showPassword ? 'text' : 'password'
                                        }
                                        value={values.password}
                                        name="password"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={
                                                        handleClickShowPassword
                                                    }
                                                    onMouseDown={
                                                        handleMouseDownPassword
                                                    }
                                                    edge="end"
                                                    size="medium"
                                                >
                                                    {showPassword ? (
                                                        <EyeOutlined />
                                                    ) : (
                                                        <EyeInvisibleOutlined />
                                                    )}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        placeholder="Enter password"
                                    />
                                    {touched.password && errors.password && (
                                        <FormHelperText
                                            error
                                            id="standard-weight-helper-text-password-login"
                                        >
                                            {errors.password}
                                        </FormHelperText>
                                    )}
                                </Stack>
                            </Grid>

                            <Grid item xs={12} sx={{ mt: -1 }}>
                                <Stack
                                    direction="row"
                                    justifyContent="flex-end"
                                    alignItems="center"
                                    spacing={2}
                                >
                                    {/* <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={checked}
                                                onChange={(event) =>
                                                    setChecked(
                                                        event.target.checked
                                                    )
                                                }
                                                name="checked"
                                                color="primary"
                                                size="small"
                                            />
                                        }
                                        label={
                                            <Typography variant="h6">
                                                Keep me sign in
                                            </Typography>
                                        }
                                    /> */}
                                    <Link
                                        variant="h6"
                                        component={RouterLink}
                                        to="/forgotPassword"
                                        color="text.primary"
                                    >
                                        Forgot Password?
                                    </Link>
                                </Stack>
                            </Grid>
                            {errors.submit && (
                                <Grid item xs={12}>
                                    <FormHelperText error>
                                        {errors.submit}
                                    </FormHelperText>
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                <AnimateButton>
                                    {/*// @ts-ignore */}
                                    <Button
                                        disableElevation
                                        disabled={isSubmitting}
                                        fullWidth
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        onClick={handleSubmit}
                                        startIcon={
                                            isSubmitting && (
                                                <CircularProgress
                                                    size="1rem"
                                                    color="primary"
                                                />
                                            )
                                        }
                                    >
                                        Login
                                    </Button>
                                </AnimateButton>
                            </Grid>
                            {/* <Grid item xs={12}>
                                <Divider>
                                    <Typography variant="caption">
                                        {' '}
                                        Login with
                                    </Typography>
                                </Divider>
                            </Grid> */}
                            {/* <Grid item xs={12}>
                                <FirebaseSocial />
                            </Grid> */}
                        </Grid>
                    </form>
                )}
            </Formik>
        </>
    )
}

export default AuthLogin
