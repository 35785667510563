// material-ui
import { Button } from '@mui/material'
// project import
import AnimateButton from 'components/@extended/AnimateButton'

const CreateButton: React.FC<{
    onPress: () => void
    text?: string
    icon?: JSX.Element
}> = ({ onPress, text, icon }): JSX.Element => (
    <AnimateButton>
        {/*// @ts-ignore */}
        <Button
            disableElevation
            // disabled={isSubmitting}
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            color="primary"
            onClick={onPress}
            sx={{
                maxWidth: 150,
            }}
        >
            {text}
            {icon}
        </Button>
    </AnimateButton>
)

export default CreateButton
