import React from 'react'
import { Link } from 'react-router-dom'

// material-ui
import { Grid, Stack, Typography, useTheme, CustomTheme } from '@mui/material'

// project import
import AuthLogin from './auth-forms/AuthLogin'
import AuthWrapper from './AuthWrapper'

//lottie
import Lottie from 'lottie-react'

//assets
import srlogo from '../../assets/srlogo.json'
import srlogo_dark from '../../assets/srlogo_dark.json'

// ================================|| LOGIN ||================================ //

const Login: React.FC = (): JSX.Element => {
    //colormode
    const theme = useTheme<CustomTheme>()

    return (
        <AuthWrapper>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Lottie
                        animationData={
                            theme.palette.mode === 'light'
                                ? srlogo
                                : srlogo_dark
                        }
                        style={{
                            height: 60,
                            marginTop: -10,
                        }}
                        loop={false}
                    />
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="baseline"
                        sx={{ mb: { xs: -0.5, sm: 0.5 } }}
                    >
                        <Typography variant="h3">Login</Typography>
                        <Typography
                            component={Link}
                            to="/register"
                            variant="body1"
                            sx={{ textDecoration: 'none' }}
                            color="primary"
                        >
                            Don&apos;t have an account?
                        </Typography>
                    </Stack>
                </Grid>
                <Grid item xs={12}>
                    <AuthLogin />
                </Grid>
            </Grid>
        </AuthWrapper>
    )
}
export default Login
