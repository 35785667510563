import React, { useCallback, useEffect } from 'react'

//router
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'

// material-ui
import { CircularProgress, Box } from '@mui/material'

//pages
import Register from 'pages/authentication/Register'
import Login from 'pages/authentication/Login'
import DashboardDefault from 'pages/dashboard'

//components
import MainLayout from 'layout/MainLayout'
import RequireAuth from 'routes/RequireAuth'
import EditProfile from 'pages/editProfile'
import SendCodeScreen from 'pages/forgotPassword/SendCodeScreen'
import ReportsPage from 'pages/reports'
import BillingPage from 'pages/billing'
import NotFound from 'pages/NotFound'
import ProposalsPage from 'pages/proposals'
import TicketDetail from 'components/ticket/TicketDetail'
import UsageModal from 'components/UsageModal'
import TicketDisclaimer from 'components/TicketDisclaimer'
import PaymentModal from 'components/PaymentModal'

//toastify
import { toast, ToastContainer } from 'react-toastify'
import { CustomTheme, useTheme, useMediaQuery } from '@mui/material'

//components
import CreateTicketForm from 'components/CreateTicketForm'

import Modal from '@mui/material/Modal'

//zustand
import useMiscellaneous from 'store/useMiscellaneous'
import useBusinessLogic from 'store/useBusinessLogic'

//api
import { getProfileInfo, headersFunction } from 'api'
import { API_URL } from 'config'

//strip
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'

//react-query
import { queryKeys } from 'react-query'
import { useQuery } from '@tanstack/react-query'

//utils
import { trackPathForAnalytics } from 'utils'

const stripePromise = loadStripe(
    'pk_live_51LtgfSJqbM9TDIzk3OQgtXHtDozxZfHVU3EAHhfEM8S9dwUraqyE8jueWDLcyVq8V2FhaJjDjdfmMNmsR8o4MpYf008BugfbIW'
)

const Router: React.FC = (): JSX.Element => {
    //is authenticated
    const isAuthenticated = useBusinessLogic((state) => state.isAuthenticated)

    //navigation
    const navigate = useNavigate()
    const { pathname, search } = useLocation()

    //check current path if there is a token and the user is trying to go to login or register redirect them to dashboard
    const token = useBusinessLogic((state) => state.token)

    useEffect(() => {
        if (token) {
            if (
                pathname === '/login' ||
                pathname === '/register' ||
                pathname === '/forgotPassword' ||
                pathname === '/'
            ) {
                navigate('/dashboard')
            }
        }
    }, [pathname, token])

    //theme
    const theme = useTheme<CustomTheme>()

    //media query
    const matchesXs = useMediaQuery((theme: CustomTheme) =>
        theme.breakpoints.down('md')
    )

    //zustand
    const checkAndRefreshToken =
        useBusinessLogic.getState().checkAndRefreshToken
    const setProfileInfo = useBusinessLogic.getState().setProfileInfo

    //----------------------FIREBASE ANALYTICS----------------------//

    const analytics = useCallback(() => {
        trackPathForAnalytics({
            path: pathname,
            search: search,
            title: pathname.split('/')[1],
        })
    }, [pathname, search])

    useEffect(() => {
        analytics()
    }, [analytics])

    //----------------------FIREBASE ANALYTICS----------------------//
    //----------------------TICKET CREATION----------------------//
    const open = useMiscellaneous((state) => state.createTicketModalOpen)
    const handleClose = useMiscellaneous.getState().setCreateTicketModalOpen

    //----------------------TICKET CREATION----------------------//
    //----------------------TICKET DETAIL----------------------//
    const ticketDetailModalOpen = useMiscellaneous(
        (state) => state.ticketDetailModalOpen
    )
    const setTicketDetailModal =
        useMiscellaneous.getState().setTicketDetailModal

    const handleCloseTicketDetailModal = () => {
        setTicketDetailModal(false, null)
    }
    //----------------------TICKET DETAIL----------------------//
    //----------------------USAGE CHARGE MODAL----------------------//
    const usageModalOpen = useMiscellaneous((state) => state.usageModalOpen)
    const setUsageModal = useMiscellaneous((state) => state.setUsageModal)
    const handleCloseUsageModal = () => {
        setUsageModal(false, null)
    }
    //----------------------USAGE CHARGE MODAL----------------------//
    //----------------------TICKET DISCLAIMER----------------------//
    const ticketCreationDisclaimerModal = useMiscellaneous(
        (state) => state.ticketCreationDisclaimerModal
    )
    const setTicketCreationDisclaimerModal =
        useMiscellaneous.getState().setTicketCreationDisclaimerModal

    const handleCloseTicketDisModal = () => {
        setTicketCreationDisclaimerModal(false)
    }
    //----------------------TICKET DISCLAIMER----------------------//
    //----------------------PAYMENT MODAL----------------------//
    const paymentModalOpen = useMiscellaneous((state) => state.paymentModalOpen)
    const setPaymentModalOpen = useMiscellaneous.getState().setPaymentModalOpen

    const handleCloseTransactionModal = () => {
        setPaymentModalOpen(false, null, null)
    }
    //----------------------PAYMENT MODAL----------------------//
    //--------------------------PAYMENT--------------------------//
    const transactionInfo = useMiscellaneous((state) => state.transactionInfo)

    const [secret, setSecret] = React.useState<string>('')

    //--------------------------TOKEN CHECK AND REFRESH--------------------------//
    //when window focuses check the token and refresh it if needed
    useEffect(() => {
        window.addEventListener('focus', checkAndRefreshToken)
        return () => {
            window.removeEventListener('focus', checkAndRefreshToken)
        }
    }, [])

    //--------------------------SET PROFILE INFO IF REFRESH--------------------------//
    const { data: profileInfo } = useQuery(
        [queryKeys.userInfo],
        getProfileInfo,
        {
            enabled: isAuthenticated,
        }
    )

    useEffect(() => {
        if (profileInfo) {
            setProfileInfo(profileInfo)
        }
    }, [profileInfo])
    //--------------------------SET PROFILE INFO IF REFRESH--------------------------//

    const getSecret = async () => {
        if (paymentModalOpen) {
            try {
                setSecret('')
                const response = await fetch(
                    `${API_URL}/finances/paymentIntent`,
                    {
                        method: 'post',
                        headers: headersFunction(),
                        body: JSON.stringify({
                            amount: Array.isArray(transactionInfo?.openBalances)
                                ? transactionInfo?.totalOwed
                                : transactionInfo?.amoutDue,
                        }),
                    }
                )
                const data = await response.json()

                if (data.error) {
                    throw new Error(data.error)
                }
                setSecret(data.clientSecret)
            } catch (error) {
                console.log(error)
                setPaymentModalOpen(false, null, null)
                toast.error('Something went wrong! Please try again later.')
            }
        }
    }

    useEffect(() => {
        getSecret()
    }, [transactionInfo])
    //--------------------------PAYMENT--------------------------//
    const DARK_BG = 'rgba(74,61,56,0.78)'
    const LIGHT_BG = 'rgba(240,218,213,0.35)'

    return (
        <>
            <MainLayout>
                <Routes>
                    <Route path="/" element={<Login />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/register" element={<Register />} />
                    <Route
                        path="/dashboard"
                        element={
                            <RequireAuth>
                                <DashboardDefault />
                            </RequireAuth>
                        }
                    />
                    <Route
                        path="/forgotPassword"
                        element={<SendCodeScreen />}
                    />
                    <Route
                        path="/editProfile"
                        element={
                            <RequireAuth>
                                <EditProfile />
                            </RequireAuth>
                        }
                    />
                    <Route
                        path="/reports"
                        element={
                            <RequireAuth>
                                <ReportsPage />
                            </RequireAuth>
                        }
                    />
                    <Route
                        path="/billing"
                        element={
                            <RequireAuth>
                                <BillingPage />
                            </RequireAuth>
                        }
                    />
                    <Route
                        path="/proposals"
                        element={
                            <RequireAuth>
                                <ProposalsPage />
                            </RequireAuth>
                        }
                    />
                    <Route path="*" element={<NotFound />} />
                </Routes>
            </MainLayout>

            <ToastContainer
                position="bottom-left"
                bodyStyle={{
                    height: 80,
                    fontSize: 16,
                    color:
                        theme.palette.mode === 'dark'
                            ? '#fff'
                            : theme.palette.text.primary,
                    fontFamily: `'Public Sans', sans-serif`,
                }}
                toastStyle={{
                    borderRadius: 2,
                    padding: 10,
                    boxShadow: ': 0px 0px 141px 19px rgba(0,0,0,1)',
                    width: matchesXs ? '100%' : '450px',
                }}
                style={{
                    width: matchesXs ? '100%' : '450px',
                }}
                theme={theme.palette.mode === 'dark' ? 'colored' : 'light'}
            />
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{
                    backgroundColor:
                        theme.palette.mode === 'dark' ? DARK_BG : LIGHT_BG,
                }}
            >
                <CreateTicketForm
                    closeModal={() => {
                        handleClose(false)
                    }}
                />
            </Modal>
            <Modal
                open={ticketDetailModalOpen}
                onClose={handleCloseTicketDetailModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{
                    backgroundColor:
                        theme.palette.mode === 'dark' ? DARK_BG : LIGHT_BG,
                    alignItems: 'center',
                    justifyContent: 'center',
                    display: 'flex',
                }}
            >
                <TicketDetail closeModal={handleCloseTicketDetailModal} />
            </Modal>

            <Modal
                open={usageModalOpen}
                onClose={handleCloseUsageModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{
                    backgroundColor:
                        theme.palette.mode === 'dark' ? DARK_BG : LIGHT_BG,
                    alignItems: 'center',
                    justifyContent: 'center',
                    display: 'flex',
                }}
            >
                <UsageModal closeModal={handleCloseUsageModal} />
            </Modal>

            <Modal
                open={ticketCreationDisclaimerModal}
                onClose={handleCloseTicketDisModal}
                aria-labelledby="ticket-disclaimer-modal"
                aria-describedby="over-limit-ticket-disclaimer"
                sx={{
                    backgroundColor:
                        theme.palette.mode === 'dark' ? DARK_BG : LIGHT_BG,
                    alignItems: 'center',
                    justifyContent: 'center',
                    display: 'flex',
                }}
            >
                <TicketDisclaimer />
            </Modal>
            <Modal
                open={paymentModalOpen}
                onClose={handleCloseTransactionModal}
                aria-describedby="payment-modal"
                disableAutoFocus
                sx={{
                    backgroundColor:
                        theme.palette.mode === 'dark' ? DARK_BG : LIGHT_BG,
                    alignItems: 'center',
                    justifyContent: 'center',
                    display: 'flex',
                }}
            >
                {secret === '' ? (
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <CircularProgress color="primary" size={100} />
                    </Box>
                ) : (
                    <Elements
                        stripe={stripePromise}
                        options={{
                            clientSecret: secret,
                            appearance: {
                                theme:
                                    theme.palette.mode === 'dark'
                                        ? 'night'
                                        : undefined,
                            },
                        }}
                    >
                        <PaymentModal />
                    </Elements>
                )}
            </Modal>
        </>
    )
}

export default Router
