// material-ui
import { Grid, Typography } from '@mui/material'

import Lottie from 'lottie-react'
import notFound from '../assets/notFound.json'

const NotFound = () => {
    return (
        <Grid container rowSpacing={2} columnSpacing={2}>
            <Grid item xs={12} md={12} lg={12}>
                <Grid container rowSpacing={1} columnSpacing={1.5}>
                    <Grid
                        item
                        xs={12}
                        md={12}
                        lg={12}
                        alignItems="center"
                        justifyContent="center"
                        display="flex"
                        flexDirection="column"
                    >
                        <Lottie
                            animationData={notFound}
                            style={{
                                width: 250,
                                height: 250,
                            }}
                        />

                        <Typography variant="h4">
                            Looks like you are lost. We can’t seem to find the
                            page you’re looking for.
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default NotFound
