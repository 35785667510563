import { useState, useEffect } from 'react'

//zustand
import useBusinessLogic from 'store/useBusinessLogic'

//firebase
import { GA4React } from 'ga-4-react'

export const errorTypeHandler = (data: any) => {
    if (data?.error === 'Please authenticate.') {
        const logout = useBusinessLogic.getState().logout
        logout()
    }

    if (data?.errors) {
        data.errors.forEach((error: { msg: string }) => {
            throw new Error(error?.msg || 'Something went wrong!')
        })
    }

    if (data?.error) {
        throw new Error('Something went wrong!')
    }
}

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window
    return {
        width,
        height,
    }
}

export function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(
        getWindowDimensions()
    )

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions())
        }

        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize)
    }, [])

    return windowDimensions
}

const ga4react = new GA4React('G-REGHE6ZB4S').initialize()

export interface AnalyticsData {
    path: string
    search: string
    title: string
}

export const trackPathForAnalytics = (data: AnalyticsData) => {
    const { path, search, title } = data
    console.log('🚀 ~ file: index.ts:61 ~ trackPathForAnalytics ~ path', path)
    ga4react
        .then((ga) => {
            ga.pageview(path, search, title)
        })
        .catch((err) => console.error(`Analytics failed: ${err}`))
}
