import { CustomTheme, ListItemComponentsPropsOverrides } from '@mui/material'

const ListItemIcon = (theme: CustomTheme): ListItemComponentsPropsOverrides => {
    return {
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    minWidth: 24,
                },
            },
        },
    }
}

export default ListItemIcon
