import React from 'react'

//MUI
import {
    List,
    CustomTheme,
    Grid,
    ListItem,
    Skeleton,
    Stack,
    Typography,
    useTheme,
} from '@mui/material'

//icons
import ReceiptIcon from '@mui/icons-material/Receipt'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import PaidIcon from '@mui/icons-material/Paid'

const ProposalsSkeleton: React.FC = (): JSX.Element => {
    //theme
    const theme = useTheme<CustomTheme>()

    const textStyle = {
        display: 'block',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        ml: 0.5,
    }

    return (
        <List>
            <ListItem>
                <Grid
                    container
                    spacing={'space-between'}
                    direction="row"
                    rowSpacing={1}
                >
                    <Grid item sm={12} xs={12} md={2} lg={2}>
                        <Stack direction="row">
                            <ReceiptIcon
                                style={{
                                    fontSize: '1.2rem',
                                    color: theme.palette.primary.main,
                                }}
                            />

                            <Typography
                                variant="h6"
                                color="inherit"
                                sx={textStyle}
                            >
                                Proposal ID
                            </Typography>
                        </Stack>
                        <Typography
                            variant="h6"
                            color="text.secondary"
                            sx={textStyle}
                        >
                            <Skeleton
                                variant="text"
                                width={100}
                                animation="wave"
                            />
                        </Typography>
                    </Grid>

                    <Grid item sm={12} xs={12} md={2} lg={2}>
                        <Stack direction="row">
                            <PaidIcon
                                sx={{
                                    fontSize: '1.2rem',
                                    color: theme.palette.primary.main,
                                }}
                            />
                            <Typography
                                variant="h6"
                                color="inherit"
                                sx={textStyle}
                            >
                                Due Today
                            </Typography>
                        </Stack>
                        <Typography
                            variant="h6"
                            color="text.secondary"
                            sx={textStyle}
                        >
                            <Skeleton
                                variant="text"
                                width={100}
                                animation="wave"
                            />
                        </Typography>
                    </Grid>

                    <Grid item sm={12} xs={12} md={2} lg={2}>
                        <Stack direction="row">
                            <PaidIcon
                                sx={{
                                    fontSize: '1.2rem',
                                    color: theme.palette.primary.main,
                                }}
                            />
                            <Typography
                                variant="h6"
                                color="inherit"
                                sx={textStyle}
                            >
                                Total Cost
                            </Typography>
                        </Stack>
                        <Typography
                            variant="h6"
                            color="text.secondary"
                            sx={textStyle}
                        >
                            <Skeleton
                                variant="text"
                                width={100}
                                animation="wave"
                            />
                        </Typography>
                    </Grid>

                    <Grid item sm={12} xs={12} md={2} lg={2}>
                        <Stack direction="row">
                            <CalendarMonthIcon
                                sx={{
                                    fontSize: '1.2rem',
                                    color: theme.palette.primary.main,
                                }}
                            />
                            <Typography
                                variant="h6"
                                color="inherit"
                                sx={textStyle}
                            >
                                Notes
                            </Typography>
                        </Stack>

                        <Typography
                            variant="h6"
                            color={theme.palette.text.secondary}
                            sx={textStyle}
                        >
                            <Skeleton
                                variant="text"
                                width={100}
                                animation="wave"
                            />
                        </Typography>
                    </Grid>

                    <Grid item sm={12} xs={12} md={2} lg={2}>
                        <Stack direction="row">
                            <CalendarMonthIcon
                                sx={{
                                    fontSize: '1.2rem',
                                    color: theme.palette.primary.main,
                                }}
                            />
                            <Typography
                                variant="h6"
                                color="inherit"
                                sx={textStyle}
                            >
                                Date Created
                            </Typography>
                        </Stack>

                        <Typography
                            variant="h6"
                            color={theme.palette.text.secondary}
                            sx={textStyle}
                        >
                            <Skeleton
                                variant="text"
                                width={100}
                                animation="wave"
                            />
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        sm={12}
                        xs={12}
                        md={2}
                        lg={2}
                        display="flex"
                        alignItems="center"
                        justifyContent="end"
                    >
                        <Skeleton
                            variant="text"
                            width={60}
                            height={45}
                            animation="wave"
                            sx={{
                                mr: 2,
                            }}
                        />

                        <Skeleton
                            variant="text"
                            width={110}
                            height={45}
                            animation="wave"
                        />
                    </Grid>
                </Grid>
            </ListItem>
        </List>
    )
}

export default ProposalsSkeleton
