import { useState } from 'react'

//react router
import {
    useNavigate,
    useSearchParams,
    createSearchParams,
} from 'react-router-dom'

// material-ui
import {
    Box,
    Button,
    CustomTheme,
    Grid,
    Stack,
    TextField,
    Typography,
    InputLabel,
    FormHelperText,
    CircularProgress,
    useMediaQuery,
} from '@mui/material'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker'

//day js
import dayjs, { Dayjs } from 'dayjs'

//formik
import { Formik } from 'formik'

//API calls
import { getReports } from 'api'

//components
import AnimateButton from 'components/@extended/AnimateButton'
import AuthCard from 'pages/authentication/AuthCard'
import MainCard from 'components/MainCard'
import TicketReportTable from 'components/reports/TicketReportTable'
import ChargedTicketsTable from 'components/reports/ChargedTicketsTable'
import InvoiceReportTable from 'components/reports/InvoiceReportTable'

//yup
import * as Yup from 'yup'

//react-query
import { useQuery } from '@tanstack/react-query'
import { queryKeys } from 'react-query'

const ReportsPage = () => {
    //navigate
    const navigate = useNavigate()

    //search params
    const [searchParams] = useSearchParams()

    //matches sx
    const matchesXs = useMediaQuery((theme: CustomTheme) =>
        theme.breakpoints.down('md')
    )

    //Date picker
    let DatePicker = DesktopDatePicker
    if (matchesXs) {
        DatePicker = MobileDatePicker
    }
    //---------------------- DATE -----------------------//
    const [startDate, setStartDate] = useState<Dayjs>(dayjs(new Date()))

    const handleChangeStartDate = (newValue: Dayjs) => {
        setStartDate(newValue)
    }

    const [endDate, setEndDate] = useState<Dayjs>(dayjs(new Date()))

    const handleChangeEndDate = (newValue: Dayjs) => {
        setEndDate(newValue)
    }
    //---------------------  DATE -----------------------//
    //---------------------- QUERY DATA -----------------//
    const [getTicketData, setGetTicketData] = useState<boolean>(false)
    const [chargedTIcketsData, getChargedTicketsData] = useState<boolean>(false)
    const [getInvoiceData, setGetInvoiceData] = useState<boolean>(false)

    const {
        data: ticketReport = [],
        isLoading: ticketReportsLoading,
        isError: ticketReportsError,
    } = useQuery(
        [queryKeys.ticketReport, searchParams.toString() ?? ''],
        // @ts-ignore
        () =>
            getReports(
                searchParams.get('reportType'),
                //@ts-ignore
                new Date(searchParams.get('startDate')).toISOString(),
                //@ts-ignore
                new Date(searchParams.get('endDate')).toISOString(),
                searchParams.get('ticketStatus')
            ),
        {
            enabled: getTicketData,
            refetchIntervalInBackground: false,
            retry: false,
            refetchOnWindowFocus: false,
        }
    )

    const {
        data: chargedTicketsReport = [],
        isLoading: chargedTicketsLoading,
        isError: chargedTicketsError,
    } = useQuery(
        [queryKeys.chargedTicketsReport, searchParams.toString() ?? ''],
        // @ts-ignore
        () =>
            getReports(
                searchParams.get('reportType'),
                //@ts-ignore
                new Date(searchParams.get('startDate')).toISOString(),
                //@ts-ignore
                new Date(searchParams.get('endDate')).toISOString(),
                searchParams.get('ticketStatus')
            ),
        {
            enabled: chargedTIcketsData,
            refetchIntervalInBackground: false,
            retry: false,
            refetchOnWindowFocus: false,
        }
    )

    const {
        data: invoiceReport = [],
        isLoading: invoiceReportsLoading,
        isError: invoiceReportsError,
    } = useQuery(
        [queryKeys.invoiceReport, searchParams.toString() ?? ''],
        () =>
            getReports(
                searchParams.get('reportType'),
                //@ts-ignore
                new Date(searchParams.get('startDate')).toISOString(),
                //@ts-ignore
                new Date(searchParams.get('endDate')).toISOString()
            ),
        {
            enabled: getInvoiceData,
            refetchIntervalInBackground: false,
            retry: false,
            refetchOnWindowFocus: false,
        }
    )
    //---------------------- QUERY DATA -----------------//

    return (
        <Grid container rowSpacing={4.5} columnSpacing={2.75}>
            <Grid item xs={12} md={12} lg={12}>
                <AuthCard
                    sx={{
                        width: '100%',
                    }}
                >
                    <Grid item xs={12}>
                        <Typography
                            variant="h3"
                            sx={{
                                mb: 1,
                            }}
                        >
                            Reports
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Formik
                                initialValues={{
                                    reportType:
                                        searchParams.get('reportType') ??
                                        'Ticket',
                                    ticketStatus: 'Closed',
                                    submit: null,
                                }}
                                validationSchema={Yup.object().shape({
                                    reportType: Yup.string()
                                        .max(255)
                                        .required('Ticket Title is Required'),
                                    ticketStatus: Yup.string()
                                        .max(255)
                                        .required('ticketStatus is Required'),
                                    endDate: Yup.date().nullable(),
                                    startDate: Yup.date().nullable(),
                                })}
                                onSubmit={async (
                                    values,
                                    { setErrors, setStatus, setSubmitting }
                                ) => {
                                    try {
                                        setStatus({ success: false })

                                        let params = {}

                                        if (
                                            values.ticketStatus === 'Charged' &&
                                            values.reportType === 'Ticket'
                                        ) {
                                            params = {
                                                reportType: values.reportType,
                                                ticketStatus:
                                                    values.ticketStatus,
                                                startDate:
                                                    startDate?.toString(),
                                                endDate: endDate?.toString(),
                                            }
                                            const options = {
                                                pathname: '/reports',
                                                search: `${createSearchParams(
                                                    params
                                                )}`,
                                            }
                                            navigate(options, { replace: true })
                                            setGetTicketData(false)
                                            getChargedTicketsData(true)
                                            return
                                        }

                                        if (values.reportType === 'Ticket') {
                                            params = {
                                                reportType: values.reportType,
                                                ticketStatus:
                                                    values.ticketStatus,
                                                startDate:
                                                    startDate?.toString(),
                                                endDate: endDate?.toString(),
                                            }
                                            const options = {
                                                pathname: '/reports',
                                                search: `${createSearchParams(
                                                    params
                                                )}`,
                                            }
                                            navigate(options, { replace: true })
                                            getChargedTicketsData(false)
                                            setGetTicketData(true)
                                        } else {
                                            params = {
                                                reportType: values.reportType,
                                                startDate:
                                                    startDate?.toString(),
                                                endDate: endDate?.toString(),
                                            }
                                            const options = {
                                                pathname: '/reports',
                                                search: `${createSearchParams(
                                                    params
                                                )}`,
                                            }
                                            navigate(options, { replace: true })
                                            setGetInvoiceData(true)
                                            setGetTicketData(false)
                                        }
                                    } catch (err: Error | any) {
                                        setStatus({ success: false })
                                        setErrors({
                                            //@ts-ignore
                                            submit: err?.message,
                                        })
                                        setSubmitting(false)
                                    }
                                }}
                            >
                                {({
                                    errors,
                                    handleChange,
                                    handleSubmit,
                                    isSubmitting,
                                    touched,
                                    values,
                                }) => (
                                    <form noValidate onSubmit={handleSubmit}>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} md={2} lg={2}>
                                                <Stack spacing={1}>
                                                    <InputLabel htmlFor="report-type">
                                                        Report Type
                                                    </InputLabel>
                                                    <Select
                                                        labelId="report-type"
                                                        id="reportType"
                                                        value={
                                                            values.reportType
                                                        }
                                                        label="report-type"
                                                        name="reportType"
                                                        onChange={handleChange}
                                                    >
                                                        <MenuItem
                                                            value={'Ticket'}
                                                        >
                                                            Ticket
                                                        </MenuItem>
                                                        <MenuItem
                                                            value={'Invoices'}
                                                        >
                                                            Invoices
                                                        </MenuItem>
                                                    </Select>
                                                    {touched.reportType &&
                                                        errors.reportType && (
                                                            <FormHelperText
                                                                error
                                                                id="helper-text-report-type"
                                                            >
                                                                {
                                                                    errors.reportType
                                                                }
                                                            </FormHelperText>
                                                        )}
                                                </Stack>
                                            </Grid>
                                            {values.reportType === 'Ticket' && (
                                                <Grid
                                                    item
                                                    xs={12}
                                                    md={2}
                                                    lg={2}
                                                >
                                                    <Stack spacing={1}>
                                                        <InputLabel htmlFor="ticket-status">
                                                            Ticket Status
                                                        </InputLabel>
                                                        <Select
                                                            labelId="ticket-status"
                                                            id="ticket-type"
                                                            value={
                                                                values.ticketStatus
                                                            }
                                                            label="ticket-status"
                                                            name="ticketStatus"
                                                            onChange={(e) => {
                                                                handleChange(e)
                                                            }}
                                                        >
                                                            <MenuItem
                                                                value={'Closed'}
                                                            >
                                                                Closed
                                                            </MenuItem>
                                                            <MenuItem
                                                                value={
                                                                    'Charged'
                                                                }
                                                            >
                                                                Charged
                                                            </MenuItem>
                                                            <MenuItem
                                                                value={
                                                                    'Canceled'
                                                                }
                                                            >
                                                                Canceled
                                                            </MenuItem>
                                                            <MenuItem
                                                                value={
                                                                    'Pending'
                                                                }
                                                            >
                                                                Pending
                                                            </MenuItem>
                                                        </Select>
                                                        {touched.ticketStatus &&
                                                            errors.ticketStatus && (
                                                                <FormHelperText
                                                                    error
                                                                    id="helper-text-company-signup"
                                                                >
                                                                    {
                                                                        errors.ticketStatus
                                                                    }
                                                                </FormHelperText>
                                                            )}
                                                    </Stack>
                                                </Grid>
                                            )}
                                            <Grid item xs={12} md={2} lg={2}>
                                                <Stack spacing={1}>
                                                    <InputLabel htmlFor="ticket-type">
                                                        Start Date
                                                    </InputLabel>
                                                    <DatePicker
                                                        inputFormat="MM/DD/YYYY"
                                                        value={startDate}
                                                        onChange={(e) => {
                                                            // handleChange(e)
                                                            handleChangeStartDate(
                                                                //@ts-ignore
                                                                e
                                                            )
                                                        }}
                                                        renderInput={(
                                                            params
                                                        ) => (
                                                            <TextField
                                                                {...params}
                                                            />
                                                        )}
                                                    />
                                                </Stack>
                                            </Grid>

                                            <Grid item xs={12} md={2} lg={2}>
                                                <Stack spacing={1}>
                                                    <InputLabel htmlFor="password-signup">
                                                        End Date
                                                    </InputLabel>
                                                    <DatePicker
                                                        // label="Date desktop"
                                                        inputFormat="MM/DD/YYYY"
                                                        value={endDate}
                                                        onChange={(e) => {
                                                            // handleChange(e)
                                                            handleChangeEndDate(
                                                                //@ts-ignore
                                                                e
                                                            )
                                                        }}
                                                        renderInput={(
                                                            params
                                                        ) => (
                                                            <TextField
                                                                {...params}
                                                            />
                                                        )}
                                                    />
                                                </Stack>
                                            </Grid>
                                            {errors.submit && (
                                                <Grid item xs={12}>
                                                    <FormHelperText error>
                                                        {errors.submit}
                                                    </FormHelperText>
                                                </Grid>
                                            )}

                                            <Grid
                                                item
                                                container
                                                direction="row"
                                                alignItems="end"
                                                justifyContent="flex-end"
                                                xs={12}
                                                md={
                                                    values.reportType ===
                                                    'Ticket'
                                                        ? 4
                                                        : 6
                                                }
                                                lg={
                                                    values.reportType ===
                                                    'Ticket'
                                                        ? 4
                                                        : 6
                                                }
                                            >
                                                <Box />
                                                <AnimateButton>
                                                    <Button
                                                        disableElevation
                                                        disabled={isSubmitting}
                                                        fullWidth
                                                        size="medium"
                                                        type="submit"
                                                        variant="contained"
                                                        color="primary"
                                                        startIcon={
                                                            isSubmitting && (
                                                                <CircularProgress
                                                                    size="1rem"
                                                                    color="inherit"
                                                                />
                                                            )
                                                        }
                                                        sx={{
                                                            width: {
                                                                xs: '100%',
                                                                sm: '100%',
                                                                md: 200,
                                                                lg: 200,
                                                            },
                                                        }}
                                                    >
                                                        Create Report
                                                    </Button>
                                                </AnimateButton>
                                            </Grid>
                                        </Grid>
                                    </form>
                                )}
                            </Formik>
                        </LocalizationProvider>
                    </Grid>
                </AuthCard>
            </Grid>

            <Grid item xs={12} md={12} lg={12}>
                {getTicketData ? (
                    <MainCard content={false}>
                        <TicketReportTable
                            ticketsReportData={ticketReport?.tickets}
                            isFetching={ticketReportsLoading}
                            isError={ticketReportsError}
                        />
                    </MainCard>
                ) : chargedTIcketsData ? (
                    <MainCard content={false}>
                        <ChargedTicketsTable
                            financeReportData={chargedTicketsReport?.invoices}
                            isFetching={chargedTicketsLoading}
                            isError={chargedTicketsError}
                        />
                    </MainCard>
                ) : getInvoiceData ? (
                    <MainCard content={false}>
                        <InvoiceReportTable
                            financeReportData={invoiceReport?.invoices}
                            isFetching={invoiceReportsLoading}
                            isError={invoiceReportsError}
                        />
                    </MainCard>
                ) : null}
            </Grid>
        </Grid>
    )
}

export default ReportsPage
