// project import
import Routes from 'routes'
import ThemeCustomization from 'themes'
import ScrollTop from 'components/ScrollTop'

import 'react-toastify/dist/ReactToastify.css'

//react-query
import { queryClient } from 'react-query'
import { QueryClientProvider } from '@tanstack/react-query'

// ==============================|| APP - THEME, ROUTER, LOCAL  ||============================== //

const App = (): JSX.Element => {
    return (
        <QueryClientProvider client={queryClient}>
            <ThemeCustomization>
                <ScrollTop>
                    <Routes />
                </ScrollTop>
            </ThemeCustomization>
        </QueryClientProvider>
    )
}

export default App
