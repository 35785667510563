//framer
import { motion } from 'framer-motion'

const SlideUpAnimation: React.FC<{
    children: React.ReactNode
}> = ({ children }): JSX.Element => {
    return (
        <motion.div
            layout
            initial={{
                opacity: 0.5,
                y: '60px',
            }}
            animate={{ opacity: 1, y: 0 }}
            transition={{
                duration: 0.18,
                ease: 'easeIn',
            }}
            style={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'center',
            }}
        >
            {children}
        </motion.div>
    )
}

export default SlideUpAnimation
