// material-ui
import { styled, CustomTheme } from '@mui/material/styles'
import Drawer from '@mui/material/Drawer'

//eslint-disable-next-line @typescript-eslint/no-unused-vars
import { drawerWidth, drawerMiniWidth } from 'config'

//types
// import { DrawerType } from 'types'

const openedMixin = (theme: CustomTheme) => ({
    width: drawerWidth,
    borderRight: `1px solid ${theme.palette.divider}`,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
    // boxShadow: 'none',
    // backgroundColor: theme.palette.grey[100],
    // boxShadow: 'inset 0px 0px 5px rgba(0, 0, 0, 0.1)',
    // position: drawerType === 'persistent' ? 'fixed' : null,
})

const closedMixin = (theme: CustomTheme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: drawerMiniWidth,
    // boxShadow: theme.customShadows.light,
    // backgroundColor: theme.palette.grey[100],
    // boxShadow: 'inset 0px 0px 10px rgba(0, 0, 0, 0.1)',
    // position: drawerType === 'persistent' ? 'fixed' : null,
})

// ==============================|| DRAWER - MINI STYLED ||============================== //

const MiniDrawerStyled = styled(Drawer, {
    shouldForwardProp: (prop) => prop !== 'open',
    //@ts-ignore -
})(({ theme, open, drawerType }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    position: drawerType === 'persistent' ? 'fixed' : null,
    ...(open && {
        //@ts-ignore
        ...openedMixin(theme),
        //@ts-ignore
        '& .MuiDrawer-paper': openedMixin(theme, drawerType),
    }),
    ...(!open && {
        //@ts-ignore
        ...closedMixin(theme),
        //@ts-ignore
        '& .MuiDrawer-paper': closedMixin(theme, drawerType),
    }),
}))

export default MiniDrawerStyled
