// assets
import {
    DashboardOutlined,
    ChromeOutlined,
    QuestionOutlined,
    MenuFoldOutlined,
    CreditCardOutlined,
    BarChartOutlined,
    ExceptionOutlined,
} from '@ant-design/icons'

//types
import { MenuItem, MenuItemsType } from 'types'

// icons
const icons = {
    DashboardOutlined,
    ChromeOutlined,
    QuestionOutlined,
    MenuFoldOutlined,
    CreditCardOutlined,
    BarChartOutlined,
    ExceptionOutlined,
}

// ==============================|| MENU ITEMS ||============================== //

const dashboard: MenuItem = {
    id: 'group-dashboard',
    title: 'Navigation',
    type: 'group',
    children: [
        {
            id: 'dashboard',
            title: 'Dashboard',
            type: 'item',
            url: '/dashboard',
            icon: icons.DashboardOutlined,
            breadcrumbs: false,
        },
        {
            id: 'reports',
            title: 'Reports',
            type: 'item',
            url: '/reports',
            icon: icons.BarChartOutlined,
            breadcrumbs: false,
        },
        {
            id: 'billing',
            title: 'Billing',
            type: 'item',
            url: '/billing',
            icon: icons.CreditCardOutlined,
            breadcrumbs: false,
        },
        {
            id: 'proposals',
            title: 'Proposals',
            type: 'item',
            url: '/proposals',
            icon: icons.ExceptionOutlined,
            breadcrumbs: false,
        },
    ],
}

// const support: MenuItem = {
//     id: 'support',
//     title: 'Support',
//     type: 'group',
//     children: [
//         {
//             id: 'sample-page',
//             title: 'Sample Page',
//             type: 'item',
//             url: '/sample-page',
//             icon: icons.ChromeOutlined,
//         },
//         {
//             id: 'documentation',
//             title: 'Documentation',
//             type: 'item',
//             url: 'https://codedthemes.gitbook.io/mantis-react/',
//             icon: icons.QuestionOutlined,
//             external: true,
//             target: true,
//         },
//     ],
// }
// ==============================|| MENU ITEMS ||============================== //z

const menuItems: MenuItemsType = {
    items: [dashboard],
}

export default menuItems
