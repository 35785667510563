import React from 'react'
import { useState } from 'react'

// material-ui
import { useTheme } from '@mui/material/styles'
import { Box, List, ListItemButton, ListItemIcon } from '@mui/material'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'

import NightlightIcon from '@mui/icons-material/Nightlight'

//zustand
import useStoredStore from 'store/usePersistedStore'

// ==============================|| HEADER PROFILE - SETTING TAB ||============================== //

const SettingTab: React.FC = (): JSX.Element => {
    //zustand
    const darkMode = useStoredStore((state) => state.darkMode)
    const setDarkMode = useStoredStore.getState().setDarkMode

    const theme = useTheme()

    const [selectedIndex, setSelectedIndex] = useState(0)
    const handleListItemClick = (event: any, index: number) => {
        setSelectedIndex(index)
    }

    const handleColorModeChange = (
        event: React.MouseEvent<HTMLElement>,
        newAlignment: 'dark' | 'light' | 'auto'
    ) => {
        setDarkMode(newAlignment)
    }

    return (
        <List
            component="nav"
            sx={{
                p: 0,
                '& .MuiListItemIcon-root': {
                    minWidth: 32,
                    color: theme.palette.grey[500],
                },
            }}
        >
            <ListItemButton
                selected={selectedIndex === 0}
                onClick={(event) => handleListItemClick(event, 0)}
            >
                <Box flex={1}>
                    <ListItemIcon>
                        <NightlightIcon />
                    </ListItemIcon>
                </Box>
                <ToggleButtonGroup
                    value={darkMode}
                    exclusive
                    onChange={handleColorModeChange}
                    aria-label="text alignment"
                >
                    <ToggleButton value="auto" aria-label="left aligned">
                        Auto
                    </ToggleButton>
                    <ToggleButton value="dark" aria-label="centered">
                        Dark
                    </ToggleButton>
                    <ToggleButton value="light" aria-label="right aligned">
                        Light
                    </ToggleButton>
                </ToggleButtonGroup>
            </ListItemButton>
        </List>
    )
}

export default SettingTab
