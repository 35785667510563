// ==============================|| PRESET THEME - THEME SELECTOR ||============================== //

const CreateCustomTheme = (colors: any, mode: 'light' | 'dark') => {
    const { red, gold, cyan, green, grey } = colors

    const greyColors = {
        0: grey[0],
        50: grey[1],
        100: grey[2],
        200: grey[3],
        300: grey[4],
        400: grey[5],
        500: grey[6],
        600: grey[7],
        700: grey[8],
        800: grey[9],
        900: 'rgba(62,71,71,1)',
        A50: grey[15],
        A100: grey[11],
        A200: grey[12],
        A400: grey[13],
        A700: grey[14],
        A800: grey[16],
    }
    const contrastTextLight = '#fff'
    const contrastTextDark = '#000000'

    const commonColors = (contrastText: string) => ({
        error: {
            lighter: red[2],
            light: red[3],
            main: red[5],
            dark: red[7],
            darker: red[9],
            contrastText,
        },
        warning: {
            lighter: gold[0],
            light: gold[3],
            main: gold[5],
            dark: gold[7],
            darker: gold[9],
            contrastText,
        },
        info: {
            lighter: cyan[0],
            light: cyan[3],
            main: cyan[5],
            dark: cyan[7],
            darker: cyan[9],
            contrastText,
        },
        success: {
            lighter: green[0],
            light: green[3],
            main: green[5],
            dark: green[7],
            darker: green[9],
            contrastText,
        },
        grey: greyColors,
    })

    if (mode === 'light') {
        return {
            primary: {
                lighter: '#FFECE5',
                100: '#FFCBB8',
                200: '#FFA98A',
                light: '#FF885C',
                400: '#FF662E',
                main: '#FF4500',
                dark: '#CC3700',
                700: '#992900',
                darker: '#661C00',
                900: '#330E00',
                contrastTextLight,
            },
            secondary: {
                lighter: greyColors[100],
                100: greyColors[100],
                200: greyColors[200],
                light: greyColors[300],
                400: greyColors[400],
                main: greyColors[500],
                600: greyColors[600],
                dark: greyColors[700],
                800: greyColors[800],
                darker: greyColors[900],
                A100: greyColors[0],
                A200: greyColors.A400,
                A300: greyColors.A700,
                contrastText: greyColors[0],
            },
            divider: greyColors[100],
            text: {
                primary: greyColors[700],
                secondary: greyColors[500],
                disabled: greyColors[400],
            },
            background: {
                paper: greyColors[0],
                default: greyColors.A50,
                lighter: greyColors[800],
            },
            ...commonColors(contrastTextLight),
        }
    } else {
        return {
            primary: {
                lighter: '#FFECE5',
                100: '#FFCBB8',
                200: '#FFA98A',
                light: '#FF885C',
                400: '#FF662E',
                main: '#FF4500',
                dark: '#CC3700',
                700: '#992900',
                darker: '#661C00',
                900: '#330E00',
                contrastTextDark,
            },
            secondary: {
                lighter: greyColors[100],
                100: greyColors[100],
                200: greyColors[200],
                light: greyColors[300],
                400: greyColors[400],
                main: greyColors[500],
                600: greyColors[600],
                dark: greyColors[700],
                800: greyColors[800],
                darker: greyColors[900],
                A100: greyColors[0],
                A200: greyColors.A400,
                A300: greyColors.A700,
                contrastText: greyColors[800],
            },
            text: {
                primary: greyColors[100],
                secondary: greyColors[400],
                disabled: greyColors[600],
            },
            divider: greyColors[200],
            background: {
                default: greyColors[800],
                paper: greyColors[800],
                lighter: greyColors[600],
            },
            ...commonColors(contrastTextDark),
        }
    }
}

export default CreateCustomTheme
