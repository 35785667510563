import React, { ReactNode } from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { useTheme, CustomTheme } from '@mui/material/styles'
import Grid from '@mui/material/Grid'
import useMediaQuery from '@mui/material/useMediaQuery'
import Divider from '@mui/material/Divider'

//icons
import PieChartOutlineIcon from '@mui/icons-material/PieChartOutline'
import SpeedIcon from '@mui/icons-material/Speed'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import LoopIcon from '@mui/icons-material/Loop'
import AllInclusiveIcon from '@mui/icons-material/AllInclusive'

//types
import { MaintenanceData } from 'types'
import { WarningOutlined } from '@ant-design/icons'

const Row: React.FC<{
    rowTitle: string
    value: string | number
    Icon: () => ReactNode
}> = ({ rowTitle, value, Icon }): JSX.Element => (
    <Box
        sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
        }}
    >
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
            }}
        >
            {/*@ts-ignore*/}
            <Icon />
            <Typography
                variant="h5"
                color="inherit"
                sx={{
                    display: 'block',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    ml: 1,
                }}
            >
                {rowTitle}
            </Typography>
        </Box>
        <Typography
            variant="h5"
            color="inherit"
            sx={{
                display: 'block',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
            }}
        >
            {value}
        </Typography>
    </Box>
)

const DividerSpacing = (): JSX.Element => (
    <Grid item>
        <Divider />
    </Grid>
)

const MonthlyMaint: React.FC<{
    value: number
    data: MaintenanceData
    isError: boolean
}> = ({ value, data, isError }): JSX.Element => {
    const theme = useTheme<CustomTheme>()

    const sm = useMediaQuery(theme.breakpoints.down('sm'))
    const md = useMediaQuery(theme.breakpoints.down('md'))

    const [animateValue, setAnimateValue] = React.useState(0)

    React.useEffect(() => {
        setAnimateValue(value)
    }, [value])

    if (isError) {
        return (
            <Box
                sx={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex',
                    width: '100%',
                    height: md ? 340 : 380,
                }}
            >
                <WarningOutlined
                    style={{
                        fontSize: '20px',
                        color: theme.palette.error.main,
                    }}
                />
                <Typography
                    variant="h5"
                    color="text.secondary"
                    sx={{
                        ml: 1,
                    }}
                >
                    Something went wrong, please try again later.
                </Typography>
            </Box>
        )
    }

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                height: md ? 340 : 365,
            }}
        >
            <Grid
                container
                rowSpacing={3}
                xs={6}
                md={12}
                lg={12}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    p: { xs: 1, sm: 1, md: 2, lg: 2 },
                }}
            >
                <Grid item>
                    <Row
                        rowTitle="Monthly Quota"
                        value={data.allowedMonthlyRequests}
                        Icon={() => <PieChartOutlineIcon color="info" />}
                    />
                </Grid>
                <DividerSpacing />
                <Grid item>
                    <Row
                        rowTitle="Tickets Used"
                        value={data.closedTickets}
                        Icon={() => <SpeedIcon color="info" />}
                    />
                </Grid>
                <DividerSpacing />
                <Grid item>
                    <Row
                        rowTitle="Open Tickets"
                        value={data.openTickets}
                        Icon={() => <CheckCircleOutlineIcon color="info" />}
                    />
                </Grid>
                <DividerSpacing />
                <Grid item>
                    <Row
                        rowTitle="Tickets Remaining"
                        value={data.ticketsRemaining}
                        Icon={() => <LoopIcon color="info" />}
                    />
                </Grid>
            </Grid>
            <Grid
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
                xs={12}
                md={12}
                lg={12}
            >
                <CircularProgress
                    variant="determinate"
                    thickness={3.5}
                    size={sm ? 110 : md ? 300 : 200}
                    sx={{
                        position: 'absolute',
                        color: theme.palette.grey[
                            theme.palette.mode === 'light' ? 200 : 800
                        ],
                    }}
                    value={100}
                />

                <CircularProgress
                    variant="determinate"
                    thickness={3.5}
                    size={sm ? 110 : md ? 300 : 200}
                    color="info"
                    sx={{
                        strokeLinecap: 'round',
                        color:
                            value >= 100
                                ? theme.palette.error.main
                                : value >= 75
                                ? theme.palette.warning.main
                                : theme.palette.success.main,
                    }}
                    value={animateValue >= 100 ? 100 : animateValue}
                />

                <Box
                    sx={{
                        height: sm ? 140 : md ? 300 : 200,
                        width: sm ? 140 : md ? 300 : 200,
                        position: 'absolute',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    {data.allowedMonthlyRequests === 0 ? (
                        <AllInclusiveIcon fontSize="large" />
                    ) : (
                        <Typography component="div" variant={sm ? 'h3' : 'h1'}>
                            {data.percentageUsed}
                        </Typography>
                    )}
                </Box>
            </Grid>
        </Box>
    )
}

export default MonthlyMaint
