import { CustomTheme, ButtonPropsColorOverrides } from '@mui/material'

const Button = (theme: CustomTheme): ButtonPropsColorOverrides => {
    const disabledStyle = {
        '&.Mui-disabled': {
            backgroundColor: theme.palette.grey[200],
        },
    }

    return {
        MuiButton: {
            defaultProps: {
                disableElevation: true,
            },
            styleOverrides: {
                root: {
                    fontWeight: 400,
                },
                contained: {
                    ...disabledStyle,
                },
                outlined: {
                    ...disabledStyle,
                },
            },
        },
    }
}

export default Button
