import React, { useEffect, useState } from 'react'

// material-ui
import { useTheme, Typography, Stack, CustomTheme, Box } from '@mui/material'

// third-party
import ReactApexChart from 'react-apexcharts'
import { ApexOptions } from 'apexcharts'

//types
import { OptionsType } from 'types'

//components
import MainCard from 'components/MainCard'

//types
import { YearlyPaymentsChartType } from 'types'

//utils
import { lastTwelveMonths } from 'utils/lastTwelveMonths'

//icons
import PaidIcon from '@mui/icons-material/Paid'
import { WarningOutlined } from '@ant-design/icons'

// chart options
const barChartOptions: ApexOptions = {
    chart: {
        type: 'bar',
        height: 365,
        toolbar: {
            show: false,
        },
    },
    plotOptions: {
        bar: {
            columnWidth: '90%',
            dataLabels: {
                orientation: 'vertical',
            },
            borderRadius: 3,
        },
    },
    stroke: {
        show: true,
        width: 4,
        colors: ['transparent'],
    },
    dataLabels: {
        enabled: true,
        formatter: (val: number) => {
            if (val === 0) {
                return ''
            } else {
                return `$${val}`
            }
        },
    },
    xaxis: {
        categories: lastTwelveMonths,
    },
    yaxis: {
        show: false,
    },
    grid: {
        show: false,
    },
    tooltip: {
        y: {
            formatter: (val: number) => {
                return `$${val}`
            },
        },
    },
}

// ==============================|| MONTHLY BAR CHART ||============================== //

const YearlyPaymentsChart: React.FC<YearlyPaymentsChartType> = ({
    data,
    isError,
}): JSX.Element => {
    const theme = useTheme<CustomTheme>()

    const { primary, secondary } = theme.palette.text
    const info = theme.palette.info.light
    const primaryOrange = theme.palette.primary.main

    //@ts-ignore
    const [options, setOptions] = useState<OptionsType>(barChartOptions)

    useEffect(() => {
        //@ts-ignore
        setOptions((prevState: OptionsType) => ({
            ...prevState,
            colors: [info, primaryOrange],
            xaxis: {
                labels: {
                    style: {
                        colors: [
                            secondary,
                            secondary,
                            secondary,
                            secondary,
                            secondary,
                            secondary,
                            secondary,
                        ],
                    },
                },
            },
            tooltip: {
                theme: theme.palette.mode,
            },
        }))
    }, [primary, info, secondary, theme])

    if (isError) {
        return (
            <Box
                sx={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex',
                    height: 359,
                    width: '100%',
                }}
            >
                <WarningOutlined
                    style={{
                        fontSize: '20px',
                        color: theme.palette.error.main,
                    }}
                />
                <Typography
                    variant="h5"
                    color="text.secondary"
                    sx={{
                        ml: 1,
                    }}
                >
                    Something went wrong, please try again later.
                </Typography>
            </Box>
        )
    }

    return (
        <>
            <Stack
                sx={{
                    p: 3,
                    pb: 0,
                    color: theme.palette.text.secondary,
                }}
                direction="row"
            >
                <Typography variant="h6" color="textSecondary">
                    Payments made in the last 12 months
                </Typography>
            </Stack>
            {/*@ts-ignore*/}
            {!data[0].data ? (
                <Stack
                    sx={{
                        color: theme.palette.text.secondary,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: 312,
                    }}
                    direction="row"
                >
                    <Typography
                        variant="h5"
                        color="text.secondary"
                        sx={{
                            mr: 1,
                        }}
                    >
                        No Payment Records
                    </Typography>
                    <PaidIcon
                        sx={{
                            color:
                                theme.palette.mode === 'dark'
                                    ? theme.palette.grey[50]
                                    : theme.palette.grey[900],
                        }}
                    />
                </Stack>
            ) : (
                <div id="chart">
                    <ReactApexChart
                        //@ts-ignore            {/*@ts-ignore*/}
                        options={options}
                        series={data}
                        type="bar"
                        height={298}
                    />
                </div>
            )}
        </>
    )
}

export default YearlyPaymentsChart
