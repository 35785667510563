import create from 'zustand'

interface DrawerState {
    drawerOpen: boolean
    drawerType: 'permanent' | 'persistent'
    openItem: [string]
    setOpenDrawer: (bool: boolean) => void
    setActiveItem: (id: [string]) => void
    setDrawerType: (type: 'permanent' | 'persistent') => void
}

const useDrawerStore = create<DrawerState>((set) => ({
    drawerOpen: false,
    drawerType: 'persistent',
    openItem: ['dashboard'],
    setOpenDrawer: (bool: boolean) =>
        set((state) => ({
            ...state,
            drawerOpen: bool,
        })),
    setActiveItem: (id: [string]) =>
        set((state) => ({
            ...state,
            openItem: id,
        })),
    setDrawerType: (type: 'permanent' | 'persistent') => {
        set((state) => ({
            ...state,
            drawerType: type,
        }))
    },
}))

export default useDrawerStore
