// material-ui
import { Box } from '@mui/material'
import Skeleton from '@mui/material/Skeleton'

const monthsArray = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]

const PaymentsSkeleton = (): JSX.Element => {
    return (
        <Box>
            <Skeleton
                variant="text"
                animation="wave"
                height={20}
                width={200}
                sx={{
                    m: 2,
                }}
            />
            <Box
                sx={{
                    pt: 2,
                    pr: 2,
                    pl: 2,
                    display: 'flex',
                    justifyContent: 'space-between',
                }}
            >
                {monthsArray.map((index) => (
                    <Skeleton
                        key={index.toString()}
                        variant="rectangular"
                        animation="wave"
                        height={237}
                        width={'4%'}
                        sx={{
                            borderTopLeftRadius: 4,
                            borderTopRightRadius: 4,
                        }}
                    />
                ))}
            </Box>
            <Skeleton
                variant="rectangular"
                animation="wave"
                height={2} // width={'100%'}
                sx={{
                    borderRadius: 2,
                    mb: 2,
                    mx: 2,
                }}
            />
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    pb: 2,
                    px: 2,
                }}
            >
                {monthsArray.map((index) => (
                    <Skeleton
                        key={index.toString()}
                        variant="rectangular"
                        animation="wave"
                        height={20}
                        width={'4%'}
                        sx={{
                            borderRadius: 1,
                        }}
                    />
                ))}
            </Box>
        </Box>
    )
}

export default PaymentsSkeleton
