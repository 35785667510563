import React, { memo } from 'react'
// project import
import Navigation from './Navigation'
import SimpleBar from 'components/third-party/SimpleBar'

const DrawerContent: React.FC = (): JSX.Element => {
    return (
        <SimpleBar
            sx={{
                '& .simplebar-content': {
                    display: 'flex',
                    flexDirection: 'column',
                },
            }}
        >
            <Navigation />
        </SimpleBar>
    )
}

export default memo(DrawerContent)
