import React from 'react'

//MUI
import {
    Button,
    CustomTheme,
    Grid,
    Link,
    ListItem,
    Stack,
    Typography,
    useTheme,
} from '@mui/material'

//types
import { OpenBalanceType } from 'types'

//icons
import ReceiptIcon from '@mui/icons-material/Receipt'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import LocalOfferIcon from '@mui/icons-material/LocalOffer'
import PaidIcon from '@mui/icons-material/Paid'

//zustand
import useMiscellaneous from 'store/useMiscellaneous'

const BalanceRow: React.FC<{
    balance: OpenBalanceType
    onPayPressed: (rowInfo: any) => void
}> = ({ balance, onPayPressed }): JSX.Element => {
    //zustand
    const setTicketDetailModal =
        useMiscellaneous.getState().setTicketDetailModal

    //theme
    const theme = useTheme<CustomTheme>()

    const textStyle = {
        display: 'block',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        ml: 0.5,
    }

    return (
        <ListItem>
            <Grid
                container
                spacing={'space-between'}
                direction="row"
                rowSpacing={1}
            >
                <Grid item sm={12} xs={12} md={3} lg={3}>
                    <Stack direction="row">
                        <ReceiptIcon
                            style={{
                                fontSize: '1.2rem',
                                color: theme.palette.primary.main,
                            }}
                        />

                        <Typography variant="h6" color="inherit" sx={textStyle}>
                            Invoice Number
                        </Typography>
                    </Stack>
                    <Typography
                        variant="h6"
                        color="text.secondary"
                        sx={textStyle}
                    >
                        {balance.invoiceNumber}
                    </Typography>
                </Grid>

                <Grid item sm={12} xs={12} md={3} lg={3}>
                    <Stack direction="row">
                        <PaidIcon
                            sx={{
                                fontSize: '1.2rem',
                                color: theme.palette.primary.main,
                            }}
                        />
                        <Typography variant="h6" color="inherit" sx={textStyle}>
                            Amount Due
                        </Typography>
                    </Stack>
                    <Typography
                        variant="h6"
                        color="text.secondary"
                        sx={textStyle}
                    >
                        ${balance.amoutDue}
                    </Typography>
                </Grid>

                <Grid item sm={12} xs={12} md={3} lg={3}>
                    <Stack direction="row">
                        <CalendarMonthIcon
                            sx={{
                                fontSize: '1.2rem',
                                color: theme.palette.primary.main,
                            }}
                        />
                        <Typography variant="h6" color="inherit" sx={textStyle}>
                            Due Date
                        </Typography>
                    </Stack>

                    <Typography
                        variant="h6"
                        color={theme.palette.text.secondary}
                        sx={textStyle}
                    >
                        {new Date(balance.dueDate).toLocaleDateString()}
                    </Typography>
                </Grid>

                <Grid item sm={12} xs={12} md={2} lg={2}>
                    <Stack direction="row">
                        <LocalOfferIcon
                            sx={{
                                fontSize: '1.2rem',
                                color: theme.palette.primary.main,
                            }}
                        />
                        <Typography variant="h6" color="inherit" sx={textStyle}>
                            Ticket ID
                        </Typography>
                    </Stack>
                    <Button
                        onClick={() => {
                            setTicketDetailModal(true, balance.notes)
                        }}
                        sx={{
                            fontSize: '1rem',
                            display: 'block',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            p: 0,
                        }}
                    >
                        {balance.notes}
                    </Button>
                </Grid>

                <Grid
                    item
                    sm={12}
                    xs={12}
                    md={1}
                    lg={1}
                    display="flex"
                    alignItems="center"
                    justifyContent="end"
                >
                    <Button
                        onClick={onPayPressed}
                        variant="contained"
                        size="small"
                        sx={{
                            height: 30,
                        }}
                    >
                        Pay
                    </Button>
                </Grid>
            </Grid>
        </ListItem>
    )
}

export default BalanceRow
