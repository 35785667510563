// material-ui
import { Box, Stack, Typography, Grid, Avatar } from '@mui/material'

// project import
import EditProfileForm from './EditProfileForm'

// ================================|| REGISTER ||================================ //

const EditProfile: React.FC = (): JSX.Element => (
    <Box
        sx={{
            minHeight: '90vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        }}
    >
        <Grid
            container
            direction="column"
            justifyContent="flex-end"
            sx={{
                maxWidth: 600,
            }}
        >
            <Grid item xs={12}>
                <Grid
                    item
                    xs={12}
                    container
                    sx={{
                        minHeight: {
                            xs: 'calc(100vh - 134px)',
                            md: 'calc(100vh - 112px)',
                        },
                    }}
                >
                    <Grid item>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Stack
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="baseline"
                                    sx={{ mb: { xs: -0.5, sm: 0.5 } }}
                                >
                                    <Typography variant="h3">
                                        Edit Profile
                                    </Typography>
                                </Stack>
                                <Stack
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="center"
                                >
                                    <Avatar
                                        alt="Remy Sharp"
                                        sx={{
                                            width: 100,
                                            height: 100,
                                        }}
                                    />
                                </Stack>
                            </Grid>
                            <Grid item xs={12}>
                                <EditProfileForm />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    </Box>
)

export default EditProfile
