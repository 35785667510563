import { CustomTheme, IconButtonPropsSizeOverrides } from '@mui/material'

const IconButton = (theme: CustomTheme): IconButtonPropsSizeOverrides => {
    return {
        MuiIconButton: {
            styleOverrides: {
                root: {
                    borderRadius: 4,
                    backgroundColor:
                        theme.palette.mode === 'light'
                            ? theme.palette.grey[200]
                            : theme.palette.grey[800],
                },
                sizeLarge: {
                    width: theme.spacing(5.5),
                    height: theme.spacing(5.5),
                    fontSize: '1.25rem',
                },
                sizeMedium: {
                    width: theme.spacing(4.5),
                    height: theme.spacing(4.5),
                    fontSize: '1rem',
                },
                sizeSmall: {
                    width: theme.spacing(3.75),
                    height: theme.spacing(3.75),
                    fontSize: '0.75rem',
                },
            },
        },
    }
}

export default IconButton
