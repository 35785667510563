import React from 'react'

// material-ui
import {
    Button,
    Checkbox,
    CustomTheme,
    Grid,
    IconButton,
    Stack,
    Typography,
    useTheme,
} from '@mui/material'

//zustand
import useMiscellaneous from 'store/useMiscellaneous'

//components
import MainCard from 'components/MainCard'
import { CloseOutlined, WarningOutlined } from '@ant-design/icons'
import AnimateButton from 'components/@extended/AnimateButton'

const TicketDisclaimer: React.FC = (): JSX.Element | null => {
    //zustand
    const setTicketDisclaimerAgreed =
        useMiscellaneous.getState().setTicketDisclaimerAgreed

    const setTicketCreationDisclaimerModal =
        useMiscellaneous.getState().setTicketCreationDisclaimerModal

    //theme
    const theme = useTheme<CustomTheme>()

    const [checked, setChecked] = React.useState(false)

    return (
        <MainCard
            sx={{
                width: {
                    xs: '90%',
                    sm: 400,
                    md: 400,
                    lg: 400,
                    xl: 400,
                },
                minHeight: {
                    xs: '30%',
                    sm: 200,
                    md: 200,
                    lg: 200,
                    xl: 200,
                },
            }}
            contentSX={{ p: 3 }}
        >
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="baseline"
                mb={2}
            >
                <Stack direction="row" spacing={1} alignItems="center">
                    <WarningOutlined
                        style={{
                            fontSize: '1.2rem',
                            color: theme.palette.warning.main,
                        }}
                    />
                    <Typography variant="h4">Disclaimer</Typography>
                </Stack>

                <IconButton
                    aria-label="go-back"
                    onClick={() => {
                        setTicketCreationDisclaimerModal(false)
                        setTicketDisclaimerAgreed(false)
                    }}
                    color="secondary"
                    sx={{
                        color: 'text.primary',
                    }}
                >
                    <CloseOutlined />
                </IconButton>
            </Stack>
            <Typography
                variant="h6"
                color={theme.palette.text.primary}
                sx={{
                    display: 'block',
                }}
            >
                You have either gone over, or are about to go over your monthly
                usage limit. By agreeing to this disclaimer, you are agreeing to
                pay any additional charges that may be incurred as a result of
                your usage.
            </Typography>

            <Stack direction="row" justifyContent="space-between" mt={2}>
                <Stack direction="row" alignItems="center">
                    <Checkbox
                        checked={checked}
                        onChange={(e) => {
                            setChecked(e.target.checked)
                        }}
                        inputProps={{ 'aria-label': 'controlled' }}
                        sx={{
                            color: theme.palette.primary.main,
                            p: 0,
                        }}
                    />
                    <Button
                        sx={{
                            textDecoration: 'underline',
                            color: 'text.primary',
                        }}
                        onClick={() => {
                            setChecked((prevState) => !prevState)
                        }}
                        variant="text"
                    >
                        Agree
                    </Button>
                </Stack>

                <Grid item xs={12} lg={6}>
                    <AnimateButton>
                        <Button
                            disableElevation
                            fullWidth
                            size="medium"
                            type="submit"
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                if (checked) {
                                    setTicketCreationDisclaimerModal(false)
                                    setTicketDisclaimerAgreed(true)
                                }
                            }}
                        >
                            Confirm
                        </Button>
                    </AnimateButton>
                </Grid>
            </Stack>
        </MainCard>
    )
}

export default TicketDisclaimer
