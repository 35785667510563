import { CustomTheme, TablePropsSizeOverrides } from '@mui/material'

const Tab = (theme: CustomTheme): TablePropsSizeOverrides => {
    return {
        MuiTab: {
            styleOverrides: {
                root: {
                    minHeight: 46,
                    color: theme.palette.text.primary,
                },
            },
        },
    }
}

export default Tab
