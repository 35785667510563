import { useEffect, useState } from 'react'

import {
    Box,
    CustomTheme,
    Grid,
    Stack,
    Typography,
    useTheme,
} from '@mui/material'
import Skeleton from '@mui/material/Skeleton'

//components
import OpenTickets from 'components/cards/OpenTicket'
import MainCard from 'components/MainCard'

//API calls
import { getOpenTickets } from 'api'

//types
import { AteraTicketType, OpenTicketsCompType } from 'types'

//react query
import { useQuery } from '@tanstack/react-query'
import { queryKeys } from 'react-query'

//icons
import LocalOfferIcon from '@mui/icons-material/LocalOffer'
import { WarningOutlined } from '@ant-design/icons'

//zustand
import useBusinessLogic from 'store/useBusinessLogic'

const skeletonArray = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
]

const OpenTicketsComp: React.FC<OpenTicketsCompType> = (): JSX.Element => {
    const {
        data: tickets = [],
        isLoading: openTicketsLoading,
        isError: openTicketsError,
    } = useQuery([queryKeys.openTickets], getOpenTickets)
    const [openTickets, setOpenTickets] = useState<AteraTicketType[] | null>(
        tickets
    )

    //theme
    const theme = useTheme<CustomTheme>()

    //profile info
    const profileInfo = useBusinessLogic((state) => state.profileInfo)
    const isAdmin = profileInfo?.isAdmin === 'yes'

    useEffect(() => {
        if (!isAdmin && tickets && profileInfo?.AteraUserID) {
            const filteredTickets = tickets?.filter(
                //@ts-ignore
                (ticket) => ticket.EndUserID === +profileInfo.AteraUserID
            )

            if (filteredTickets.length === 0) {
                return setOpenTickets(null)
            }

            return setOpenTickets(filteredTickets)
        }

        if (isAdmin) {
            setOpenTickets(tickets)
        }
    }, [tickets, profileInfo, isAdmin])

    if (openTicketsLoading) {
        return (
            <Grid
                item
                sx={{
                    overflowX: 'scroll',
                    whiteSpace: 'nowrap',
                    scrollBehavior: 'smooth',
                    flexDirection: 'row',
                    display: 'flex',
                    paddingBottom: 2,
                    '& ::-webkit-scrollbar': { display: 'none' },
                    // msOverflowStyle: 'none',
                    scrollbarColor: 'transparent',
                    scrollbarWidth: 'none',
                }}
                style={{}}
                xs={12}
                sm={12}
                md={12}
                lg={12}
            >
                {skeletonArray.map((item: any) => (
                    <Grid
                        key={`${item}`}
                        item
                        xs={12}
                        sm={12}
                        md={3}
                        lg={3}
                        sx={{
                            marginRight: 3,
                        }}
                    >
                        <MainCard
                            contentSX={{
                                p: 2.25,
                            }}
                            sx={{
                                borderRadius: 2,
                                height: 180,
                                width: 300,
                            }}
                        >
                            <Stack spacing={0.5}>
                                <Stack
                                    spacing={0.5}
                                    direction="row"
                                    justifyContent="space-between"
                                >
                                    <Grid item>
                                        <Skeleton
                                            width={100}
                                            height={20}
                                            animation="wave"
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Skeleton
                                            variant="rectangular"
                                            animation="wave"
                                            width={60}
                                            height={20}
                                            sx={{
                                                borderRadius: 1,
                                            }}
                                        />
                                    </Grid>
                                </Stack>
                                <Grid container alignItems="center">
                                    <Grid
                                        item
                                        sx={{
                                            whiteSpace: 'nowrap',
                                            overflow: ' hidden',
                                            textOverflow: 'ellipsis',
                                            mt: 2,
                                        }}
                                    >
                                        <Skeleton
                                            width={150}
                                            height={40}
                                            animation="wave"
                                        />
                                    </Grid>
                                </Grid>
                            </Stack>
                            <Box>
                                <Skeleton
                                    width={200}
                                    height={20}
                                    animation="wave"
                                />
                                <Skeleton
                                    width={200}
                                    height={20}
                                    animation="wave"
                                />
                            </Box>
                            <Skeleton
                                width={150}
                                height={20}
                                animation="wave"
                            />
                        </MainCard>
                    </Grid>
                ))}
            </Grid>
        )
    }

    return (
        <>
            {openTicketsError ? (
                <Grid
                    item
                    sx={{
                        flexDirection: 'row',
                        display: 'flex',
                        paddingBottom: 2,
                        height: 232,
                    }}
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                >
                    <MainCard
                        contentSX={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '100%',
                            height: '100%',
                            p: 2.5,
                        }}
                        sx={{
                            borderRadius: 2,
                            width: '100%',
                            height: '100%',
                        }}
                    >
                        <Box
                            sx={{
                                justifyContent: 'center',
                                alignItems: 'center',
                                display: 'flex',
                                height: 414,
                                width: '100%',
                            }}
                        >
                            <WarningOutlined
                                style={{
                                    fontSize: '20px',
                                    color: theme.palette.error.main,
                                }}
                            />
                            <Typography
                                variant="h5"
                                color="text.secondary"
                                sx={{
                                    ml: 1,
                                }}
                            >
                                Something went wrong, please try again later.
                            </Typography>
                        </Box>
                    </MainCard>
                </Grid>
            ) : openTickets ? (
                <Grid
                    item
                    sx={{
                        overflowX: 'scroll',
                        whiteSpace: 'nowrap',
                        scrollBehavior: 'smooth',
                        flexDirection: 'row',
                        display: 'flex',
                        paddingBottom: 2,
                        '& ::-webkit-scrollbar': { display: 'none' },
                        msOverflowStyle: 'none',
                        scrollbarColor: 'transparent',
                        scrollbarWidth: 'none',
                    }}
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                >
                    {openTickets.map((item: AteraTicketType, index: number) => (
                        <Box
                            key={item.TicketID}
                            // item
                            // xs={12}
                            // sm={12}
                            // md={3}
                            // lg={2.5}
                            sx={{
                                marginRight: 3,
                                width: 300,
                            }}
                        >
                            <OpenTickets
                                requestor={item?.EndUserFirstName}
                                businessName={item?.CustomerName}
                                ticketID={item?.TicketID}
                                description={item?.TicketTitle}
                                techName={item?.TechnicianFullName}
                                key={index}
                            />
                        </Box>
                    ))}
                </Grid>
            ) : (
                <Grid
                    item
                    sx={{
                        overflowX: 'scroll',
                        whiteSpace: 'nowrap',
                        scrollBehavior: 'smooth',
                        flexDirection: 'row',
                        display: 'flex',
                        paddingBottom: 2,
                        height: 232,
                        '& ::-webkit-scrollbar': { display: 'none' },
                        msOverflowStyle: 'none',
                        scrollbarColor: 'transparent',
                        scrollbarWidth: 'none',
                    }}
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                >
                    <MainCard
                        contentSX={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '100%',
                            height: '100%',
                            p: 2.5,
                        }}
                        sx={{
                            borderRadius: 2,
                            width: '100%',
                            height: '100%',
                        }}
                    >
                        <Typography
                            variant="h5"
                            color="text.secondary"
                            sx={{
                                mr: 1,
                            }}
                        >
                            No Open Tickets
                        </Typography>
                        <LocalOfferIcon />
                    </MainCard>
                </Grid>
            )}
        </>
    )
}

export default OpenTicketsComp
