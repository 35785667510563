export const lastTwelveMonths = [
    new Date().toLocaleString('default', { month: 'short' }),
    new Date(new Date().setMonth(new Date().getMonth() - 1)).toLocaleString(
        'default',
        { month: 'short' }
    ),
    new Date(new Date().setMonth(new Date().getMonth() - 2)).toLocaleString(
        'default',
        { month: 'short' }
    ),
    new Date(new Date().setMonth(new Date().getMonth() - 3)).toLocaleString(
        'default',
        { month: 'short' }
    ),
    new Date(new Date().setMonth(new Date().getMonth() - 4)).toLocaleString(
        'default',
        { month: 'short' }
    ),
    new Date(new Date().setMonth(new Date().getMonth() - 5)).toLocaleString(
        'default',
        { month: 'short' }
    ),
    new Date(new Date().setMonth(new Date().getMonth() - 6)).toLocaleString(
        'default',
        { month: 'short' }
    ),
    new Date(new Date().setMonth(new Date().getMonth() - 7)).toLocaleString(
        'default',
        { month: 'short' }
    ),
    new Date(new Date().setMonth(new Date().getMonth() - 8)).toLocaleString(
        'default',
        { month: 'short' }
    ),
    new Date(new Date().setMonth(new Date().getMonth() - 9)).toLocaleString(
        'default',
        { month: 'short' }
    ),
    new Date(new Date().setMonth(new Date().getMonth() - 10)).toLocaleString(
        'default',
        { month: 'short' }
    ),
    new Date(new Date().setMonth(new Date().getMonth() - 11)).toLocaleString(
        'default',
        { month: 'short' }
    ),
].reverse()
