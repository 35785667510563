import { CustomTheme } from '@mui/material'

const Link = (theme: CustomTheme) => {
    return {
        MuiLink: {
            defaultProps: {
                underline: 'hover',
            },
        },
    }
}

export default Link
