import React, { useEffect, useMemo } from 'react'

// material-ui
import { CssBaseline, StyledEngineProvider } from '@mui/material'
import { createTheme, ThemeProvider } from '@mui/material/styles'

// project import
import Palette from './palette'
import Typography from './typography'
import CustomShadows from './shadows'
import componentsOverride from './overrides'

//types
import { ThemeCustomizationType } from 'types'

// ==============================|| DEFAULT THEME - MAIN  ||============================== //

//zustand
import useStoredStore from 'store/usePersistedStore'
import { useSystemColorMode } from 'hooks'

const ThemeCustomization: React.FC<ThemeCustomizationType> = ({
    children,
}): JSX.Element => {
    //zustand
    const darkMode = useStoredStore((state) => state.darkMode)

    const dark = useSystemColorMode()

    const theme = Palette(darkMode === 'auto' ? dark : darkMode)

    const themeTypography = Typography(`'Public Sans', sans-serif`)
    const themeCustomShadows = useMemo(() => CustomShadows(theme), [theme])

    const themeOptions = useMemo(
        () => ({
            breakpoints: {
                values: {
                    xs: 0,
                    sm: 768,
                    md: 1024,
                    lg: 1266,
                    xl: 1536,
                },
            },
            direction: 'ltr',
            mixins: {
                toolbar: {
                    minHeight: 60,
                    paddingTop: 8,
                    paddingBottom: 8,
                },
            },
            palette: theme.palette,
            typography: themeTypography,
            customShadows: themeCustomShadows,
        }),
        [theme, themeTypography, themeCustomShadows]
    )

    const themes = createTheme(themeOptions)
    themes.components = componentsOverride(themes)

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themes}>
                <CssBaseline />
                {children}
            </ThemeProvider>
        </StyledEngineProvider>
    )
}

export default ThemeCustomization
