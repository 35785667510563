// material-ui
import { CustomTheme, useTheme, Box, Typography } from '@mui/material'

// project import
import NavGroup from './NavGroup'
import menuItem from 'menu-items/index'

// ==============================|| DRAWER CONTENT - NAVIGATION ||============================== //

const Navigation: React.FC = (): JSX.Element => {
    const theme = useTheme<CustomTheme>()

    const navGroups = menuItem.items.map((item) => {
        switch (item.type) {
            case 'group':
                return <NavGroup key={item.id} item={item} />
            default:
                return (
                    <Typography
                        key={item.id}
                        variant="h6"
                        color="error"
                        align="center"
                    >
                        Fix - Navigation Group
                    </Typography>
                )
        }
    })

    return (
        <Box
            sx={{
                paddingTop: theme.spacing(2),
            }}
        >
            {navGroups}
        </Box>
    )
}

export default Navigation
